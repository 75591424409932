export enum LicenseTypesEnum {
  UsingOwnLicense = 'Using own license',
  ThroughImporterOfRecord = 'Through an importer of record',
  Factory = 'Factory',
  FactoryUnderGAFI = 'Factory under GAFI',
  ContractorLicense = 'Contractor license',
  IndustrialFactory = 'Industrial factory',
  IndustrialFactoryUnderGAFI = 'Industrial factory under GAFI',
  TraderWithIORLicense = 'Trader with IOR license',
  TraderWithOwnLicense = 'Trader with own license'
}
