<div class="lead-times">
  <div class="row mx-0 d-flex justify-content-end lead-times-header">
    <div class="d-flex align-items-center gap-4" id="not-printed">
      <button class="export-btn" (click)="exportLeadTimesToExcel()">
        <svg-icon name="export-excel"></svg-icon> Export to Excel
      </button>
      <a
        class="export-btn"
        [href]="
          'leadtimes-pdf?shipmentId=' + shipmentId + '&companyId=' + companyId
        "
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg-icon name="download-icon"></svg-icon> Download PDF
      </a>
    </div>
  </div>
  <div
    *ngIf="leadTimes && leadTimes?.length > 0; else noLeadTimeTemplate"
    class="lead-section"
  >
    <div *ngFor="let item of leadTimes">
      <app-lead-time-card
        [lead]="item"
        [activeModule]="activeModule"
      ></app-lead-time-card>
    </div>
  </div>

  <ng-template #noLeadTimeTemplate>
    <div class="no-lead-times">
      <svg-icon name="noItemsIcon"></svg-icon>
      <span>No lead time started yet</span>
    </div>
  </ng-template>
</div>
