import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalKPIsListGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.canListApprovalKPIs
      ? true
      : this.router.createUrlTree(['settings'], { fragment: 'config' });
  }

  private get canListApprovalKPIs(): boolean {
    return this.authService.canListApprovalKPIs;
  }
}
