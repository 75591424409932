<app-page-header [title]="'Land Routes'"></app-page-header>

<div class="search-filter-margin row">
  <div class="search-filter-container mx-0 row align-items-center">
    <div class="search-bar">
      <app-admin-search-bar
        class="search-bar d-block"
        [placeholder]="'Search for route'"
        (searchChange)="onSearchChanged($event)"
      ></app-admin-search-bar>
    </div>
    <button class="btn">
      <svg-icon name="three-lines-filter"></svg-icon>
    </button>
  </div>
  <div class="ml-auto">
    <button type="submit" class="btn add-btn" [routerLink]="['./add']">
      <span class="add-btn-title"
        >+ {{ "config.management.Land.New" | translate }}</span
      >
    </button>
  </div>
</div>

<app-add-empty-data-msg
  *ngIf="!islandRouteExist"
  [boldText]="'There are no routes yet!'"
  [normalText]="'Routes will appear here once created.'"
></app-add-empty-data-msg>

<ngx-datatable
  *ngIf="islandRouteExist"
  class="material colored-header sm table table-striped table-hover"
  [rows]="this.paginatedLandRoutes?.items"
  [rowHeight]="40"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
  (scroll)="onScroll($event)"
>
</ngx-datatable>

<ng-template #actionTemplate let-value="value">
  <svg-icon
    class="action-btn cursor-pointer pr-2"
    name="edit-circle"
    [routerLink]="['./', value.id, 'edit']"
  ></svg-icon>
  <svg-icon
    class="action-btn cursor-pointer pr-2"
    name="delete-circle"
    (click)="deleteLandRoute(value)"
  ></svg-icon>
</ng-template>
<ng-template #routeNameTemplate let-row="row">
  {{ row.pickupCountry }} - {{ row.deliveryCountry }}
</ng-template>
<ng-template #originPortTemplate let-row="row">
  {{ row.originPort }} {{ "(" + row.originPortCode + ")" }}
</ng-template>
<ng-template #transitPortTemplate let-row="row">
  {{
    (row.transitPort ? row.transitPort : "_") +
      (row.transitPortCode ? "(" + row.transitPortCode + ")" : "")
  }}
</ng-template>
<ng-template #deliveryPortTemplate let-row="row">
  {{ row.deliveryPort }} {{ "(" + row.deliveryPortCode + ")" }}
</ng-template>
