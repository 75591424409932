<div class="table-container" [formGroup]="form ? form : null">
  <div
    class="row d-flex"
    [ngClass]="{
      'first-row': i == 0
    }"
    *ngFor="let ddlTable of ddlTables ? ddlTables : ddlTableWithIds; index as i"
  >
    <div class="mt-3 col-6">
      <div class="d-flex align-items-center mt-2">
        <app-checkbox
          (click)="selectAll(i, $event.target.checked)"
          [checked]="isAllSelected(i)"
          [classes]="isDashMark(i) ? 'dashmark' : ''"
        ></app-checkbox>
        <span class="mx-1"></span>
        <span class="title">
          {{ ddlTable?.parentName ? ddlTable?.parentName : ddlTable.parent }}
        </span>
      </div>
    </div>
    <div class="mt-3 mb-3 col-6">
      <ng-select
        [items]="getChildren(ddlTable)"
        [searchable]="false"
        [clearable]="false"
        [multiple]="true"
        class="ddl-height"
        [formControlName]="formControlNames[i]"
        [scrollToValue]="false"
        [bindLabel]="isddlTableWithIds ? 'name' : null"
        [bindValue]="isddlTableWithIds ? 'id' : null"
        [ngClass]="{
          error: isInvalidFormControl(formControlNames[i], index),
          'first-row': index == 0
        }"
      >
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          {{ isddlTableWithIds ? labels(items) : items.join(", ") }}
        </ng-template>

        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div class="d-flex align-items-center">
            <app-checkbox
              id="item-{{ index }}"
              [checked]="item$.selected"
            ></app-checkbox>
            <span class="mx-1"></span>
            <span class="title">{{ item.name ? item.name : item }}</span>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</div>
