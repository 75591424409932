<app-page-header
  [title]="'config.management.City.Manage' | translate"
></app-page-header>

<app-page-action-header
  placeholder="{{ 'config.management.City.Search' | translate }}"
  addLabel="{{ 'config.management.City.New' | translate }}"
  addUrl="./add"
  [canAdd]="canCreateCities"
  (searchChanged)="onSearchChanged($event)"
></app-page-action-header>

<ngx-datatable
  class="material colored-header sm table table-striped table-hover mt-5"
  [loadingIndicator]="loadingIndicator"
  [rows]="rows"
  [rowHeight]="35"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [scrollbarH]="false"
  [columnMode]="'force'"
  (scroll)="onScroll($event)"
  *ngIf="paginatedCities?.items?.length > 0 && !isLoading; else emptyCities"
>
</ngx-datatable>

<ng-template #indexTemplate let-value="value">
  <strong>{{ value }}</strong>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
  <div>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      [routerLink]="['./' + row.id + '/edit']"
      *ngIf="canUpdateCities"
    ></svg-icon>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="delete-circle"
      (click)="deleteCity(row)"
      *ngIf="canDeleteCities"
    ></svg-icon>
  </div>
</ng-template>

<ng-template #emptyCities>
  <app-add-empty-data-msg
    *ngIf="paginatedCities?.items?.length === 0 && !isLoading"
    [boldText]="'There are no cities yet!'"
    [normalText]="'Cities will appear here once created.'"
  ></app-add-empty-data-msg>
</ng-template>
