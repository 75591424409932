import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { AddOrEditPortComponent } from '../add-or-edit-port/add-or-edit-port.component';
import { ActivatedRoute } from '@angular/router';
import { CountryMaster } from 'src/app/shared/models/country.model';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { PortDto } from '../../../models/portDto.model';
import { PortService } from '../../../services/port.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-port-list',
  templateUrl: './port-list.component.html',
  styleUrls: ['./port-list.component.scss']
})
export class PortListComponent implements OnInit {
  rows: PortDto[] = [];
  columns: any[] = [];
  rowsCache: PortDto[] = [];
  countries: CountryMaster[];
  isForEdit: boolean;
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private portService: PortService,
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
    private countryService: CountryService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.rows = this.getIndecies(data['ports']);
      this.rowsCache = [...this.rows];
    });

    this.getCountries();

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Ports.Name'), width: 290 },
      {
        prop: 'code',
        name: gT('config.management.Ports.Code'),
        width: 100
      },
      {
        prop: 'countryName',
        name: gT('config.management.Ports.CountryName'),
        width: 200
      },
      {
        prop: 'cityName',
        name: gT('config.management.Ports.CityName'),
        width: 200
      },
      {
        prop: 'isAirPort',
        name: gT('config.management.Ports.IsAirPort'),
        width: 100
      },
      {
        prop: 'options',
        name: gT('config.management.Ports.Manage'),
        width: 170,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];

    this.portService.portUpdatedEmitter.subscribe(
      data => {
        this.showSinglePort(data.updatedPort, false);
      },
      error => {
        this.alertService.error('Error!');
      }
    );

    this.portService.portAddedEmitter.subscribe(
      data => {
        this.showSinglePort(data.addedPort, true);
      },
      error => this.alertService.error('Error!')
    );
  }

  getCountries() {
    this.countryService.getCountries().subscribe(
      data => {
        this.countries = data;
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  showSinglePort(port: PortDto, isNew: boolean) {
    this.dialogService.close();
    if (port == null) {
      this.alertService.error('Error!');
      return;
    }

    const index = this.rows.findIndex(i => i.id == port.id);
    if (isNew) {
      this.rows.unshift(port);
    } else {
      this.rows[index] = port;
    }

    isNew
      ? this.alertService.success(`Added ${port.name} Successfully`)
      : this.alertService.success(`${port.name} Has Been Successfully Updated`);
  }

  onSearchChanged(event: any) {
    this.portService.searchPorts(event, null, null, 0, 150).subscribe(
      data => {
        this.rows = this.getIndecies(data);
      },
      error => this.alertService.error(error)
    );
  }

  openForm(port: PortDto, isForEdit: boolean) {
    this.dialogService.open(
      AddOrEditPortComponent,
      {
        port,
        countries: this.countries,
        isForEditing: isForEdit
      },
      '650px',
      '600px'
    );
  }

  deletePort(row: PortDto) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" port?',
      DialogType.confirm,
      () => this.deletePortHelper(row)
    );
  }

  deletePortHelper(row: PortDto) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.portService.deletePort(row).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.rows = this.rows.filter(item => item !== row);
        this.alertService.success(`Successfully Deleted ${row.name}`);
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the port.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }
}
