import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AppStatusCode,
  HttpStatusCode
} from 'src/app/shared/models/enums/http-status-code';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ShipmentsService } from '../../services/shipments.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'undo',
  templateUrl: './undo.component.html',
  styleUrls: ['./undo.component.scss']
})
export class UndoComponent implements OnInit {
  shipmentId: string;
  companyId: number;
  reason: string;
  isLoading: boolean;
  reasonError: boolean;
  constructor(
    private alertService: AlertService,
    private shipmentService: ShipmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.shipmentId = this.data['shipmentId'];
    this.companyId = this.data['companyId'];
  }

  undo() {
    if (this.isLoading) {
      return;
    }
    if (this.reason) {
      this.isLoading = true;
      this.shipmentService
        .undo(this.shipmentId, this.companyId, this.reason)
        .subscribe(data => {
          if (
            data['statusCode'] == HttpStatusCode.Created ||
            data['statusCode'] == HttpStatusCode.Ok
          ) {
            this.alertService.success('Shipment Status reverted successfully.');
            this.shipmentService.shipmentUpdated.emit();
            this.cancel();
          } else if (
            data['statusCode'] ==
            AppStatusCode.Current_Shipment_Activity_Already_Undone
          ) {
            this.alertService.error(data['result']);
            this.cancel();
          }
        });
    } else {
      this.reasonError = true;
    }
    if (this.isLoading) {
      return;
    }
  }

  cancel() {
    this.dialogService.close();
  }
}
