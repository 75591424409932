<div class="remove-document-con">
    <svg-icon name="removeDocument"></svg-icon>
    <div class="title">
        The <strong>selected information</strong>
        will be <strong>deleted</strong>
        <div>from this shipment</div>
    </div>
    <div class="sub-title">
        To continue with this action please confirm <div>and the respective information will be deleted</div>
    </div>

    <div class="form-buttons">
        <button class="form-btn cancel" (click)="cancel()">Cancel</button>
        <button class="form-btn delete-charge" (click)="deleteCharge()">Confirm</button>
    </div>
    <div class="deletion-warning">
        <svg-icon [name]="'mark!'"></svg-icon>
        <div class="deletion-warning-text">This action cannot be undone.</div>
    </div>
</div>