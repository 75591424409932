import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApprovalActivityTransition } from 'src/app/admin-portal-configs/models/approval-activity-transition.model';
import { ApprovalActivityService } from 'src/app/admin-portal-configs/services/approval-activity.service';
import { AlertService, DialogType, MessageSeverity } from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-approval-activity-transition-list',
  templateUrl: './approval-activity-transition-list.component.html',
  styleUrls: ['./approval-activity-transition-list.component.scss']
})
export class ApprovalActivityTransitionListComponent implements OnInit {
  transitions: ApprovalActivityTransition[];
  columns: any[] = [];
  rows: ApprovalActivityTransition[] = [];
  rowsCache: ApprovalActivityTransition[] = [];
  isLoadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;


  constructor(
    private approvalActivityService : ApprovalActivityService ,
    private route: ActivatedRoute,
    private alertService : AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService
    
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      var transitions = data['activityTransitions'];

      transitions = this.getIndecies(transitions);

      if (transitions?.length > 0) {
        this.rowsCache = [...transitions];
        this.rows = transitions;
      }
    });
    this.initTable();
  }

  initTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 400 },
      {
        prop: 'nextActivities.length',
        name: gT('config.management.ApprovalActivity.NextTransitionsCount'),
        width: 100
      },
      {
        prop: 'options',
        name: gT('config.management.ApprovalActivity.ManageTransitions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  getIndecies(array: ApprovalActivityTransition[]) {
    array?.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  deleteActivityTransition(row: ApprovalActivityTransition) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.name +
        '" activity transitions? this will delete all next transitions for this activity',
      DialogType.confirm,
      () => this.deleteActivityTransitionHelper(row)
    );
  }

  deleteActivityTransitionHelper(row: ApprovalActivityTransition) {
    this.alertService.startLoadingMessage('Deleting...');
    this.isLoadingIndicator = true;

    this.approvalActivityService.deleteTransition(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.isLoadingIndicator = false;

        this.rowsCache.find(i => i.id == row.id).nextActivities = [];
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.isLoadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the activity transition.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }
  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  get canDeleteApprovelWorkflow():boolean{
    return this.authService.canDeleteApprovelWorkflow;
   }

   get canUpdateApprovelWorkflow():boolean{
     return this.authService.canUpdateApprovelWorkflow;
    }
  get canCreateApprovelWorkflow(): boolean {
     return this.authService.canCreateApprovelWorkflow;
   }
}
