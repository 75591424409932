import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CityLocation } from 'src/app/admin-portal-configs/models/city-location.model';
import { CountryMaster } from 'src/app/shared/models/country.model';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { CityService } from '../../../services/city.service';
import { CountryService } from 'src/app/shared/services/country.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';

@Component({
  selector: 'app-add-or-edit-cities',
  templateUrl: './add-or-edit-cities.component.html',
  styleUrls: ['./add-or-edit-cities.component.scss']
})
export class AddOrEditCitiesComponent implements OnInit {
  pageTitle: string = 'Add City';
  cityForm: UntypedFormGroup;
  cityWithLocation: CityLocation;
  countries: CountryMaster[];
  isSaving: boolean = false;
  duplicateCity: boolean = false;
  isNewCity: boolean = false;
  isInvalidForm: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private cityService: CityService,
    private route: ActivatedRoute,
    private countryService: CountryService,
    private formsHelperService: FormsHelperService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.cityWithLocation = data['city'];
    });

    this.getCountries();
    this.initForm();
  }

  getCountries(): void {
    this.countryService.getCountries().subscribe(
      data => {
        this.countries = data;
      },
      error => {
        this.alertService.showStickyMessage(
          'Error',
          `An error occured whilst retreiving a list of countries.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  initForm(): void {
    this.isNewCity = this.cityWithLocation == null;
    this.cityWithLocation = this.cityWithLocation || ({} as CityLocation);

    this.cityForm = this.fb.group({
      city: [this.cityWithLocation.city, Validators.required],
      countryId: [this.cityWithLocation.countryId, Validators.required],
      cityAscii: [this.cityWithLocation.cityAscii, Validators.required],
      lat: [this.cityWithLocation.lat, Validators.required],
      lng: [this.cityWithLocation.lng, Validators.required],
      unLocode: [this.cityWithLocation.unLocode, Validators.required]
    });
  }

  save(): void {
    this.cityForm.markAllAsTouched();
    const formVal = this.cityForm.value;

    if (!this.cityForm.valid) {
      this.isInvalidForm = true;
      return;
    }

    this.isSaving = true;
    this.isInvalidForm = false;

    formVal.country = this.getCountryName(formVal.countryId);

    if (this.isNewCity) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  updateRequest(formVal: CityLocation): void {
    this.cityService.updateCity(this.cityWithLocation.id, formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  addNewRequest(formVal: CityLocation): void {
    this.cityService.addNewCity(formVal).subscribe(
      data => {
        this.saveSuccessHelper(data);
      },
      error => {
        this.saveFailedHelper(error);
      }
    );
  }

  cancel(): void {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/cities']);
  }

  resetForm(): void {
    this.cityForm.reset();
  }

  saveORCancel(isSave: boolean): void {
    if (isSave) {
      this.save();
    } else {
      this.cancel();
    }
  }

  isFormControlSelected(input: any, formControlName: string): boolean {
    return this.cityForm.get(formControlName).value === input;
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.cityForm,
      formControlName
    );
  }

  get isFormValid(): boolean {
    return this.cityForm.valid;
  }

  private getCountryName(id: number): string {
    return this.countries.find(i => i.id == id).name;
  }

  private saveSuccessHelper(cityLocation?: CityLocation): void {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    if (this.isNewCity) {
      this.alertService.showMessage(
        'Success',
        `City \"${cityLocation.city}\" was created successfully`,
        MessageSeverity.success
      );
    } else {
      this.alertService.showMessage(
        'Success',
        `Changes to city \"${cityLocation.city}\" were saved successfully`,
        MessageSeverity.success
      );
    }

    this.resetForm();
    this.router.navigate(['/settings/cities']);
  }

  private saveFailedHelper(error: any): void {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    if (error == '409') {
      this.duplicateCity = true;
      return;
    }
    this.alertService.showStickyMessage(
      'Save Error',
      'The below errors occurred whilst saving your changes:',
      MessageSeverity.error,
      error
    );
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
