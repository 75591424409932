<div id="shipmentpdf">
  <div class="d-flex justify-content-end align-items-center gap-1 mb-4 ">
    <img [src]="logoUrl" class="logo-navy" alt="logo-navy" />
  </div>
  <div class="d-flex align-items-center gap-1 mb-4 ">
    <p class="mb-0 shipment-number">{{ shipmentNumber }} - Activity Log</p>
  </div>
  <app-shipment-activity-log
    *ngFor="let activityLog of activityLogs; let i = index"
    [shipmentActivityLog]="activityLog"
    [isDisplayProgressLine]="i !== activityLogs.length - 1"
    [lastActivityLog]="lastActivityLog"
    [oceanContainersNumbers]="oceanContainersNumbers"
  ></app-shipment-activity-log>
</div>
