<div id="shipmentpdf">
  <div class="d-flex justify-content-end align-items-center gap-1 mb-4 ">
    <img [src]="logoUrl" class="logo-navy" alt="logo-navy" />
  </div>
  <div class="d-flex align-items-center gap-1 mb-4 ">
    <p class="mb-0 shipment-number">{{ shipmentNumber }} - Shipment Charges</p>
  </div>
  <app-shipment-charges
    [shipmentId]="shipmentDetails.shipment.id"
    [charges]="shipmentDetails.shipment.charges"
    [totalShipmentValue]="
      shipmentDetails.shipment.cargoDetails?.shipmentProduct?.totalShipmentValue
    "
    [currency]="
      shipmentDetails.shipment.cargoDetails?.shipmentProduct?.currency
    "
    [status]="shipmentDetails.shipment?.shipmentStatus?.name"
  ></app-shipment-charges>
</div>
