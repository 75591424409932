import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Charge } from 'src/app/shared/models/charge.model';

@Component({
  selector: 'app-charges-currency-based-total',
  templateUrl: './charges-currency-based-total.component.html',
  styleUrls: ['./charges-currency-based-total.component.scss']
})
export class ChargesCurrencyBasedTotalComponent implements OnInit, OnChanges {
  @Input() charges: Charge[];
  @Input() serviceName: string = null;
  currencyBasedCharges: ChargesCurrencyBasedTotals[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.processCharges();
  }

  ngOnInit(): void {}

  processCharges() {
    if(this.serviceName)
    {
      this.charges = this.charges.filter(c => c.serviceName == this.serviceName);
    }

    var existingCurrencies: string[] = this.charges.map(
      charge => charge.currencyCode
    );
    const distinctCurrencies = existingCurrencies.filter(
      (currency, i, arr) => arr.findIndex(t => t === currency) === i
    );

    this.currencyBasedCharges = [];

    distinctCurrencies.forEach(currencyCode => {
      var totalCharges = 0;
      var chargesWithCurrency = this.charges.filter(
        c => c.currencyCode == currencyCode
      );
      chargesWithCurrency.forEach(charge => {
        totalCharges += charge.amount;
      });

      this.currencyBasedCharges.push({
        totalCharges: totalCharges,
        currencyCode: currencyCode
      } as ChargesCurrencyBasedTotals);

    });
  }
}

export interface ChargesCurrencyBasedTotals {
  totalCharges: number;
  currencyCode: string;
}
