import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { ActivatedRoute } from '@angular/router';
import { DocumentTypeWithService } from '../../../models/document-type-with-service.models';
import { DocumentTypeService } from '../../../services/document-type.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-document-types-list',
  templateUrl: './document-types-list.component.html',
  styleUrls: ['./document-types-list.component.scss']
})
export class DocumentTypesListComponent implements OnInit {
  columns: any[] = [];
  rows: DocumentTypeWithService[] = [];
  rowsCache: DocumentTypeWithService[] = [];
  loadingIndicator: boolean;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('isPinnedTemplate', { static: true })
  isPinnedTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private documentTypeService: DocumentTypeService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var documentTypes = data['documentTypes'];

      documentTypes = this.getIndecies(documentTypes);

      this.rowsCache = [...documentTypes];
      this.rows = documentTypes;
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      {
        prop: 'name',
        name: gT('config.management.DocumentTypes.name'),
        width: 100
      },
      {
        prop: 'isPinned',
        name: gT('config.management.isPinned'),
        cellTemplate: this.isPinnedTemplate
      }
    ];

    if (this.canDeleteDocumentType || this.canUpdateDocumentType) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.DocumentTypes.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteDocumentType(row: DocumentTypeWithService) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" document type?',
      DialogType.confirm,
      () => this.deleteDocumentTypeHelper(row)
    );
  }

  deleteDocumentTypeHelper(row: DocumentTypeWithService) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.documentTypeService.deleteDocumentType(row.id.toString()).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rows = this.rows.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);

        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the document type.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  get canCreateDocumentType(): boolean {
    return this.authService.canCreateDocumentType;
  }

  get canUpdateDocumentType(): boolean {
    return this.authService.canUpdateDocumentType;
  }

  get canDeleteDocumentType(): boolean {
    return this.authService.canDeleteDocumentType;
  }
}
