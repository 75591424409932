<div>
  <app-page-header
    title="{{ 'config.management.Activity.ManageTransitions' | translate }}"
  ></app-page-header>
  <app-page-action-header
    placeholder="{{ 'config.management.Activity.Search' | translate }}"
    addLabel="{{ 'config.management.Activity.AddTransition' | translate }}"
    [addUrl]="['./add']"
    [canAdd]="canCreateShipmentWorkflow"
    (searchChanged)="onSearchChanged($event)"
  ></app-page-action-header>

  <ngx-datatable
    class="material colored-header sm table table-striped table-hover mt-5"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="35"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
  </ngx-datatable>

  <ng-template #indexTemplate let-value="value">
    <strong>{{ value }}</strong>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
    <div>
      <svg-icon
        *ngIf="canUpdateShipmentWorkflow"
        class="action-btn cursor-pointer pr-2"
        name="edit-circle"
        [routerLink]="['./' + row.id + '/edit']"
      ></svg-icon>

      <svg-icon
        class="pr-2 cursor-pointer"
        name="delete-circle"
        (click)="deleteActivityTransition(row)"
        *ngIf="canDeleteShipmentWorkflow"
      ></svg-icon>
    </div>
  </ng-template>
</div>
