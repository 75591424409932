<app-page-header [title]="'Approval KPIs'"></app-page-header>

<div class="mb-4">
  <app-page-action-header
    placeholder="{{ 'config.management.ApprovalLeadTime.Search' | translate }}"
    addLabel="{{ 'config.management.ApprovalLeadTime.New' | translate }}"
    addUrl="./add"
    [canAdd]="canCreateApprovalKPIs"
    (searchChanged)="onSearchChanged($event)"
  >
  </app-page-action-header>
</div>

<div>
  <ngx-datatable
    class="material colored-header sm table table-striped table-hover"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="35"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
  </ngx-datatable>

  <ng-template #leadTimeNameTemplate let-row="row" let-value="value">
    <div class="d-flex">
      <div class="name">{{ row?.name }}</div>
      <div
        [ngbPopover]="leadTimeInfo"
        #leadTimeInfoPopover="ngbPopover"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="togglePopover(leadTimeInfoPopover)"
        (mouseleave)="closePopover(leadTimeInfoPopover)"
      >
        <svg-icon id="pop-over-icon" name="info"></svg-icon>

        <ng-template #leadTimeInfo>
          <div class="trigger">Start Event</div>
          <div class="trigger-name">
            {{ row?.startTriggerName }}
          </div>
          <div class="trigger">End Event</div>
          <div class="trigger-name pb-0">
            {{ row?.endTriggerName }}
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
    <button
      class="btn btn-link btn-sm"
      [routerLink]="['./' + row.id + '/edit']"
      *ngIf="canUpdateApprovalKPIs"
    >
      <svg-icon name="edit-circle"></svg-icon>
    </button>
    <button
      class="btn btn-link btn-sm"
      (click)="deleteApprovalLeadTime(row)"
      *ngIf="canDeleteApprovalKPIs"
    >
      <svg-icon name="delete-circle"></svg-icon>
    </button>
  </ng-template>
</div>
