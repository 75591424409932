<div  class="d-inline-flex">
  <span class="fulfilled-action-label">
  {{ displayedText }}
</span>
<div
  class="pop-over-icon"
  placement="bottom"
  [ngbPopover]="fulfilledActionInfo"
  triggers="hover:hover"
>
  <svg-icon name="small-info"></svg-icon>
</div>
</div>
<ng-template #fulfilledActionInfo>
  <div class="row pop-over">
    <div class="pr-3 fulfilled-popOver-label">Marked as resolved on</div>
    <div class="px-0 pop-over-label fulfilled-popOver-information">
      {{ fulfillDate | fromutc | date: "dd/MM/YYYY hh:mm a" }}
    </div>
  </div>
  <div class="row pop-over mt-3">
    <div class="pr-3 fulfilled-popOver-label">Marked as resolved by</div>
    <div class="px-0 pop-over-label fulfilled-popOver-information">
      {{ fulfillActor?.actorName }}
    </div>
  </div>
</ng-template>
