import { Component, Input, OnInit } from '@angular/core';
import { ActionActor } from 'src/app/shared/models/action-actor.model';

@Component({
  selector: 'app-comment-pending-action-fulfill-information',
  templateUrl: './comment-pending-action-fulfill-information.component.html',
  styleUrls: ['./comment-pending-action-fulfill-information.component.scss']
})
export class CommentPendingActionFulfillInformationComponent implements OnInit {
  @Input() fulfillActor?: ActionActor;
  @Input() fulfillDate?: Date;
  @Input() displayedText: string = 'Action Resolved';

  constructor() {}

  ngOnInit(): void {
  }
}
