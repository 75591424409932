import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { DialogService } from '../../services/dialog.service';
import { RemoveLeadtimeNoteComponent } from '../remove-leadtime-note/remove-leadtime-note.component';
import { LeadTimeLogService } from 'src/app/shipments/services/lead-time-log.service';
import { LeadTimeLog } from 'src/app/shipments/models/lead-time-log.model';
import { ApprovalLeadTimeLog } from 'src/app/approvals/models/approval-lead-time-logs.model';
import { ProductRegistrationLeadTimeLog } from 'src/app/product-registration/models/product-registration-lead-time-log.model';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-lead-time-card',
  templateUrl: './lead-time-card.component.html',
  styleUrls: ['./lead-time-card.component.scss']
})
export class LeadTimeCardComponent implements OnInit, OnChanges {
  @Input() lead:
    | LeadTimeLog
    | ApprovalLeadTimeLog
    | ProductRegistrationLeadTimeLog;
  @Input() activeModule = '';
  isNoteInputShowen: boolean = false;
  insertedNote: string = '';

  constructor(
    private approvalService: ApprovalService,
    private productRegistrationService: ProductRegistrationService,
    private dialogService: DialogService,
    private leadTimeLogService: LeadTimeLogService
  ) {}

  ngOnChanges(): void {
    this.isNoteInputShowen = this.lead?.notes != null;
    this.insertedNote = this.lead?.notes;
  }
  ngOnInit(): void {}

  showNoteInput(): void {
    if (this.insertedNote) {
      this.isNoteInputShowen = true;
    }
    if (this.isNoteInputShowen) {
      this.showRemoveNotePopUp();
    } else {
      this.isNoteInputShowen = true;
    }
  }

  onNotesChange(): void {
    if (this.activeModule === 'shipments') {
      this.leadTimeLogService
        .updateLeadTimeNotes(this.lead?.id, this.insertedNote)
        .subscribe(data => {});
    } else if (this.activeModule === 'productRegistration') {
      this.productRegistrationService
        .updateProductLeadTimeLogs(this.lead?.id, this.insertedNote)
        .subscribe(data => {});
    } else if (this.activeModule === 'approvals') {
      this.approvalService
        .updateLeadTimeNotes(this.lead?.id, this.insertedNote)
        .subscribe(data => {});
    }
  }

  showRemoveNotePopUp(): void {
    this.dialogService
      .open(
        RemoveLeadtimeNoteComponent,
        {
          id: this.lead?.id,
          activeModule: this.activeModule
        },
        '540px',
        '675px'
      )
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.isNoteInputShowen = false;
          this.insertedNote = '';
        }
      });
  }

  convertDateToAfricaCairo(date: Date): string {
    let newDate = moment
      .utc(date)
      .tz('Africa/Cairo')
      .format('D MMM yyyy, h:mm a');

    return newDate;
  }

  get expectedEndDate(): string {
    let date = this.convertDateToAfricaCairo(this.lead.startedDate);
    let newdate = moment(date)
      .add(this.lead.expectedLeadTime, 'days')
      .format('D MMM yyyy, h:mm a');
    return newdate;
  }

  get endedDate(): string {
    let endDate = this.lead?.endedDate;
    if (!endDate) {
      return null;
    }
    return this.convertDateToAfricaCairo(endDate);
  }

  get startedDate(): string {
    let startDate = this.lead?.startedDate;
    return this.convertDateToAfricaCairo(startDate);
  }

  get status(): string {
    if (!this.lead || !this.lead.startedDate || !this.lead.expectedLeadTime) {
      return '';
    }
    const netDays = this.lead.netDays;
    const totalHours = this.lead.expectedLeadTime;
    const percentage = netDays / totalHours;

    if (netDays <= this.lead.expectedLeadTime) {
      if (percentage > 0.7 || netDays == this.lead.expectedLeadTime) {
        return 'risk';
      }
      return 'active';
    } else {
      return 'expire';
    }
  }
}
