import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from '../../../../shared/services/alert.service';
import { AppTranslationService } from '../../../../shared/services/app-translation.service';
import { Utilities } from '../../../../shared/services/utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/shared/models/shipment/activity.model';
import { ActivityService } from '../../../services/activity.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { PagedResult } from 'src/app/shared/models/paged-result';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnInit, OnDestroy {
  columns: any[] = [];
  rows: Activity[] = [];
  rowsCache: Activity[] = [];
  loadingIndicator = false;
  activityData: PagedResult<Activity>;

  @ViewChild('indexTemplate', { static: true }) indexTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild('parentCategoryTemplate', { static: true })
  parentCategoryTemplate: TemplateRef<any>;

  searchTerm = new Subject<string>();
  private destroySearch$ = new Subject<void>();

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initializeData();
    this.initializeTable();
    this.initSearch();
  }

  ngOnDestroy(): void {
    this.destroySearch$.next();
    this.destroySearch$.complete();
  }

  initSearch(): void {
    this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(term => term && term.length >= 3),
        switchMap(term => this.loadActivities(true, term)),
        takeUntil(this.destroySearch$)
      )
      .subscribe(pagedResult => {
        this.activityData = pagedResult;
        this.rows = pagedResult.data;
      });
  }

  onSearchChanged(term: string) {
    this.searchTerm.next(term);
  }

  initializeData(): void {
    this.route.data.subscribe(data => {
      this.rows = data['activities'].data || [];
      this.activityData = data['activities'];
    });
  }

  initializeTable(): void {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 400 }
    ];

    if (this.isVoidActivities) {
      this.columns.push({
        name: gT('config.management.Status.ParentCategory'),
        width: 250,
        cellTemplate: this.parentCategoryTemplate,
        resizeable: true
      });
    }

    if (this.canUpdateShipmentActivities || this.canDeleteShipmentActivities) {
      this.columns.push({
        prop: 'options',
        name: gT('config.management.Activity.Action'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      });
    }
  }

  onScroll(): void {
    if (
      this.activityData?.pageIndex >= this.activityData?.totalPages ||
      this.loadingIndicator
    ) {
      return;
    }

    this.activityData.pageIndex++;

    this.loadActivities(false).subscribe(pagedResult => {
      this.activityData = pagedResult;
      this.rows = [...this.rows, ...pagedResult.data];
    });
  }

  loadActivities(
    isNewSearch: boolean,
    searchTerm?: string
  ): Observable<PagedResult<Activity>> {
    if (isNewSearch) {
      this.activityData.pageIndex = 1;
    }

    this.loadingIndicator = true;

    return this.activityService
      .getAllActivities(
        this.isVoidActivities,
        this.activityData?.pageIndex,
        this.activityData?.pageSize,
        searchTerm
      )
      .pipe(
        tap(() => {
          this.loadingIndicator = false;
        })
      );
  }

  deleteActivity(row: Activity): void {
    this.alertService.showDialog(
      `Are you sure you want to delete the "${row.name}" activity?`,
      DialogType.confirm,
      () => this.deleteActivityHelper(row)
    );
  }

  deleteActivityHelper(row: Activity): void {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.activityService.deleteActivity(row.id).subscribe(
      () => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.rows = this.rows.filter(item => item !== row);
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occurred while deleting the activity.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  get canUpdateShipmentActivities(): boolean {
    return this.authService.canUpdateShipmentActivities;
  }

  get canDeleteShipmentActivities(): boolean {
    return this.authService.canDeleteShipmentActivities;
  }

  get canCreateShipmentActivities(): boolean {
    return this.authService.canCreateShipmentActivities;
  }

  get isVoidActivities(): boolean {
    return this.router.url.includes('activities');
  }

  get pageTitle(): string {
    return this.isVoidActivities ? 'Shipment Activities' : 'Shipment Statuses';
  }

  get searchPlaceholder(): string {
    return this.isVoidActivities
      ? 'config.management.Activity.Search'
      : 'config.management.Status.Search';
  }

  get addNewButton(): string {
    return this.isVoidActivities
      ? 'config.management.Activity.Add'
      : 'config.management.Status.Add';
  }

  get canEditShipmentStatus(): boolean {
    return this.authService.canEditShipmentStatus;
  }

  get canCreateShipmentStatus(): boolean {
    return this.authService.canCreateShipmentStatus;
  }

  get canDeleteShipmentStatus(): boolean {
    return this.authService.canDeleteShipmentStatus;
  }
}
