<div class="main-container">
  <h4 class="font-weight-bold" *ngIf="!isSuccess">
    Forgot Your Password?
  </h4>
  <p class="description" *ngIf="!isSuccess">
    Please enter your email to reset the password
  </p>
  <h4 class="font-weight-bold" *ngIf="isSuccess">
    {{ "recoverPassword.CheckYourEmailTitle" | translate }}
  </h4>
  <form
    *ngIf="formResetToggle"
    class="recover-form "
    name="recoverForm"
    #f="ngForm"
    novalidate
    (ngSubmit)="
      f.form.valid
        ? recoverPassword()
        : showErrorAlert(
            'recoverPassword.alerts.UsernameOrEmailRequired',
            'recoverPassword.alerts.EnterValidUsernameOrEmail'
          )
    "
  >
    <div>
      <label *ngIf="isSuccess" class="form-label">
        {{ "recoverPassword.CheckYourMail" | translate }}
      </label>
    </div>
    <div *ngIf="!isSuccess" class="form-group position-relative">
      <label *ngIf="!isSuccess" for="username" class="form-label">
        {{ "recoverPassword.EnterUsernameOrEmail" | translate }}
      </label>
      <input
        type="email"
        id="recover-username"
        name="username"
        placeholder="{{
          'recoverPassword.UsernameOrEmailPlaceholder' | translate
        }}"
        class="form-control"
        [ngClass]="{
          'is-valid': f.submitted && username.valid,
          'is-invalid': f.submitted && !username.valid
        }"
        [(ngModel)]="usernameOrEmail"
        #username="ngModel"
        required
        email
      />
      <div class=" invalid-login" *ngIf="f.submitted && !username.valid">
        <span class="invalid-login-message">
          {{ "recoverPassword.EmailRequired" | translate }}
        </span>
      </div>
    </div>
    <div class="form-group ">
      <div class="col-md-12">
        <div ngPreserveWhitespaces>
          <button
            *ngIf="isSuccess"
            type="button"
            routerLink="/"
            class="btn row custom-button done-button"
          >
            <svg-icon name="black-arrow-left" class="mr-2"></svg-icon>
            {{ "recoverPassword.Back" | translate }}
          </button>

          <button
            *ngIf="!isSuccess"
            type="submit"
            class="btn custom-button row"
            [disabled]="isLoading"
          >
            <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
            {{
              isLoading
                ? ("recoverPassword.Processing" | translate)
                : ("recoverPassword.Send" | translate)
            }}
          </button>

          <button
            *ngIf="!isSuccess"
            type="button"
            routerLink="/"
            class="row back-button"
            [disabled]="isLoading"
          >
            <svg-icon name="white-arrow-left" class="mr-2"></svg-icon>
            {{ "recoverPassword.Back" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
