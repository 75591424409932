import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocalStoreManager } from './local-store-manager.service';
import { OidcHelperService } from './oidc-helper.service';
import { ConfigurationService } from './configuration.service';
import { DBkeys } from './db-keys';
import { JwtHelper } from './jwt-helper';
import { Utilities } from './utilities';
import { AccessToken, LoginResponse } from '../models/login-response.model';
import { User } from '../models/user.model';
import { PermissionValues } from '../models/permission.model';
import { CompanyService } from '../../company/services/company.service';
import { EmployeeType } from '../models/enums/employee-type';
import { IndustryEnum } from '../models/enums/industry.enum';
import { UserPermissions } from '../models/enums/user-permission.enum';
import { SignalRService } from './signal-r.service';
import { AuthenticateService } from './authenticate.service';

@Injectable()
export class AuthService {
  private currentUserPermissions: any[];
  private loggedInUser: User;
  public tenantData: any = {};

  public get userPermissions(): any[] {
    if (this.currentUserPermissions == null && this.isLoggedIn) {
      const jwtHelper = new JwtHelper();
      const decodedAccessToken = jwtHelper.decodeToken(
        this.accessToken
      ) as AccessToken;
      const permissions: PermissionValues[] = Array.isArray(
        decodedAccessToken.permission
      )
        ? decodedAccessToken.permission
        : [decodedAccessToken.permission];

      this.currentUserPermissions = permissions;
    }
    return this.currentUserPermissions;
  }

  public get currentUser(): User {
    if (this.loggedInUser == null && this.isLoggedIn) {
      const jwtHelper = new JwtHelper();
      const decodedAccessToken = jwtHelper.decodeToken(
        this.accessToken
      ) as AccessToken;
      const user = new User(
        decodedAccessToken.id,
        decodedAccessToken.employeeid,
        decodedAccessToken.name,
        decodedAccessToken.fullname,
        decodedAccessToken.email,
        decodedAccessToken.jobtitle,
        decodedAccessToken.companyname,
        decodedAccessToken.companyid,
        decodedAccessToken.customercompanies
          ? JSON.parse(decodedAccessToken.customercompanies)
          : null,
        decodedAccessToken.defaultcustomercompany,
        decodedAccessToken.profilepictureurl,
        decodedAccessToken.phone_number,
        Array.isArray(decodedAccessToken.role)
          ? decodedAccessToken.role
          : [decodedAccessToken.role],
        decodedAccessToken.industry,
        decodedAccessToken.defaultcustomercompanyName,
        decodedAccessToken.tenant
      );
      user.isEnabled = true;
      this.loggedInUser = user;
    }
    return this.loggedInUser;
  }
  public get loginUrl() {
    return this.configurations.loginUrl;
  }
  public get homeUrl() {
    return this.configurations.homeUrl;
  }

  public get defaultPageUrl() {
    if (this.canSeeAdminSide) {
      return 'settings#config';
    }
    if (!this.currentUser?.defaultCustomerCompany) {
      return this.homeUrl;
    }
    return this.configurations.defaultPageUrl.replace(
      '{companyId}',
      this.currentUser.defaultCustomerCompany
    );
  }
  public loginRedirectUrl: string;
  public logoutRedirectUrl: string;

  public reLoginDelegate: () => void;

  private previousIsLoggedInCheck = false;
  private loginStatus = new BehaviorSubject<boolean>(this.isLoggedIn);

  constructor(
    private router: Router,
    private oidcHelperService: OidcHelperService,
    private configurations: ConfigurationService,
    private companyService: CompanyService,
    private localStorage: LocalStoreManager,
    private signalRService: SignalRService,
    private authenticateService: AuthenticateService
  ) {
    this.initializeLoginStatus();
    this.tenantData = JSON.parse(window.localStorage.getItem('theme') || '{}');
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }

  gotoPage(page: string, preserveParams = true) {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: preserveParams ? 'merge' : '',
      preserveFragment: preserveParams
    };

    this.router.navigate([page], navigationExtras);
  }

  gotoHomePage() {
    this.router.navigate([this.homeUrl]);
  }

  gotoDefaultPage() {
    this.router.navigate([this.defaultPageUrl]);
  }

  redirectLoginUser() {
    let redirect =
      this.loginRedirectUrl &&
      this.loginRedirectUrl !== '/' &&
      this.loginRedirectUrl !== ConfigurationService.defaultPageUrl
        ? this.loginRedirectUrl
        : this.defaultPageUrl;

    this.loginRedirectUrl = null;

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    const urlAndParams = Utilities.splitInTwo(
      urlParamsAndFragment.firstPart,
      '?'
    );

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: 'merge'
    };
    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }

  redirectLogoutUser() {
    this.router.navigate([this.loginUrl]);
  }

  redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    this.router.navigate([this.loginUrl]);
  }

  reLogin() {
    if (this.reLoginDelegate) {
      this.reLoginDelegate();
    } else {
      this.redirectForLogin();
    }
  }

  refreshLogin() {
    return this.authenticateService
      .refreshLogin(this.refreshToken)
      .pipe(map(resp => this.processLoginResponse(resp, this.rememberMe)));
  }

  loginWithPassword(userName: string, password: string, rememberMe?: boolean) {
    if (this.isLoggedIn) {
      this.logout();
    }
    return this.authenticateService
      .login(userName, password)
      .pipe(map(resp => this.processLoginResponse(resp, rememberMe)));
  }

  loginWithExternalToken(
    token: string,
    provider: string,
    email?: string,
    password?: string
  ) {
    if (this.isLoggedIn) {
      this.logout();
    }

    return this.oidcHelperService
      .loginWithExternalToken(token, provider, email, password)
      .pipe(map(resp => this.processLoginResponse(resp)));
  }

  initLoginWithGoogle(rememberMe?: boolean) {
    if (this.isLoggedIn) {
      this.logout();
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    this.oidcHelperService.initLoginWithGoogle();
  }

  initLoginWithFacebook(rememberMe?: boolean) {
    if (this.isLoggedIn) {
      this.logout();
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
    this.oidcHelperService.initLoginWithFacebook();
  }

  private processLoginResponse(response: LoginResponse, rememberMe?: boolean) {
    const accessToken = response.accessToken;
    if (accessToken == null) {
      throw new Error('accessToken cannot be null');
    }

    rememberMe = rememberMe || this.rememberMe;

    const refreshToken = response.refreshToken || this.refreshToken;
    //const expiresIn = response.expires_in;
    //const tokenExpiryDate = new Date();
    //tokenExpiryDate.setSeconds(tokenExpiryDate.getSeconds() + expiresIn);
    //const accessTokenExpiry = tokenExpiryDate;
    const jwtHelper = new JwtHelper();
    const decodedAccessToken = jwtHelper.decodeToken(
      accessToken
    ) as AccessToken;

    const accessTokenExpiry = new Date(
      decodedAccessToken.exp * 1000 + 12 * 3600
    );
    this.oidcHelperService.accessTokenExpiryDate = accessTokenExpiry;

    const permissions: PermissionValues[] = Array.isArray(
      decodedAccessToken.permission
    )
      ? decodedAccessToken.permission
      : [decodedAccessToken.permission];

    this.currentUserPermissions = permissions;

    if (!this.isLoggedIn) {
      this.configurations.import(decodedAccessToken.configuration);
    }

    const user = new User(
      decodedAccessToken.id,
      decodedAccessToken.employeeid,
      decodedAccessToken.name,
      decodedAccessToken.fullname,
      decodedAccessToken.email,
      decodedAccessToken.jobtitle,
      decodedAccessToken.companyname,
      decodedAccessToken.companyid,
      decodedAccessToken.customercompanies
        ? JSON.parse(decodedAccessToken.customercompanies)
        : null,
      decodedAccessToken.defaultcustomercompany,
      decodedAccessToken.profilepictureurl,
      decodedAccessToken.phone_number,
      Array.isArray(decodedAccessToken.role)
        ? decodedAccessToken.role
        : [decodedAccessToken.role],
      decodedAccessToken.industry,
      decodedAccessToken.defaultcustomercompanyName,
      decodedAccessToken.tenant
    );
    user.isEnabled = true;

    this.loggedInUser = user;

    this.saveUserDetails(accessToken, refreshToken, rememberMe);

    this.reevaluateLoginStatus(user);

    return user;
  }

  private saveUserDetails(
    accessToken: string,
    refreshToken: string,
    rememberMe: boolean
  ) {
    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.savePermanentData(refreshToken, DBkeys.REFRESH_TOKEN);
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, DBkeys.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(
        refreshToken,
        DBkeys.REFRESH_TOKEN
      );
    }

    this.localStorage.savePermanentData(rememberMe, DBkeys.REMEMBER_ME);
  }

  logout(): void {
    this.signalRService.stopConnection(this?.currentUser?.employeeId);
    this.localStorage.deleteData(DBkeys.ACCESS_TOKEN);
    this.localStorage.deleteData(DBkeys.REFRESH_TOKEN);
    this.loggedInUser = null;
    this.currentUserPermissions = [];
    this.oidcHelperService.accessTokenExpiryDate = null;
    this.configurations.clearLocalChanges();
    this.reevaluateLoginStatus();
    this.removeTenantData();
  }

  private reevaluateLoginStatus(currentUser?: User) {
    const user = currentUser || this.currentUser;
    const isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this.loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this.loginStatus.asObservable();
  }

  canAccessCompanyShipments(companyId: string): boolean {
    let permissionsToAccessAllCompanies: string[] = [
      UserPermissions.ViewShipmentsForMedicalCustomers,
      UserPermissions.ViewShipmentsForNonMedicalCustomers,
      UserPermissions.ViewReadyForInvoicePreparationShipments,
      UserPermissions.ViewReadyForInvoiceIssuanceShipments,
      UserPermissions.ViewForm4List,
      UserPermissions.ManageMedicalOperationalOversight,
      UserPermissions.ManageNonMedicalOperationalOversight,
      UserPermissions.ManageAllQuotesOperationalOversight
    ];

    if (this.hasPermission(permissionsToAccessAllCompanies)) {
      return true;
    }

    return this.canAccessCompany(companyId);
  }

  checkCompanyShipmentsAccessPermission(companyId) {
    var result = this.canAccessCompanyShipments(companyId);
    if (!result) {
      this.gotoHomePage();
    }
  }
  updateDefaultCompanyDataForCurrentUser(
    defaultCompanyId: string,
    defaultIndustry: string,
    isNavigateToDashboard?: boolean,
    defaultCompanyName?: string
  ) {
    if (!defaultCompanyName) {
      this.companyService
        .getCompanyNameById(parseInt(defaultCompanyId))
        .subscribe(data => {
          defaultCompanyName = data['companyName'];
        });
    }
    this.loggedInUser.defaultCustomerCompany = defaultCompanyId;
    this.loggedInUser.defaultCustomerCompanyName = defaultCompanyName;
    this.loggedInUser.industry = defaultIndustry;
    let companyChanged = {
      companyId: defaultCompanyId,
      isNavigateToDashboard: isNavigateToDashboard
    };
    this.companyService.customerCompanySwitched.emit(companyChanged);
  }

  updateUserCustomerCompanies(customerCompanies: string[]) {
    this.loggedInUser.customerCompanies = this.currentUser.customerCompanies.concat(
      customerCompanies
    );
  }

  updateProfilePictureUrl(url) {
    this.loggedInUser.profilePictureUrl = url;
  }

  canAccessCompany(companyId: string): boolean {
    return (
      this.currentUser.customerCompanies.find(c => c == companyId) != undefined
    );
  }

  setTenantData(data: any) {
    this.tenantData = data;
  }

  removeTenantData() {
    this.tenantData = {};
    window.localStorage.removeItem('theme');
  }

  get tenantLogo(): string {
    return this.tenantData?.logo;
  }

  get accessToken(): string {
    return this.oidcHelperService.accessToken;
  }

  get accessTokenExpiryDate(): Date {
    return this.oidcHelperService.accessTokenExpiryDate;
  }

  get refreshToken(): string {
    return this.oidcHelperService.refreshToken;
  }

  get isSessionExpired(): boolean {
    return this.oidcHelperService.isSessionExpired;
  }

  get isLoggedIn(): boolean {
    return this.accessToken != null;
  }

  get rememberMe(): boolean {
    return (
      this.localStorage.getDataObject<boolean>(DBkeys.REMEMBER_ME) === true
    );
  }

  get isAdmin(): boolean {
    return this.currentUser?.roles.some(
      p =>
        p.includes('Administrator') ||
        p.includes('Super Administrator') ||
        p.includes('administrator')
    );
  }

  get isAccountManager(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.AccountManager)
    );
  }

  get isOperationsCoordinator(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.OperationsCoordinator)
    );
  }

  get canManageShipmentsOperationalOversight(): boolean {
    return (
      this.hasManageMedicalOperationalOversightPermission ||
      this.hasManageNonMedicalOperationalOversightPermission
    );
  }

  get hasManageMedicalOperationalOversightPermission(): boolean {
    return this.hasPermission([
      UserPermissions.ManageMedicalOperationalOversight
    ]);
  }

  get hasManageNonMedicalOperationalOversightPermission(): boolean {
    return this.hasPermission([
      UserPermissions.ManageNonMedicalOperationalOversight
    ]);
  }
  get canManageAllQuotesOperationalOversight(): boolean {
    return this.hasPermission([
      UserPermissions.ManageAllQuotesOperationalOversight
    ]);
  }

  get isCustomer(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.CustomerEmployee)
    );
  }

  hasPermission(permissionValue: any[]): boolean {
    return this.userPermissions?.some(p => permissionValue?.includes(p));
  }

  get canManageForm4Requests(): boolean {
    return this.hasPermission([UserPermissions.ViewForm4List]);
  }

  get isFinanceSpecialist(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.FinanceSpecialist)
    );
  }

  get isOperationsSpecialist(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.OperationsSpecialist)
    );
  }

  get isOperationsManager(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.OperationsManager)
    );
  }

  get isRegulatoryAffairsSpecialist(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.RegulatoryAffairsSpecialist)
    );
  }

  get isRegulatoryAffairsAssociate(): boolean {
    return this.currentUser?.roles.some(p =>
      p.includes(EmployeeType.RegulatoryAffairsAssociate)
    );
  }

  get currentIndustry(): string {
    return this.currentUser?.industry;
  }

  get isMedicalOrPharmaceuticalIndustry(): boolean {
    return (
      this.currentIndustry == IndustryEnum.PharmaceuticalManufacturing ||
      this.currentIndustry ==
        IndustryEnum.MedicalEquipmentAndSuppliesManufacturing
    );
  }

  //this is a workaround to access the sidebar we need to improve this design
  get canSeeAdminSide(): boolean {
    // is a logical to have a create role without have a ViewRole permission
    // this design need to be changes or improved
    return (
      this.isAdmin ||
      this.canViewRole ||
      this.canViewShipmentActivities ||
      this.canViewRegistrationActivities ||
      this.canViewRegistrationWorkflows
    );
  }
  get canViewRole(): boolean {
    return this.hasPermission([UserPermissions.ViewRole]);
  }

  get canChangeShipmentServices(): boolean {
    return this.hasPermission([UserPermissions.ChangeShipmentServices]);
  }

  get canViewShipmentKPIs(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentKPIs]);
  }

  get canCreateShipmentKPIs(): boolean {
    return this.hasPermission([UserPermissions.CreateShipmentKPIs]);
  }

  get canUpdateShipmentKPIs(): boolean {
    return this.hasPermission([UserPermissions.UpdateShipmentKPIs]);
  }

  get canDeleteShipmentKPIs(): boolean {
    return this.hasPermission([UserPermissions.DeleteShipmentKPIs]);
  }

  get canPauseResumeProductRegistration(): boolean {
    return this.hasPermission([UserPermissions.PauseResumeProductRegistration]);
  }

  get canViewShipmentActivities(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentActivities]);
  }

  get canCreateShipmentActivities(): boolean {
    return this.hasPermission([UserPermissions.CreateShipmentActivities]);
  }

  get canUpdateShipmentActivities(): boolean {
    return this.hasPermission([UserPermissions.UpdateShipmentActivities]);
  }

  get canDeleteShipmentActivities(): boolean {
    return this.hasPermission([UserPermissions.DeleteShipmentActivities]);
  }

  get canViewRegistrationActivities(): boolean {
    return this.hasPermission([UserPermissions.ViewRegistrationActivities]);
  }

  get canCreateRegistrationActivities(): boolean {
    return this.hasPermission([UserPermissions.CreateRegistrationActivities]);
  }

  get canUpdateRegistrationActivities(): boolean {
    return this.hasPermission([UserPermissions.UpdateRegistrationActivities]);
  }

  get canDeleteRegistrationActivities(): boolean {
    return this.hasPermission([UserPermissions.DeleteRegistrationActivities]);
  }

  get canViewRegistrationWorkflows(): boolean {
    return this.hasPermission([UserPermissions.ViewRegistrationWorkflows]);
  }

  get canViewActivityLogTab(): boolean {
    return this.hasPermission([UserPermissions.ViewActivityLogTab]);
  }

  get canViewLeadTimesTab(): boolean {
    return this.hasPermission([UserPermissions.ViewLeadTimesTab]);
  }

  get canViewOverviewTab(): boolean {
    return this.hasPermission([UserPermissions.ViewOverviewTab]);
  }

  get canViewProductsTab(): boolean {
    return this.hasPermission([UserPermissions.ViewProductsTab]);
  }

  get canCreateRegistrationWorkflows(): boolean {
    return this.hasPermission([UserPermissions.CreateRegistrationWorkflows]);
  }

  get canUpdateRegistrationWorkflows(): boolean {
    return this.hasPermission([UserPermissions.UpdateRegistrationWorkflows]);
  }

  get canDeleteRegistrationWorkflows(): boolean {
    return this.hasPermission([UserPermissions.DeleteRegistrationWorkflows]);
  }

  get canCancelProductRegistration(): boolean {
    return this.hasPermission([UserPermissions.CancelProductRegistration]);
  }

  get canDeleteProductFromRegistration(): boolean {
    return this.hasPermission([UserPermissions.DeleteProductFromRegistration]);
  }

  get canViewCarriers(): boolean {
    return this.hasPermission([UserPermissions.ViewCarriers]);
  }

  get canCreateCarriers(): boolean {
    return this.hasPermission([UserPermissions.CreateCarriers]);
  }

  get canUpdateCarriers(): boolean {
    return this.hasPermission([UserPermissions.UpdateCarriers]);
  }

  get canDeleteCarriers(): boolean {
    return this.hasPermission([UserPermissions.DeleteCarriers]);
  }

  get canViewProductRegistrationKPI(): boolean {
    return this.hasPermission([UserPermissions.ViewProductRegistrationKPIs]);
  }

  get canCreateProductRegistrationKPI(): boolean {
    return this.hasPermission([UserPermissions.CreateProductRegistrationKPIs]);
  }

  get canUpdateProductRegistrationKPI(): boolean {
    return this.hasPermission([UserPermissions.UpdateProductRegistrationKPIs]);
  }

  get canDeleteProductRegistrationKPI(): boolean {
    return this.hasPermission([UserPermissions.DeleteProductRegistrationKPIs]);
  }

  get canViewChargeType(): boolean {
    return this.hasPermission([UserPermissions.ViewChargeType]);
  }

  get canCreateChargeType(): boolean {
    return this.hasPermission([UserPermissions.CreateChargeType]);
  }

  get canUpdateChargeType(): boolean {
    return this.hasPermission([UserPermissions.UpdateChargeType]);
  }

  get canDeleteChargeType(): boolean {
    return this.hasPermission([UserPermissions.DeleteChargeType]);
  }

  get canViewShipmentWorkflow(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentWorkflow]);
  }

  get canCreateShipmentWorkflow(): boolean {
    return this.hasPermission([UserPermissions.CreateShipmentWorkflow]);
  }

  get canUpdateShipmentWorkflow(): boolean {
    return this.hasPermission([UserPermissions.UpdateShipmentWorkflow]);
  }

  get canDeleteShipmentWorkflow(): boolean {
    return this.hasPermission([UserPermissions.DeleteShipmentWorkflow]);
  }

  get canDeleteCustomers(): boolean {
    return this.hasPermission([UserPermissions.DeleteCustomers]);
  }

  get canViewCities(): boolean {
    return this.hasPermission([UserPermissions.ViewCities]);
  }

  get canCreateCities(): boolean {
    return this.hasPermission([UserPermissions.CreateCities]);
  }

  get canUpdateCities(): boolean {
    return this.hasPermission([UserPermissions.UpdateCities]);
  }

  get canDeleteCities(): boolean {
    return this.hasPermission([UserPermissions.DeleteCities]);
  }

  get canViewCurrency(): boolean {
    return this.hasPermission([UserPermissions.ViewCurrency]);
  }

  get canCreateCurrency(): boolean {
    return this.hasPermission([UserPermissions.CreateCurrency]);
  }

  get canUpdateCurrency(): boolean {
    return this.hasPermission([UserPermissions.UpdateCurrency]);
  }

  get canDeleteCurrency(): boolean {
    return this.hasPermission([UserPermissions.DeleteCurrency]);
  }

  get canViewUsers(): boolean {
    return this.hasPermission([UserPermissions.ViewUsers]);
  }

  get canCreateUsers(): boolean {
    return this.hasPermission([UserPermissions.CreateUsers]);
  }

  get canUpdateUsers(): boolean {
    return this.hasPermission([UserPermissions.UpdateUsers]);
  }

  get canDeleteUsers(): boolean {
    return this.hasPermission([UserPermissions.DeleteUsers]);
  }

  get canViewDistricts(): boolean {
    return this.hasPermission([UserPermissions.ViewDistricts]);
  }

  get canCreateDistricts(): boolean {
    return this.hasPermission([UserPermissions.CreateDistricts]);
  }

  get canUpdateDistricts(): boolean {
    return this.hasPermission([UserPermissions.UpdateDistricts]);
  }

  get canDeleteDistricts(): boolean {
    return this.hasPermission([UserPermissions.DeleteDistricts]);
  }

  get canViewDocumentType(): boolean {
    return this.hasPermission([UserPermissions.ViewDocumentType]);
  }

  get canCreateDocumentType(): boolean {
    return this.hasPermission([UserPermissions.CreateDocumentType]);
  }

  get canUpdateDocumentType(): boolean {
    return this.hasPermission([UserPermissions.UpdateDocumentType]);
  }

  get canDeleteDocumentType(): boolean {
    return this.hasPermission([UserPermissions.DeleteDocumentType]);
  }

  get canViewProductCategories(): boolean {
    return this.hasPermission([UserPermissions.ViewProductCategories]);
  }

  get canCreateProductCategories(): boolean {
    return this.hasPermission([UserPermissions.CreateProductCategories]);
  }

  get canUpdateProductCategories(): boolean {
    return this.hasPermission([UserPermissions.UpdateProductCategories]);
  }

  get canDeleteProductCategories(): boolean {
    return this.hasPermission([UserPermissions.DeleteProductCategories]);
  }

  get canViewLeadTimes(): boolean {
    return this.hasPermission([UserPermissions.ViewLeadTimes]);
  }

  get canUpdateLeadTimes(): boolean {
    return this.hasPermission([UserPermissions.UpdateLeadTimes]);
  }

  get canViewProductRegistrationLeadTimes(): boolean {
    return this.hasPermission([
      UserPermissions.ViewProductRegistrationLeadTimes
    ]);
  }

  get canUpdateProductRegistrationLeadTimes(): boolean {
    return this.hasPermission([
      UserPermissions.UpdateProductRegistrationLeadTimes
    ]);
  }

  get canViewFreePLProfile(): boolean {
    return this.hasPermission([UserPermissions.ViewFreePLProfile]);
  }

  get canUpdateFreePLProfile(): boolean {
    return this.hasPermission([UserPermissions.UpdateFreePLProfile]);
  }

  get canViewEmployees(): boolean {
    return this.hasPermission([UserPermissions.ViewEmployees]);
  }

  get canUpdateEmployees(): boolean {
    return this.hasPermission([UserPermissions.UpdateEmployees]);
  }

  get canCreateEmployees(): boolean {
    return this.hasPermission([UserPermissions.CreateEmployees]);
  }

  get canViewReports(): boolean {
    return this.hasPermission([UserPermissions.ViewReports]);
  }

  get canViewShippersConsignees(): boolean {
    return this.hasPermission([UserPermissions.ViewShippersConsignees]);
  }

  get canCreateShippersConsignees(): boolean {
    return this.hasPermission([UserPermissions.CreateShippersConsignees]);
  }

  get canUpdateShippersConsignees(): boolean {
    return this.hasPermission([UserPermissions.UpdateShippersConsignees]);
  }

  get canDeleteShippersConsignees(): boolean {
    return this.hasPermission([UserPermissions.DeleteShippersConsignees]);
  }

  get canUpdateFreePLCustomers(): boolean {
    return this.hasPermission([UserPermissions.UpdateFreePLCustomers]);
  }

  get canCreateFreePLCustomers(): boolean {
    return this.hasPermission([UserPermissions.CreateFreePLCustomers]);
  }

  get canViewFreePLCustomers(): boolean {
    return this.hasPermission([UserPermissions.ViewFreePLCustomers]);
  }

  get canManageNonMedicalCompanies(): boolean {
    return this.hasPermission([UserPermissions.ManageNonMedicalCompanies]);
  }

  get canManageMedicalCompanies(): boolean {
    return this.hasPermission([UserPermissions.ManageMedicalCompanies]);
  }

  get canCreateQuote(): boolean {
    return this.hasPermission([UserPermissions.CreateQuote]);
  }

  get canCreateQuoteProposal(): boolean {
    return this.hasPermission([UserPermissions.CreateQuoteProposal]);
  }

  get canSubmitQuoteProposal(): boolean {
    return this.hasPermission([UserPermissions.SubmitQuoteProposal]);
  }

  get canViewQuoteProposal(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteProposal]);
  }

  get canDuplicateShipment(): boolean {
    return this.hasPermission([UserPermissions.DuplicateShipment]);
  }

  get canViewActiveShipmentsOnDashboard(): boolean {
    return this.hasPermission([UserPermissions.ViewActiveShipmentsOnDashboard]);
  }

  get canCancelShipment(): boolean {
    return this.hasPermission([UserPermissions.CancelShipments]);
  }

  get canViewShipmentActivityLog(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentActivityLog]);
  }

  get canViewShipmentDetails(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentDetails]);
  }

  get canViewShipmentDocuments(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentDocuments]);
  }

  get canPostShipmentActivity(): boolean {
    return this.hasPermission([UserPermissions.PostShipmentActivity]);
  }

  get canViewRegistrationListAccess(): boolean {
    return this.hasPermission([UserPermissions.EnableRegistrationListAccess]);
  }

  get canDeletePostedShipmentActivity(): boolean {
    return this.hasPermission([UserPermissions.DeletePostedShipmentActivity]);
  }

  get canViewShipmentLeadTimes(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentLeadTimes]);
  }

  get canViewShipmentServicesAndCharges(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentServicesAndCharges]);
  }

  get canAddShipmentCharge(): boolean {
    return this.hasPermission([UserPermissions.AddShipmentCharge]);
  }

  get canEditShipmentCharge(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentCharge]);
  }

  get canDeleteShipmentCharge(): boolean {
    return this.hasPermission([UserPermissions.DeleteShipmentCharge]);
  }

  get canViewShipmentProgressCard(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentProgressCard]);
  }

  get canViewAndEditTruckingDetails(): boolean {
    return this.hasPermission([UserPermissions.ViewAndEditTruckingDetails]);
  }

  get canEditShipmentsActualDates(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentsActualDates]);
  }

  get canEditShipmentsExpectedDates(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentsExpectedDates]);
  }

  get canUploadShipmentDocument(): boolean {
    return this.hasPermission([UserPermissions.UploadShipmentDocument]);
  }

  get canReplaceShipmentDocument(): boolean {
    return this.hasPermission([UserPermissions.ReplaceShipmentDocument]);
  }

  get canDeleteShipmentDocument(): boolean {
    return this.hasPermission([UserPermissions.DeleteShipmentDocument]);
  }

  get canDownloadShipmentDocument(): boolean {
    return this.hasPermission([UserPermissions.DownloadShipmentDocument]);
  }

  get canSendCommentsToShipment(): boolean {
    return this.hasPermission([UserPermissions.SendCommentsToShipment]);
  }

  get canViewQuoteProposalTab(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteProposalTab]);
  }
  get canViewQuoteSummaryTab(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteSummaryTab]);
  }
  get canViewQuoteSummaryRoutingSection(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteSummaryRoutingSection]);
  }
  get canEditQuoteSummaryRoutingSection(): boolean {
    return this.hasPermission([UserPermissions.EditQuoteSummaryRoutingSection]);
  }

  get canViewQuotes(): boolean {
    return (
      this.canCreateQuote ||
      this.canCreateQuoteProposal ||
      this.canViewQuoteProposal ||
      this.canAcceptAndRejectQuoteProposal ||
      this.canViewAnyQuoteListTab
    );
  }

  get canAcceptAndRejectQuoteProposal(): boolean {
    return this.hasPermission([UserPermissions.AcceptAndRejectQuoteProposal]);
  }

  get isMedicalOperationalOversight(): boolean {
    let hasBothMedicalAndNonMedicalPermissions =
      this.hasManageMedicalOperationalOversightPermission &&
      this.hasManageNonMedicalOperationalOversightPermission;

    if (
      this.hasManageMedicalOperationalOversightPermission &&
      !hasBothMedicalAndNonMedicalPermissions
    ) {
      return true;
    }

    if (
      this.hasManageNonMedicalOperationalOversightPermission &&
      !hasBothMedicalAndNonMedicalPermissions
    ) {
      return false;
    }

    return null;
  }

  get activityCategoryPermissions(): any {
    let activityCategoryPerms = [
      UserPermissions.ViewOperationalActivites,
      UserPermissions.ViewForm4Activities,
      UserPermissions.ViewFreightActivities,
      UserPermissions.ViewCustomsClearanceActivities,
      UserPermissions.ViewTruckingActivities,
      UserPermissions.ViewInvoicingOperationsActivities,
      UserPermissions.ViewInvoicingFinanceActivities,
      UserPermissions.ViewAgricultureInspectionActivities,
      UserPermissions.ViewNTRAInspectionActivities,
      UserPermissions.ViewGOIECInspectionActivities,
      UserPermissions.ViewChemicalInspectionActivities,
      UserPermissions.ViewNationalSecurityInspectionActivities,
      UserPermissions.ViewScaleAndBalanceInspectionActivities
    ];
    return this.userPermissions.filter(e => activityCategoryPerms.includes(e));
  }

  get canEditShipmentReferenceNumbers(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentReferenceNumbers]);
  }

  get canPauseResumeShipment(): boolean {
    return this.hasPermission([UserPermissions.PauseResumeShipment]);
  }

  get canViewShipments(): boolean {
    return this.hasPermission([
      UserPermissions.ViewShipmentsForMedicalCustomers,
      UserPermissions.ViewShipmentsForNonMedicalCustomers,
      UserPermissions.ViewReadyForInvoicePreparationShipments,
      UserPermissions.ViewReadyForInvoiceIssuanceShipments
    ]);
  }

  get canViewShipmentsForSelectedCustomer(): boolean {
    return this.hasPermission([
      UserPermissions.ViewShipmentsForSelectedCustomer
    ]);
  }

  get canEditAdditionalShipmentDetailsSection(): boolean {
    return this.hasPermission([UserPermissions.EditAdditionalShipmentDetails]);
  }

  get canCreateShipment(): boolean {
    return this.hasPermission([UserPermissions.CreateShipment]);
  }

  get canEditOriginDestination(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentOriginDestination]);
  }

  get canEditTheInvolvedPartiesSection(): boolean {
    return this.hasPermission([UserPermissions.EditTheInvolvedPartiesSection]);
  }

  get canInitiateForm4Process(): boolean {
    return this.hasPermission([UserPermissions.InitiateForm4Process]);
  }

  get canUpdateForm4Status(): boolean {
    return this.hasPermission([UserPermissions.UpdateForm4Status]);
  }

  get canEditShipmentPackingDetails(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentPackingDetails]);
  }

  get canEditShipmentProducts(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentProducts]);
  }

  get canUpdateShipmentStatus(): boolean {
    return this.hasPermission([UserPermissions.UpdateShipmentStatus]);
  }

  get canUndoShipmentStatusUpdate(): boolean {
    return this.hasPermission([UserPermissions.UndoShipmentStatusUpdate]);
  }

  get canSendPendingActionToShipment(): boolean {
    return this.hasPermission([UserPermissions.SendPendingActionToShipment]);
  }

  get canAssignShiperConsignee(): boolean {
    return this.hasPermission([UserPermissions.AssignShiperConsignee]);
  }

  get canViewQuoteListReceivedTab(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteListReceivedTab]);
  }

  get canViewQuoteListPendingApprovalTab(): boolean {
    return this.hasPermission([
      UserPermissions.ViewQuoteListPendingApprovalTab
    ]);
  }

  get canViewQuoteListApprovedTab(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteListApprovedTab]);
  }

  get canViewQuoteListArchivedTab(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteListArchivedTab]);
  }

  get canViewAnyQuoteListTab(): boolean {
    return (
      this.canViewQuoteListReceivedTab ||
      this.canViewQuoteListPendingApprovalTab ||
      this.canViewQuoteListApprovedTab ||
      this.canViewQuoteListArchivedTab
    );
  }

  get canSetOrFulfillQuotePendingAction(): boolean {
    return this.hasPermission([UserPermissions.SetOrFulfillQuotePendingAction]);
  }

  get canSendCommentToQuote(): boolean {
    return this.hasPermission([UserPermissions.SendCommentToQuote]);
  }

  get canViewQuoteDocuments(): boolean {
    return this.hasPermission([UserPermissions.ViewQuoteDocuments]);
  }

  get canEditQuoteDocuments(): boolean {
    return this.hasPermission([UserPermissions.EditQuoteDocuments]);
  }
  get canViewQuoteSummaryCargoSection(): boolean {
    return this.hasPermission([
      UserPermissions.ViewQuoteSummaryCargoDetailsSection
    ]);
  }
  get canEditQuoteSummaryCargoSection(): boolean {
    return this.hasPermission([
      UserPermissions.EditQuoteSummaryCargoDetailsSection
    ]);
  }
  get canCancelQuote(): boolean {
    return this.hasPermission([UserPermissions.CancelQuote]);
  }

  get canEditTruckingService(): boolean {
    return this.hasPermission([
      UserPermissions.EditTruckingServiceQuoteProposalCard
    ]);
  }

  get canViewTruckingService(): boolean {
    return this.hasPermission([
      UserPermissions.ViewTruckingServiceQuoteProposalCard
    ]);
  }
  get canViewQuoteProposalIorOrEorService(): boolean {
    return this.hasPermission([
      UserPermissions.ViewQuoteProposalIorOrEorService
    ]);
  }
  get canEditQuoteProposalIorOrEorService(): boolean {
    return this.hasPermission([
      UserPermissions.EditQuoteProposalIorOrEorService
    ]);
  }
  get canViewQuoteProposalFreightService(): boolean {
    return this.hasPermission([
      UserPermissions.ViewQuoteProposalFreightService
    ]);
  }
  get canEditQuoteProposalFreightService(): boolean {
    return this.hasPermission([
      UserPermissions.EditQuoteProposalFreightService
    ]);
  }
  get canViewQuoteProposalClearanceService(): boolean {
    return this.hasPermission([
      UserPermissions.ViewQuoteProposalClearanceService
    ]);
  }
  get canEditQuoteProposalClearanceService(): boolean {
    return this.hasPermission([
      UserPermissions.EditQuoteProposalClearanceService
    ]);
  }
  get canManageRegistrationStatus(): boolean {
    return this.hasPermission([
      UserPermissions.EnableRegistrationStatusManagment
    ]);
  }
  get canCreateProductRegistration(): boolean {
    return this.hasPermission([UserPermissions.CreateProductRegistration]);
  }
  get canEditProductRegistration(): boolean {
    return this.hasPermission([UserPermissions.EditProductRegistration]);
  }
  get canCreateProduct(): boolean {
    return this.hasPermission([UserPermissions.CreateProduct]);
  }
  get canDeleteProduct(): boolean {
    return this.hasPermission([UserPermissions.DeleteProduct]);
  }
  get canViewProductsList(): boolean {
    return this.hasPermission([UserPermissions.ProductListView]);
  }
  get canUploadProductDocument(): boolean {
    return this.hasPermission([UserPermissions.UploadProductDocument]);
  }
  get canDownloadProductDocument(): boolean {
    return this.hasPermission([UserPermissions.DownloadProductDocument]);
  }
  get canReplaceProductDocument(): boolean {
    return this.hasPermission([UserPermissions.ReplaceProductDocument]);
  }
  get canDeleteProductDocument(): boolean {
    return this.hasPermission([UserPermissions.DeleteProductDocument]);
  }

  get canSendPendingActionToRegistration(): boolean {
    return this.hasPermission([
      UserPermissions.SetOrFulfillRegistrationPendingAction
    ]);
  }

  get canSendCommentsToRegistration(): boolean {
    return this.hasPermission([UserPermissions.SendCommentsToRegistration]);
  }

  get canViewProductOverviewTab(): boolean {
    return this.hasPermission([UserPermissions.ViewProductOverviewTab]);
  }

  get canViewComplianceDetailsManagement(): boolean {
    return this.hasPermission([
      UserPermissions.ViewComplianceDetailsManagement
    ]);
  }

  get canViewProductDocumentsManagement(): boolean {
    return this.hasPermission([UserPermissions.ViewProductDocumentsManagement]);
  }

  get canUpdateProductStatus(): boolean {
    return this.hasPermission([UserPermissions.UpdateProductStatus]);
  }

  get canEditProductDetailsManagement(): boolean {
    return this.hasPermission([UserPermissions.EditProductDetailsManagement]);
  }

  get canEditComplianceDetailsManagement(): boolean {
    return this.hasPermission([
      UserPermissions.EditComplianceDetailsManagement
    ]);
  }

  get canAccessWarehousingList(): boolean {
    return this.hasPermission([UserPermissions.AccessWarehousingList]);
  }

  get canViewAssignedShipments(): boolean {
    return this.hasPermission([UserPermissions.ViewAssignedShipments]);
  }

  get canViewApprovalActivityLogTab(): boolean {
    return this.hasPermission([UserPermissions.ViewApprovalActivityLogTab]);
  }
  get canViewApprovalChargesTab(): boolean {
    return this.hasPermission([UserPermissions.ViewApprovalChargesTab]);
  }
  get canViewApprovalLeadTimesTab(): boolean {
    return this.hasPermission([UserPermissions.ViewApprovalLeadTimesTab]);
  }
  get canViewApprovalOverviewTab(): boolean {
    return this.hasPermission([UserPermissions.ViewApprovalOverviewTab]);
  }
  get canViewApprovalProductsTab(): boolean {
    return this.hasPermission([UserPermissions.ViewApprovalProductsTab]);
  }

  get canEditApprovalDetails(): boolean {
    return this.hasPermission([UserPermissions.EditApprovalDetails]);
  }

  get canCreateApproval(): boolean {
    return this.hasPermission([UserPermissions.CreateApproval]);
  }
  get canAccessApprovalList(): boolean {
    return this.hasPermission([UserPermissions.EnableApprovalListAccess]);
  }

  get canCancelApproval(): boolean {
    return this.hasPermission([UserPermissions.CancelApproval]);
  }

  get canUpdateApprovalStatus(): boolean {
    return this.hasPermission([UserPermissions.UpdateApprovalStatus]);
  }

  get canCreateTenant(): boolean {
    return this.hasPermission([UserPermissions.CreateTenant]);
  }

  get canViewTenant(): boolean {
    return this.hasPermission([UserPermissions.ViewTenant]);
  }

  get canUpdateTenant(): boolean {
    return this.hasPermission([UserPermissions.UpdateTenant]);
  }

  get canDeleteTenant(): boolean {
    return this.hasPermission([UserPermissions.DeleteTenant]);
  }

  get canTrackingDHLExpress(): boolean {
    return this.hasPermission([UserPermissions.TrackingDHLExpress]);
  }

  get canTrackingSeaRates(): boolean {
    return this.hasPermission([UserPermissions.TrackingSeaRates]);
  }

  get canCreatePlan(): boolean {
    return this.hasPermission([UserPermissions.CreatePlan]);
  }

  get canViewPlan(): boolean {
    return this.hasPermission([UserPermissions.ViewPlan]);
  }

  get canUpdatePlan(): boolean {
    return this.hasPermission([UserPermissions.UpdatePlan]);
  }

  get canDeletePlan(): boolean {
    return this.hasPermission([UserPermissions.DeletePlan]);
  }

  get canViewShipmentACL(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentACL]);
  }

  get canAddShipmentParty(): boolean {
    return this.hasPermission([UserPermissions.AddShipmentParty]);
  }

  get canRemoveShipmentParty(): boolean {
    return this.hasPermission([UserPermissions.RemoveShipmentParty]);
  }

  get canViewApprovleWorkflow(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsWorkflowManagementList
    ]);
  }
  get canDeleteApprovelWorkflow(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsWorkflowManagementDelete
    ]);
  }
  get canUpdateApprovelWorkflow(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsWorkflowManagementUpdate
    ]);
  }
  get canCreateApprovelWorkflow(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsWorkflowManagementCreate
    ]);
  }
  get canViewApprovalActivities(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsActivityManagementList
    ]);
  }

  get canCreateApprovalActivities(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsActivityManagementCreate
    ]);
  }

  get canUpdateApprovalActivities(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsActivityManagementUpdate
    ]);
  }

  get canDeleteApprovalActivities(): boolean {
    return this.hasPermission([
      UserPermissions.ApprovalsActivityManagementDelete
    ]);
  }

  get canListApprovalKPIs(): boolean {
    return this.hasPermission([UserPermissions.ListApprovalKPIs]);
  }
  get canCreateApprovalKPIs(): boolean {
    return this.hasPermission([UserPermissions.CreateApprovalKPIs]);
  }
  get canUpdateApprovalKPIs(): boolean {
    return this.hasPermission([UserPermissions.UpdateApprovalKPIs]);
  }
  get canDeleteApprovalKPIs(): boolean {
    return this.hasPermission([UserPermissions.DeleteApprovalKPIs]);
  }

  get canCreateShipmentStatus(): boolean {
    return this.hasPermission([UserPermissions.CreateShipmentStatus]);
  }

  get canEditShipmentStatus(): boolean {
    return this.hasPermission([UserPermissions.EditShipmentStatus]);
  }

  get canViewShipmentStatus(): boolean {
    return this.hasPermission([UserPermissions.ViewShipmentStatus]);
  }

  get canDeleteShipmentStatus(): boolean {
    return this.hasPermission([UserPermissions.DeleteShipmentStatus]);
  }
}
