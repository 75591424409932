<div [class]="'leadtime-card ' + status">
  <div class="day-count">
    <svg-icon name="timer-icon" class="icon"></svg-icon>
    <span> {{ lead?.netDays }} Days </span>
  </div>

  <div class="details row mx-0">
    <div class="col-6 d-flex gap-2 w-100 px-0">
      <div>
        <svg-icon name="arrow-circle-right-icon" class="icon"></svg-icon>
      </div>
      <div class="d-flex flex-column align-items-start">
        <h3 class="title">
          {{ lead?.name }}
        </h3>
        <p class="startdate">Started at : {{ startedDate }}</p>
      </div>
    </div>
    <div class="col-3 expected-date py-2">
      <p class="title">Planned End Lead Time / Date</p>
      <p>{{ lead?.expectedLeadTime }} Days</p>
      <p>{{ expectedEndDate }}</p>
    </div>
    <div class="col-3 actual-date py-2" [ngClass]="{ active: lead?.endedDate }">
      <p class="title">Actual End Lead Time / Date</p>
      <p>{{ lead?.endedDate ? lead?.netDays : "-- Days" }}</p>
      <p>
        {{ endedDate ? endedDate : "-- -- ----, --:-- --" }}
      </p>
    </div>
  </div>
  <div class="pt-3">
    <div class="d-flex align-items-center gap-2 mb-2">
      <app-checkbox
        (click)="showNoteInput()"
        [checked]="isNoteInputShowen"
      ></app-checkbox>
      <span>Any Notes</span>
    </div>
    <textarea
      [(ngModel)]="insertedNote"
      class="note-input"
      rows="3"
      *ngIf="isNoteInputShowen"
      maxlength="100"
      (keydown)="onNotesChange()"
    ></textarea>
  </div>
</div>
