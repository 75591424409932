<app-admin-page-details
  [title]="pageTitle"
  [isFormValid]="isFormValid"
  (submitButtonClicked)="saveORCancel($event)"
>
  <ng-container pageBody>
    <div class="page-container" [formGroup]="cityForm">
      <app-error-badge
        class="warning-badge"
        [message]="
          'Required fields are either left empty or filled incorrectly. Check all fields highlighted in red and try again.'
        "
        *ngIf="isInvalidForm"
      ></app-error-badge>
      <div class="row mx-0" [class]="{ 'invalid-form-padding': isInvalidForm }">
        <div
          class="field-container d-flex justify-content-between align-items-end"
        >
          <div class="input-field-container w-100">
            <label class="field-title w-100">
              {{ "config.management.City.CityName" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="60"
              name="input-city"
              formControlName="city"
              [ngClass]="{
                'is-invalid-field': isInvalidFormControl('city')
              }"
            />
          </div>
        </div>
        <div
          class="field-container d-flex justify-content-between align-items-end"
        >
          <div class="input-field-container w-100">
            <label class="field-title w-100">
              {{ "config.management.City.CityAscii" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              name="input-cityAscii"
              formControlName="cityAscii"
              [ngClass]="{
                'is-invalid-field': isInvalidFormControl('cityAscii')
              }"
            />
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div
          class="field-container d-flex justify-content-between align-items-end"
        >
          <div class="input-field-container w-100">
            <label class="field-title w-100">
              {{ "config.management.City.CityUNLocode" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              name="input-unLocode"
              formControlName="unLocode"
              [ngClass]="{
                'is-invalid-field': isInvalidFormControl('unLocode')
              }"
            />
          </div>
        </div>
        <div
          class="field-container d-flex justify-content-between align-items-end"
        >
          <div class="input-field-container w-100">
            <label class="field-title w-100">
              {{ "config.management.City.CountryName" | translate
              }}<span class="text-error">*</span>
            </label>
            <ng-select
              class="form-control field-input"
              maxlength="11"
              name="ddl-country"
              formControlName="countryId"
              bindValue="id"
              bindLabel="name"
              [items]="countries"
              [clearable]="false"
              [ngClass]="{
                'is-invalid-field': isInvalidFormControl('countryId')
              }"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <mat-radio-button
                  [value]="item.id"
                  [checked]="isFormControlSelected(item.id, 'countryId')"
                  >{{ item.name }}</mat-radio-button
                >
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row mx-0">
        <div
          class="field-container d-flex justify-content-between align-items-end"
        >
          <div class="input-field-container w-100">
            <label class="field-title w-100">
              {{ "config.management.City.Latitude" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="11"
              name="input-latitude"
              formControlName="lat"
              [ngClass]="{
                'is-invalid-field': isInvalidFormControl('lat')
              }"
              max="180"
              min="-180"
            />
          </div>
        </div>
        <div
          class="field-container d-flex justify-content-between align-items-end"
        >
          <div class="input-field-container w-100">
            <label class="field-title w-100">
              {{ "config.management.City.Longtitude" | translate
              }}<span class="text-error">*</span>
            </label>
            <input
              type="text"
              class="form-control field-input"
              maxlength="11"
              name="longitude"
              formControlName="lng"
              [ngClass]="{
                'is-invalid-field': isInvalidFormControl('lng')
              }"
              max="180"
              min="-180"
            />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-admin-page-details>
