<div class="page-details-container">
  <div class="page-body">
    <div class="row d-flex mx-0">
      <app-page-header [title]="title" class="col-8 pl-0"></app-page-header>
      <div class="note col-4 pr-0 text-right">
        (<span class="required-text">*</span>) means that this field is
        mandatory
      </div>
    </div>
    <ng-content
      *ngIf="isShowErrorAlert"
      select="[errorMessageAlert]"
    ></ng-content>
    <ng-content select="[pageBody]"></ng-content>
  </div>

  <div class="m-0 btn-operation position-relative">
    <div class="text-right p-0 justify-content-end">
      <button
        class="btn btn-cancel bg-transparent"
        (click)="onSubmitButtonClicked(false)"
      >
        Cancel
      </button>
      <button
        class="btn btn-save"
        (click)="onSubmitButtonClicked(true)"
        [disabled]="!isFormValid"
      >
        Save
      </button>
    </div>
  </div>
</div>
