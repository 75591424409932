<div class="remove-note">
  <div class="title">
    Your <strong>note</strong> will be <strong>deleted</strong> because you
    unselected the checkbox
  </div>

  <div class="form-buttons">
    <button class="form-btn cancel" (click)="cancel()">Cancel</button>
    <button class="form-btn delete-charge" (click)="removeNotes()">
      Confirm
    </button>
  </div>
  <div class="deletion-warning">
    <svg-icon [name]="'mark!'"></svg-icon>
    <div class="deletion-warning-text">This action cannot be undone.</div>
  </div>
</div>
