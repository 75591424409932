import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { NotificationService } from '../../services/notification.service';
import { ProductRegistrationHelperService } from 'src/app/create-product-registration/service/product-registration-helper.service';
import { ShipmentHelpersService } from 'src/app/shipments/services/shipment-helpers.service';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { CompanyService } from 'src/app/company/services/company.service';
import { Company } from 'src/app/company/models/company.model';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import { DialogService } from '../../services/dialog.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-white-nav-bar',
  templateUrl: './white-nav-bar.component.html',
  styleUrls: ['./white-nav-bar.component.scss']
})
export class WhiteNavBarComponent implements OnInit {
  private companyNameSubject = new Subject<string>();
  private baseCompanies: Company[];
  defaultCompany: string;
  companies: Company[];
  defaultCompanyName: string;

  totalNumberOfCompanies: number = 0;
  pageIndex: number = 0;
  pageSize: number = 5;
  isCompaniesLoading: boolean = false;
  @ViewChild('companiesDDL') companiesDDL!: NgSelectComponent;
  disableSearchFn = (): boolean => true;
  logoUrl = 'assets/images/PNG/FreePL_Logo.png';
  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private shipmentHelpersService: ShipmentHelpersService,
    private productRegistrationHelperService: ProductRegistrationHelperService,
    private accountService: AccountService,
    private productHelperService: ProductHelperService,
    private companyService: CompanyService,
    private quoteHelperService: CreateQuoteHelperService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.authService.tenantLogo) {
      this.logoUrl = this.authService.tenantLogo;
    }

    if (!this.isAdmin) {
      this.defaultCompany = this.authService.currentUser.defaultCustomerCompany;

      if (this.canManageMedicalCompanies || this.canManageNonMedicalCompanies) {
        this.getFreePLEmployeeCustomerCompanies();
      }

      this.companyService.customerCompanySwitched.subscribe(data => {
        this.defaultCompany = data['companyId'];
        this.defaultCompanyName = this.companies.find(
          c => c.id.toString() == this.defaultCompany
        )?.name;

        if (!this.defaultCompanyName) {
          this.getCompanyById();
        }
      });

      this.setupCompanySearch();
    }
  }

  setupCompanySearch(): void {
    this.companyNameSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(companyName =>
          this.companyService.searchFreePLEmployeeCustomerCompanies(
            this.authService.currentUser.employeeId,
            companyName
          )
        )
      )
      .subscribe(
        _companies => {
          this.companies = _companies;
        },
        error => {
          console.log(error);
        }
      );
  }

  onCompanyNameChange(searchTerm: any): void {
    let companyName = searchTerm.term;
    if (companyName.length >= 3) {
      this.companyNameSubject.next(companyName);
    } else {
      this.companies = this.baseCompanies;
    }
  }

  getCompanyById(): void {
    if (this.defaultCompany == '1') {
      return;
    }
    this.companyService.getCompany(+this.defaultCompany).subscribe(company => {
      this.companies = [company, ...this.companies];
      this.defaultCompanyName = this.companies.find(
        c => c.id.toString() == this.defaultCompany
      )?.name;
    });
  }

  getFreePLEmployeeCustomerCompanies(): void {
    this.companyService
      .getFreePLEmployeeCustomerCompanies(
        this.authService.currentUser.employeeId,
        this.pageSize,
        this.pageIndex
      )
      .subscribe(data => {
        this.processCompanyData(data);
        this.baseCompanies = this.companies;
      });
  }

  processCompanyData(data: any): void {
    if (this.companies?.length > 0) {
      this.isCompaniesLoading = false;
      let items = data?.items?.filter(
        item => !this.companies.some(c => c.id == item.id)
      );
      this.companies = this.companies.concat(items);
      if (data?.items.length > 0) {
        this.addToCustomerCompanies(data?.items);
      }
    } else {
      this.companies = data?.items;
      this.totalNumberOfCompanies = data?.totalItems;
      this.defaultCompanyName = this.companies.find(
        c => c.id.toString() == this.defaultCompany
      )?.name;

      if (!this.defaultCompanyName) {
        this.getCompanyById();
      }
    }
  }

  logout(): void {
    this.authService.logout();
    this.authService.redirectLogoutUser();
    this.notificationService.removeCachedNotifications();
    this.removeStoredFiltersData();
  }

  removeStoredFiltersData(): void {
    this.shipmentHelpersService.clearShipmentsStoredFiltersInBrowser();
    this.shipmentHelpersService.clearEmployeeSelectedShipmentViewColumns();
    this.shipmentHelpersService.clearLastSelectedShipmentsList();
    this.shipmentHelpersService.clearEmployeeShipmentListView();
    this.productRegistrationHelperService.clearEmployeeSelectedColumns();
    this.productHelperService.clearEmployeeSelectedColumns();
    this.quoteHelperService.clearEmployeeSelectedColumns();
    this.quoteHelperService.clearEmployeeSelectedQuoteView();
    this.quoteHelperService.clearQuoteSearchCriteria();
    this.quoteHelperService.clearLastSelectedQuotesLists();
  }

  onChangeDefaultCustomerCompany(): void {
    const companyId: number = this.currenctCompanyId;

    if (companyId && companyId > 0) {
      this.companyService
        .setDefaultCustomerCompanyForFreePLEmployee(
          this.authService.currentUser.employeeId,
          companyId.toString()
        )
        .subscribe(data => {
          this.defaultCompany = companyId.toString();
          this.authService.updateDefaultCompanyDataForCurrentUser(
            this.defaultCompany,
            this.defaultIndustryName,
            true,
            data['companyName']
          );
        });
    }
  }

  onScrollToEnd(): void {
    if (!this.hasGotAllCompanies && !this.isCompaniesLoading) {
      this.loadCompanies();
    }
  }

  loadCompanies(): void {
    this.isCompaniesLoading = true;
    this.pageIndex++;
    this.getFreePLEmployeeCustomerCompanies();
  }

  addToCustomerCompanies(customerCompanies: any[]): void {
    let customerCompaniesIds = customerCompanies.map(c => c.id);
    this.authService.updateUserCustomerCompanies(customerCompaniesIds);
  }

  closeDialogs(): void {
    this.dialogService.closeAll();
  }

  onCompaniesDDLClose(): void {
    this.companies = this.baseCompanies;
    this.companiesDDL.blur();
  }

  get hasGotAllCompanies(): boolean {
    let totalCompaniesRetrieved = (this.pageIndex + 1) * this.pageSize;
    return totalCompaniesRetrieved >= this.totalNumberOfCompanies;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin;
  }

  get canManageMedicalCompanies(): boolean {
    return this.authService.canManageMedicalCompanies;
  }

  get canManageNonMedicalCompanies(): boolean {
    return this.authService.canManageNonMedicalCompanies;
  }

  get currenctCompanyId(): number {
    return this.companies?.find(i => i.name == this.defaultCompanyName)?.id;
  }

  get defaultIndustryName(): string {
    return this.companies?.find(i => i.name == this.defaultCompanyName)
      ?.industry?.name;
  }

  get companyLogo(): string {
    return this.companies?.find(i => i.name == this.defaultCompanyName)?.logo;
  }

  get isUserLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  get profilePictureUrl(): string {
    return this.accountService.currentUser.profilePictureUrl;
  }

  get employeeId(): string {
    return this.authService.currentUser.employeeId;
  }

  get hasUnreadNotifications(): boolean {
    return this.notificationService?.hasUnreadNotifications;
  }

  get fullName(): string {
    return this.authService?.currentUser?.fullName || '';
  }

  get hasSingleCompany(): boolean {
    return this.baseCompanies?.length == 1;
  }
}
