export enum ActivityEnum {
  Invoiced = 'Invoiced',
  Archived = 'Archived',
  Collected = 'Collected',
  OnHold = 'On Hold',
  Resumed = 'Resumed',
  ShipmentSubmitted = 'Shipment Submitted',
  Cancelled = 'Cancelled',
  Form4ProcessInitiated = 'Form 4 process initiated'
}
