<label class="wrapper d-flex align-items-center">
  <input
    [id]="id"
    [disabled]="disabled"
    type="checkbox"
    [value]="value"
    (change)="checkboxChange($event)"
    (click)="onCheckboxClick($event)"
    [ngClass]="{ notChecked: !checked && preventDefaultAction }"
    [(ngModel)]="checked"
    [classList]="'checkmark ' + classes + ' ' + (disabled ? ' disabled ' : ' ')"
  />
</label>
