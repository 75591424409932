import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ShipmentActivityLog } from '../../models/shipment-activity-log.model';
import { ActivityLog } from 'src/app/shared/models/shipment/activity-log.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { UndoComponent } from '../undo/undo.component';
import { ActivityEnum } from '../../enums/activity.enum';
import { ShipmentsService } from '../../services/shipments.service';

@Component({
  selector: 'app-shipment-activity-log',
  templateUrl: './shipment-activity-log.component.html',
  styleUrls: ['./shipment-activity-log.component.scss']
})
export class ShipmentActivityLogComponent implements OnInit, AfterViewInit {
  @Input() isDisplayProgressLine: boolean = false;
  @Input() shipmentActivityLog: ShipmentActivityLog;
  @Input() lastActivityLog: ActivityLog;
  @Input() oceanContainersNumbers: string[];

  @ViewChild('componentElement') componentElement: ElementRef;
  @ViewChild('lineElement') lineElement: ElementRef;
  componentHeight: number = 150;

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    private shipmentService: ShipmentsService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setComponentHeight();

    if (this.isDisplayProgressLine) {
      this.setLineTopPosition();
    }

    this.cdr.detectChanges();

    if (this.shipmentActivityLog?.activityLogDate != null) {
      this.orderActivityLogsByActualDate();
    } else {
      this.orderActivityLogsByEstimateDate();
    }
  }

  orderActivityLogsByActualDate(): void {
    this.shipmentActivityLog.activityLogs.sort(
      (b, a) =>
        new Date(b?.actualDate).getTime() - new Date(a?.actualDate).getTime()
    );
  }

  orderActivityLogsByEstimateDate(): void {
    this.shipmentActivityLog.activityLogs.sort(
      (b, a) =>
        new Date(b?.estimatedDate).getTime() -
        new Date(a?.estimatedDate).getTime()
    );
  }

  setComponentHeight() {
    if (this.componentElement && this.componentElement.nativeElement) {
      this.componentHeight =
        this.componentElement.nativeElement.offsetHeight - 20;
    }
  }

  setLineTopPosition() {
    const currentTop = this.lineElement.nativeElement.offsetTop;
    const newTop = currentTop + 35;
    this.lineElement.nativeElement.style.top = `${newTop}px`;
  }

  isTrackingActivityLog(activityLog: ActivityLog): boolean {
    return activityLog?.userFullName == null;
  }

  isActivityLogFromLeadTime(activityLog: ActivityLog): boolean {
    return activityLog?.isLeadTimeLog;
  }

  getUserTruncatedName(userFullName: string): string {
    return userFullName
      ? `${userFullName[0]}. ${userFullName.split(' ')[1]}`
      : '';
  }

  onShipmentUndo(): void {
    this.dialogService.open(
      UndoComponent,
      {
        shipmentId: this.shipmentId,
        companyId: this.companyId
      },
      '600px',
      null,
      ['undo-dialog']
    );
  }

  isDelayedEstimateDate(activityLog: ActivityLog): boolean {
    return (
      activityLog?.delayedEstimates != null &&
      activityLog?.delayedEstimates?.length > 0
    );
  }

  canUndoActivityLog(activityLog: ActivityLog): boolean {
    return (
      activityLog == this.lastActivityLog &&
      !this.isUndoDisabled &&
      !activityLog?.isSeaRatesActivity &&
      activityLog?.revertAction == null &&
      activityLog?.actualDate != null &&
      this.canUndoShipmentStatusUpdate
    );
  }

  isDelayedActualDate(activityLog: ActivityLog): boolean {
    return (
      activityLog?.delayedActuals != null &&
      activityLog?.delayedActuals?.length > 0
    );
  }

  isAirTrackingActivity(activityLog: ActivityLog): boolean {
    return activityLog?.airPieces != null && activityLog?.weight != null;
  }

  getAirActivityPackageDetails(activityLog: ActivityLog): string {
    return `${activityLog.airPieces} Pieces, ${activityLog.weight} kg`;
  }

  isDelayedActivityLog(activityLog: ActivityLog): boolean {
    return activityLog?.isDelayed;
  }

  isUpcomingActivityLog(activityLog: ActivityLog): boolean {
    return (
      activityLog?.estimatedDate != null && activityLog?.actualDate == null
    );
  }

  isOngoingActivityLog(activityLog: ActivityLog): boolean {
    return (
      activityLog?.isVoid &&
      activityLog?.isDHLActivityLog != true &&
      activityLog?.isSeaRatesActivity != true
    );
  }

  hasDHLPieces(activityLog: ActivityLog): boolean {
    return (
      activityLog?.isDHLActivityLog &&
      activityLog?.dhlPiecesIds != null &&
      activityLog?.dhlPiecesIds?.length > 0
    );
  }

  getDHLPiecesIds(activityLog: ActivityLog): string[] {
    return activityLog?.dhlPiecesIds;
  }
  markCommentAsFulfilled(commentPendingAction: any) {
    this.shipmentService
      .markCommentPendingActionAsFulfilled(
        this.shipmentId,
        commentPendingAction.id
      )
      .subscribe(res => {
        commentPendingAction.isPending = false;
        this.shipmentService.onCommentsUpdate.emit();
      });
  }
  get activityLogDate(): Date {
    return this.shipmentActivityLog?.activityLogDate;
  }

  get activityLogs(): ActivityLog[] {
    return this.shipmentActivityLog?.activityLogs;
  }

  get canUndoShipmentStatusUpdate(): boolean {
    return this.authService.canUndoShipmentStatusUpdate;
  }

  get companyId(): string {
    return this.authService.currentUser.defaultCustomerCompany;
  }

  get isLastStatusSubmitted(): boolean {
    return (
      this.lastActivityLog?.activityName === ActivityEnum.ShipmentSubmitted
    );
  }

  get isUndoDisabled(): boolean {
    return (
      this.lastActivityLog?.activityName === ActivityEnum.ShipmentSubmitted ||
      this.lastActivityLog?.activityName == ActivityEnum.OnHold ||
      this.lastActivityLog?.activityName == ActivityEnum.Cancelled ||
      this.lastActivityLog?.activityName == ActivityEnum.Archived ||
      this.lastActivityLog?.activityName == ActivityEnum.Collected
    );
  }

  get shipmentId(): string {
    return this.shipmentActivityLog?.activityLogs[0]?.shipmentId;
  }

  get isOceanTrackingActivity(): boolean {
    return this.oceanContainersNumbers != null;
  }

  isRevertActivityLog(activityLog: ActivityLog): boolean {
    return (
      activityLog?.reason !=
      'The shipment returned to this status due to an undone status change'
    );
  }
}
