<div class="add-service-charge-con" [formGroup]="chargeForm">
  <app-page-header [title]="formTitle"></app-page-header>
  <div class="spacing-between-icon-and-title"></div>
  <div class="field-title">
    Charge Category
    <span class="required-symbol">*</span>
  </div>
  <ng-select
    *ngIf="!charge.id"
    appearance="outline"
    [placeholder]="'Select charge category'"
    [searchable]="false"
    [clearable]="false"
    class="drop-down charge-category"
    [class]="
      chargeForm.get('serviceId').touched && !chargeForm.get('serviceId').valid
        ? 'error'
        : ''
    "
    [readonly]="this.charge.id ? true : false"
    formControlName="serviceId"
    (change)="selectCategory($event)"
  >
    <ng-option [value]="category.id" *ngFor="let category of chargeCategories">
      <div class="drop-down-element">{{ category.name }} Charges</div>
    </ng-option>
  </ng-select>

  <input
    *ngIf="charge.id"
    type="text"
    [value]="charge.serviceName"
    class="charge-category-input"
    disabled
  />

  <div class="field-title">
    Charge Name
    <span class="required-symbol">*</span>
  </div>
  <ng-select
    appearance="outline"
    [placeholder]="'Select charge name'"
    [searchable]="false"
    [clearable]="false"
    class="drop-down doc-type"
    [class]="
      chargeForm.get('chargeTypeId').touched &&
      !chargeForm.get('chargeTypeId').valid
        ? 'error'
        : ''
    "
    formControlName="chargeTypeId"
  >
    <ng-option [value]="name.id" *ngFor="let name of filteredChargeTypes">
      <div class="drop-down-element">
        {{ name.name }}
      </div>
    </ng-option>
  </ng-select>

  <input
    *ngIf="showCustomName"
    type="text"
    class="custom-charge-name input-filed-placeholder"
    placeholder="custom charge name"
    [class]="
      chargeForm.get('chargeTypeName').touched &&
      showCustomName &&
      !chargeForm.get('chargeTypeName').valid
        ? 'error'
        : ''
    "
    formControlName="chargeTypeName"
  />

  <mat-radio-group
    formControlName="hasPercentageRate"
    [(ngModel)]="charge.hasPercentageRate"
    class="rate-quantity-con"
  >
    <div
      [ngClass]="{ disabled: charge.hasPercentageRate }"
      class="rate-quantity-inner-con rate-con"
    >
      <div class="field-title">
        Rate
        <span class="required-symbol">*</span>
      </div>

      <div class="rate-input-field">
        <mat-radio-button
          [value]="false"
          [checked]="!charge.hasPercentageRate"
          class="rate-type"
        ></mat-radio-button>
        <input
          type="number"
          class="rate-val  input-filed-placeholder"
          placeholder="1.0"
          [class]="
            chargeForm.get('rate').touched && chargeForm.get('rate').errors
              ? 'error'
              : ''
          "
          min="1"
          formControlName="rate"
        />
        <ng-select
          appearance="outline"
          [placeholder]="'USD'"
          [searchable]="false"
          [clearable]="false"
          class="currency-drop-down"
          [class]="
            chargeForm.get('currency').touched &&
            chargeForm.get('currency').errors
              ? 'error'
              : ''
          "
          formControlName="currency"
        >
          <ng-option
            [value]="currency.name"
            *ngFor="let currency of currencies"
          >
            <div class="drop-down-element">
              {{ currency.code }}
            </div>
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div
      [ngClass]="{ disabled: charge.hasPercentageRate }"
      class="rate-quantity-inner-con quantity-con"
    >
      <div class="field-title">
        Quantity
        <span class="required-symbol">*</span>
      </div>
      <app-quantity-input
        [isGreyBorder]="true"
        input
        [class]="
          chargeForm.get('quantity').touched &&
          chargeForm.get('quantity').errors
            ? 'error-app-qunatity-input'
            : 'charge-quantity-input'
        "
        [(ngModel)]="charge.quantity"
        formControlName="quantity"
      ></app-quantity-input>
    </div>

    <div
      [ngClass]="{ disabled: !charge.hasPercentageRate }"
      class="percentage-rate-inner-con rate-con"
    >
      <div class="percentage-rate-input-field">
        <mat-radio-button
          [value]="true"
          [checked]="charge.hasPercentageRate"
          class="rate-type"
        ></mat-radio-button>
        <input
          type="number"
          class="percentage-rate-val  input-filed-placeholder"
          placeholder="0.0"
          [class]="
            chargeForm.get('percentageRate').touched &&
            chargeForm.get('percentageRate').errors
              ? 'error'
              : ''
          "
          [title]="
            chargeForm.get('percentageRate').touched &&
            !chargeForm.get('percentageRate').valid
              ? 'Please enter a value from 0S-100%'
              : ''
          "
          formControlName="percentageRate"
        />
        <ng-select
          appearance="outline"
          [placeholder]="'% of'"
          [searchable]="false"
          [clearable]="false"
          class="of-label"
        >
        </ng-select>
      </div>
    </div>
    <div
      [ngClass]="{ disabled: !charge.hasPercentageRate }"
      class="percentage-rate-inner-con quantity-con"
    >
      <ng-select
        appearance="outline"
        [placeholder]="'Select charge name'"
        [searchable]="false"
        [clearable]="false"
        class="charge-val"
        [class]="
          chargeForm.get('parentChargeId').touched &&
          chargeForm.get('parentChargeId').errors
            ? 'error'
            : ''
        "
        formControlName="parentChargeId"
      >
        <ng-option [value]="charge.id" *ngFor="let charge of charges">
          <div class="drop-down-element">
            {{ charge.chargeTypeName }}
          </div>
        </ng-option>
        <ng-option [value]="TOTALSHIPMENTVALUE">
          <div class="drop-down-element">
            {{ TOTALSHIPMENTVALUE }}
          </div>
        </ng-option>
      </ng-select>
    </div>
  </mat-radio-group>

  <div class="form-buttons">
    <button class="form-btn cancel" (click)="closeDialog()">
      Cancel
    </button>
    <button
      (click)="addEditCharge()"
      [disabled]="isSaving"
      class="form-btn add-charge primary-button"
    >
      {{ charge.id ? "Apply Changes" : "Add Charge" }}
    </button>
  </div>
</div>
