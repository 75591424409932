import { Component, Input, OnInit } from '@angular/core';
import { DdlTable } from '../../models/ddl-table';
import { FormGroup } from '@angular/forms';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { DdlTableWithId } from '../../models/ddl-table-with-id';

@Component({
  selector: 'app-ddl-table',
  templateUrl: './ddl-table.component.html',
  styleUrls: ['./ddl-table.component.scss']
})
export class DdlTableComponent implements OnInit {
  @Input() ddlTables: DdlTable[];
  @Input() ddlTableWithIds: DdlTableWithId[];
  @Input() form: FormGroup;
  @Input() formControlNames: string[];

  constructor(private formsHelperService: FormsHelperService) { }

  ngOnInit() { }

  getChildren(ddlTable: DdlTable | DdlTableWithId) {
    return ddlTable?.children;
  }

  selectAll(index: number, checked: boolean): void {
    const children = this.isddlTableWithIds
      ? this.ddlTableWithIds[index].children.map(child => child.id)
      : this.ddlTables[index].children;
    checked
      ? this.form?.get(this.formControlNames[index])?.setValue(children)
      : this.form?.get(this.formControlNames[index]).setValue([]);
  }

  isAllSelected(index: number): boolean {
    const children = this.isddlTableWithIds
      ? this.ddlTableWithIds[index].children
      : this.ddlTables[index].children;
    return (
      this.form?.get(this.formControlNames[index])?.value?.length ===
      children?.length
    ) || this.isDashMark(index);
  }

  isInvalidFormControl(formControlName: string, index: number): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.form[index],
      formControlName
    );
  }

  labels(items: any): string {
    return items.map(item => (item.name ? item.name : item)).join(', ');
  }

  isDashMark(index: number): boolean {
    const children = this.isddlTableWithIds
      ? this.ddlTableWithIds[index].children
      : this.ddlTables[index].children;
    return (
      this.form?.get(this.formControlNames[index])?.value?.length !=
      children?.length && this.form?.get(this.formControlNames[index])?.value?.length > 0
    );
  }
  get isddlTableWithIds(): boolean {
    return this.ddlTableWithIds?.length > 0;
  }
}
