<div
  class="company-details-tabs h-100"
  [ngClass]="isCustomerModule ? 'customer-company-details-tabs' : ''"
>
  <div class="tabs-wrapper d-flex flex-column">
    <a
      *ngIf="isCustomerModule"
      class="lnk-back-to-customers d-flex"
      routerLink="/customers-profile"
    >
      <svg-icon
        name="black-arrow-left"
        class="back-arrow-icon d-flex align-items-center"
      ></svg-icon
      >All Customers
    </a>
    <div class="d-flex align-items-center">
      <div class="logo-container overflow-hidden" *ngIf="company?.logo">
        <img class="company-logo" [src]="company?.logo" />
      </div>
      <h1 class="company-title m-0">
        {{ company?.name }}
      </h1>
    </div>

    <div class="tabs cursor-pointer" *ngFor="let tab of companyTabsWithIcons">
      <div class="row mx-0" (click)="expandSubmenu(tab)" *ngIf="tab?.canView">
        <svg-icon [name]="tab.icon" class="icon"></svg-icon>
        <div
          class="pl-1 tab"
          [ngClass]="isSelectedTab(tab.name) ? 'selected-tab' : ''"
          (click)="navigateToPage(tab.name)"
        >
          {{ tab.name }}
        </div>
        <svg-icon
          class="expand-icon"
          *ngIf="tab?.subTabs?.length > 0"
          name="black-arrow-down"
        ></svg-icon>
      </div>

      <div class="sub-menu" *ngIf="tab.isExpanded">
        <div class="sub-menu-item" *ngFor="let subTab of tab?.subTabs">
          <span class="black-line"></span>
          <div
            class="sub-menu-title"
            [class]="!subTab.isActive ? 'disabled' : ''"
          >
            <div
              [routerLink]="[
                getSubTabUrl(subTab?.id, subTab.name, subTab.type)
              ]"
              [queryParams]="{ serviceOrder: subTab.serviceId }"
              [ngClass]="{
                'selected-sub-tab': isSelectedSubTab(subTab.name)
              }"
            >
              {{ subTab.name }}
            </div>
            <svg-icon
              *ngIf="!subTab.isActive"
              class="align-text-bottom"
              name="small-alert-Mark"
              [ngbPopover]="subTab.errorMessage"
              placement="right"
              triggers="mouseenter:mouseleave"
            ></svg-icon>

            <div *ngIf="subTab.name == 'Bundle'" class="m-2">
              <div
                *ngFor="let bundleService of subTab?.bundleServices"
                class="m-1 selected-sub-tab"
                [routerLink]="[
                  getSubTabUrl(
                    bundleService?.id,
                    bundleService.name,
                    bundleService.type
                  )
                ]"
                [queryParams]="{ serviceType: 'bundle' }"
                [ngClass]="{
                  'selected-sub-tab': isSelectedSubTab(bundleService.name)
                }"
              >
                {{ bundleService.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
