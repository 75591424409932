<app-page-header
  [title]="'config.management.Currencies.Manage' | translate"
></app-page-header>
<div class=" justify-content-center  pb-4">
  <app-currency-matrix [currencies]="currencyRows"> </app-currency-matrix>
</div>

<app-page-action-header
  placeholder="{{ 'config.management.Currencies.Search' | translate }}"
  addLabel="{{ 'config.management.Currencies.New' | translate }}"
  (onAddClick)="addOrEditCurrency()"
  [canAdd]="canCreateCurrency"
  (searchChanged)="onSearchCurrency($event)"
></app-page-action-header>

<ngx-datatable
  class="material colored-header sm table table-striped table-hover mt-5"
  [loadingIndicator]="loadingIndicator"
  [rows]="currencyRows"
  [rowHeight]="35"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
>
</ngx-datatable>

<ng-template #indexTemplate let-value="value">
  <strong>{{ value }}</strong>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
  <div>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      (click)="addOrEditCurrency(row)"
      *ngIf="canUpdateCurrency"
    ></svg-icon>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="delete-circle"
      (click)="deleteCurrency(row)"
      *ngIf="canDeleteCurrecny"
    ></svg-icon>
  </div>
</ng-template>
