import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ShipmentComment } from 'src/app/shipments/models/shipment-comment-model';
import { ShipmentsService } from 'src/app/shipments/services/shipments.service';
import { AuthService } from '../../services/auth.service';
import { LocalStoreManager } from '../../services/local-store-manager.service';
import { ProductRegistrationCommentWithActionActors } from 'src/app/product-registration/models/product-registration-comment-with-action-actors.model';
import { ProductRegistrationCommentService } from 'src/app/product-registration/services/product-registration-comment.service';

@Component({
  selector: 'app-comments-pending-action',
  templateUrl: './comments-pending-action.component.html',
  styleUrls: ['./comments-pending-action.component.scss']
})
export class CommentsPendingActionComponent implements OnInit {
  @Input() isInDashboard?: boolean = false;
  @Input() commentsPendingActions?: ShipmentComment[];
  @Input()
  productRegistrationComments?: ProductRegistrationCommentWithActionActors[];

  @Input() progressName?: string;
  @Input() relatedEntityId: string;

  constructor(
    private authService: AuthService,
    private shipmentsService: ShipmentsService,
    private router: Router,
    private localStorage: LocalStoreManager,
    private productCommentService: ProductRegistrationCommentService
  ) {}

  ngOnInit(): void {}

  get isProductComments(): boolean {
    return this.productRegistrationComments ? true : false;
  }
  markCommentAsFulfilled(action: any) {
    if (this.isProductComments) {
      this.productCommentService
        .fulFillPendingActionComment(this.relatedEntityId, action?.id)
        .subscribe(response => {
          this.productCommentService.onCommentsUpdate.emit(response);
        });
    } else {
      this.shipmentsService
        .markCommentPendingActionAsFulfilled(this.relatedEntityId, action?.id)
        .subscribe(res => {
          this.shipmentsService.onCommentsUpdate.emit(res);
        });
    }
  }

  get commentsForCurrentProgress(): any[] {
    const comments = this.isProductComments
      ? this.filterProductComments()
      : this.filterShipmentComments();

    return comments || [];
  }

  private filterProductComments(): ProductRegistrationCommentWithActionActors[] {
    return (
      this.productRegistrationComments?.filter(
        c =>
          c.progressName === this.progressName &&
          (c.isPendingAction || c.isFulFill)
      ) || []
    );
  }

  private filterShipmentComments(): ShipmentComment[] {
    if (this.progressName) {
      return this.commentsPendingActions?.filter(
        c => c.progressName === this.progressName && c.isPendingAction != null
      );
    } else if (this.isInDashboard) {
      return this.commentsPendingActions?.filter(c => c.isPendingAction);
    }

    return this.commentsPendingActions?.filter(c => c.isPendingAction != null);
  }

  onViewOnChat(commentId: number) {
    let companyId = this.router.url.split('/')[2];
    if (this.isInDashboard && companyId) {
      this.localStorage.saveSyncedSessionData(commentId, 'commentId');
      this.router.navigate([
        `company/${companyId}/shipments/${this.relatedEntityId}/details`
      ]);
    }

    if (this.productRegistrationComments) {
      this.productCommentService.onViewInChat.emit(commentId.toString());
    } else {
      this.shipmentsService.onViewInChat.emit(commentId.toString());
    }
  }

  get canSendPendingActionToShipment(): boolean {
    return (
      this.authService.canSendPendingActionToShipment && !this.isProductComments
    );
  }
}
