<app-page-header
  [title]="'config.management.HSCodes.Manage' | translate"
></app-page-header>

<app-page-action-header
  placeholder="{{ 'config.management.HSCodes.Search' | translate }}"
  addLabel="{{ 'config.management.HSCodes.New' | translate }}"
  (onAddClick)="addOrEditHSCode()"
  [canAdd]="true"
  (searchChanged)="onSearchHSCode($event)"
></app-page-action-header>

<ngx-datatable
  class="material colored-header sm table table-striped table-hover mt-5"
  [loadingIndicator]="loadingIndicator"
  [rows]="hsCodeRows"
  [rowHeight]="35"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
>
</ngx-datatable>

<ng-template #indexTemplate let-value="value">
  <strong>{{ value }}</strong>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
  <div>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      (click)="addOrEditHSCode(row)"
    ></svg-icon>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="delete-circle"
      (click)="deleteHSCode(row)"
    ></svg-icon>
  </div>
</ng-template>
