import { ServiceTypes } from '../../shared/models/shipment/service-types.enum';

export const servicesOrder = [
  ServiceTypes.Freight,
  ServiceTypes.IOR,
  ServiceTypes.EOR,
  ServiceTypes.CustomsClearance,
  ServiceTypes.Trucking,
  ServiceTypes.AccountManagement,
  'E2E Handling',
  'Additional'
];
