<div>
  <app-page-header [title]="'Registration KPIs'"></app-page-header>

  <div class="mb-4">
    <app-page-action-header
      placeholder="{{ 'config.management.ProductLeadTime.Search' | translate }}"
      addLabel="{{ 'config.management.ShipmentLeadTime.New' | translate }}"
      addUrl="./add"
      [canAdd]="canCreateProductRegistrationKPI"
      (searchChanged)="onSearchChanged($event)"
    >
    </app-page-action-header>
  </div>

  <ngx-datatable
    class="material colored-header sm table table-striped table-hover"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="35"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
  </ngx-datatable>
  <ng-template #leadTimeNameTemplate let-row="row" let-value="value">
    <div class="d-flex">
      <div class="name">{{ row?.name }}</div>
      <div
        [ngbPopover]="leadTimeInfo"
        #leadTimeInfoPopover="ngbPopover"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="togglePopover(leadTimeInfoPopover)"
        (mouseleave)="closePopover(leadTimeInfoPopover)"
      >
        <svg-icon id="pop-over-icon" name="info"></svg-icon>

        <ng-template #leadTimeInfo>
          <div class="trigger">Start Event</div>
          <div class="trigger-name">
            {{ row?.startTriggerName }}
          </div>
          <div class="trigger">End Event</div>
          <div class="trigger-name pb-0">
            {{ row?.endTriggerName }}
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
    <button
      class="btn btn-link btn-sm"
      [routerLink]="['./' + row.id + '/edit']"
      *ngIf="canUpdateProductRegistrationKPI"
    >
      <svg-icon name="edit-circle"></svg-icon>
    </button>
    <button
      class="btn btn-link btn-sm"
      (click)="deleteProductLeadTime(row)"
      *ngIf="canDeleteProductRegistrationKPI"
    >
      <svg-icon name="delete-circle"></svg-icon>
    </button>
  </ng-template>
</div>
