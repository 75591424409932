<div class="row p-3">
  <app-page-header
    class="col-sm-8 col-md-6 col-lg-8 col-xl-9 pl-0"
    [title]="
      (isNewDocumentType
        ? 'config.management.DocumentTypes.New'
        : 'config.management.DocumentTypes.Edit'
      ) | translate
    "
  ></app-page-header>

  <span class="col-sm-4 col-md-6 col-lg-4 col-xl-3 pl-0 mandatory-field">
    (<span class="required-text ">*</span>) means that this field is mandatory
  </span>

  <div class="mb-3 col-12">
    <app-error-badge
      *ngIf="hasErrorMessage"
      [message]="errorMessage"
    ></app-error-badge>
  </div>

  <div class="col-lg-7 col-md-10 col-sm-10 Card pb-1 pl-0">
    <form
      [formGroup]="documentTypeForm"
      id="documentTypeForm"
      (ngSubmit)="save()"
    >
      <fieldset>
        <div class="form-group">
          <label for="name" class="col-form-label">
            {{ "config.management.Name" | translate
            }}<span class="required-text">*</span>
          </label>
          <input
            formControlName="name"
            [ngClass]="{
              error: isInvalidFormControl('name')
            }"
            type="text"
            class="form-control"
            name="txt-name"
            placeholder="{{
              'config.management.DocumentTypes.NamePlaceHolder' | translate
            }}"
          />
        </div>

        <div class="form-group">
          <label for="Description" class="col-form-label">
            {{ "config.management.DocumentTypes.Description" | translate }}
            <span class="required-text">*</span>
          </label>
          <textarea
            #descriptionTextarea
            [ngClass]="{
              error: isInvalidFormControl('description'),
              'auto-expand': true
            }"
            class="form-control"
            name="txt-description"
            maxLength="477"
            formControlName="description"
            placeholder="{{
              'config.management.DocumentTypes.DescriptionPlaceHolder'
                | translate
            }}"
            (input)="autoExpand($event)"
            rows="1"
          ></textarea>
        </div>

        <div class="form-group field-group row">
          <div class="col-6">
            <label for="parentType" class="col-form-label">
              {{ "config.management.parentCategory" | translate
              }}<span class="required-text">*</span>
            </label>
            <ng-select
              formControlName="parentType"
              [ngClass]="{
                error: isInvalidFormControl('parentType')
              }"
              (change)="changeParentType($event)"
              class="ddl-height"
              name="ddl-parentType"
              [items]="parentTypes"
              [bindLabel]="'name'"
              [bindValue]="'value'"
              [searchable]="false"
              [clearable]="false"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-item$="item$"
              >
                <div class="d-flex align-items-center">
                  <mat-radio-button [value]="item" [checked]="item$.selected">
                  </mat-radio-button>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item.name }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="col-6" *ngIf="isProduct">
            <label for="category" class="col-form-label">
              {{ "config.management.DocumentTypes.ProductType" | translate
              }}<span class="required-text">*</span>
            </label>
            <ng-select
              formControlName="category"
              [ngClass]="{
                error: isInvalidFormControl('category')
              }"
              class="ddl-height"
              name="ddl-category"
              [items]="categoryTypes"
              [searchable]="false"
              [clearable]="false"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-item$="item$"
              >
                <div class="d-flex align-items-center">
                  <mat-radio-button [value]="item" [checked]="item$.selected">
                  </mat-radio-button>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="col-6" *ngIf="isShipment">
            <label for="freightType" class="col-form-label">
              {{ "config.management.DocumentTypes.FreightType" | translate
              }}<span class="required-text">*</span>
            </label>
            <ng-select
              [ngClass]="{
                error: isInvalidFormControl('freightType')
              }"
              class=""
              id="ddl-freightType"
              formControlName="freightType"
              [items]="freightTypes"
              [searchable]="false"
              [clearable]="false"
              class="ddl-height"
              [multiple]="true"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                {{ items.join(", ") }}
              </ng-template>

              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <div class="d-flex align-items-center">
                  <app-checkbox
                    id="item-{{ index }}"
                    [checked]="item$.selected"
                  ></app-checkbox>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="col-6" *ngIf="isCompany">
            <label for="licenseTypes" class="col-form-label">
              {{
                "config.management.DocumentTypes.CompanyLicenseType"
                  | translate
              }}<span class="required-text">*</span>
            </label>
            <ng-select
              [ngClass]="{
                error: isInvalidFormControl('licenseTypes')
              }"
              [items]="licenseTypes"
              [searchable]="false"
              [clearable]="false"
              [multiple]="true"
              class="ddl-height"
              id="ddl-licenseTypes"
              formControlName="licenseTypes"
            >
              <ng-template
                ng-multi-label-tmp
                let-items="items"
                let-clear="clear"
              >
                {{ items.join(", ") }}
              </ng-template>

              <ng-template
                ng-option-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
                <div class="d-flex align-items-center">
                  <app-checkbox
                    id="item-{{ index }}"
                    [checked]="item$.selected"
                  ></app-checkbox>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="form-group field-group row" *ngIf="isCompany || isProduct">
          <div class="col-6">
            <label for="requirementType" class="col-form-label">
              {{ "config.management.DocumentTypes.RequirementType" | translate
              }}<span class="required-text">*</span>
            </label>
            <ng-select
              [ngClass]="{
                error: isInvalidFormControl('isRequired')
              }"
              class="ddl-height"
              name="txt-requirementType"
              [items]="documentTypeConditions"
              [bindLabel]="'name'"
              bindValue="value"
              [searchable]="false"
              [clearable]="false"
              formControlName="isRequired"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-item$="item$"
              >
                <div class="d-flex align-items-center">
                  <mat-radio-button [value]="item" [checked]="item$.selected">
                  </mat-radio-button>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item.name }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div class="col-6" *ngIf="isCompany || isProduct">
            <label for="expiringIn" class="col-form-label">
              {{
                "config.management.DocumentTypes.DocumentTypeExpires"
                  | translate
              }}
            </label>
            <div class="row ml-0 mr-0">
              <input
                class="form-control col-md-2 col-lg-2 col-xl-4 right-combined-field"
                formControlName="expiringIn"
                id="txt-documentTypeExpires"
              />
              <input
                class="form-control col-md-10 col-lg-10 col-xl-8 left-combined-field"
                id="txt-documentTypeExpires"
                value="Expiry Notification Days"
                disabled="true"
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-0 field-group row">
          <div class="col-6">
            <label for="maxFileSize" class="col-form-label">
              {{ "config.management.MaxFileSize" | translate
              }}<span class="required-text">*</span>
            </label>
            <div class="mb-3">
              <input
                formControlName="maxFileSize"
                [ngClass]="{
                  error: isInvalidFormControl('maxFileSize')
                }"
                type="number"
                name="txt-maxFileSize"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-6" *ngIf="isShipment || isProduct">
            <label for="pinToUploadWindow" class="col-form-label">
              {{
                "config.management.DocumentTypes.PinToUploadWindow" | translate
              }}
            </label>
            <ng-select
              class="ddl-height"
              name="ddl-pinToUploadWindow"
              [items]="booleanTypes"
              [bindLabel]="'name'"
              bindValue="value"
              [searchable]="false"
              [clearable]="false"
              formControlName="isPinned"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-item$="item$"
              >
                <div class="d-flex align-items-center">
                  <mat-radio-button [value]="item" [checked]="item$.selected">
                  </mat-radio-button>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item.name }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
  <div class="form-group ddl-table" *ngIf="isProductMedical">
    <label for="name" class="col-form-label">
      {{
        "config.management.DocumentTypes.TypeVsClassificationRules" | translate
      }}<span class="required-text">*</span>
    </label>
    <app-ddl-table
      [ddlTables]="ddlTables"
      [form]="documentTypeForm?.get('classificationData')"
      [formControlNames]="[
        'fscClassificationTypes',
        'cfgWithGmbClassificationTypes',
        'cfgWithoutGmbClassificationTypes'
      ]"
    >
    </app-ddl-table>
  </div>

  <div class="form-group ddl-table" *ngIf="isCompany">
    <label for="name" class="col-form-label">
      {{
        "config.management.DocumentTypes.TypeVsSub-IndustryRules" | translate
      }}
    </label>
    <app-ddl-table
      [ddlTableWithIds]="industryParentsWithChildren"
      [form]="documentTypeForm?.get('industry')"
      [formControlNames]="industryFormControlNames"
    >
    </app-ddl-table>
  </div>
</div>
<div class="col-6"></div>
<div class="col-8"></div>
<div class="btn-container">
  <button class="btn btn-cancel" (click)="cancel()">
    {{ "config.management.DocumentTypes.Editor.Cancel" | translate }}
  </button>
  <button
    class="btn btn-save"
    (click)="save()"
    [disabled]="isSaving || isFormDisabled"
  >
    {{
      isSaving
        ? ("config.management.DocumentTypes.Editor.Saving" | translate)
        : ("config.management.DocumentTypes.Editor.Save" | translate)
    }}
  </button>
</div>
