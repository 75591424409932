<div class="container-fluid">
  <div class="row mx-0">
    <app-page-header [title]="'Documents'"></app-page-header>

    <button
      type="submit"
      (click)="openUploadDocumentPopup()"
      class="btn upload-btn ml-auto"
    >
      <span class="btn-text">+ Upload New Document</span>
    </button>
  </div>

  <div
    class="uploaded-documents-cont"
    *ngIf="companyDocuments?.length > 0; else noDocumentsUploadedBlock"
  >
    <div class="uploaded-documents-list">
      <span class="document-type-col-title">Document Type</span>
      <span class="file-col-title">File</span>
      <span class="notes-col-title">Notes</span>
      <span class="actions-col-title">Actions</span>
    </div>
    <div *ngFor="let document of companyDocuments; let i = index">
      <app-uploaded-documents-details
        *ngIf="
          !isUploadingFile || document.documentTypeName != uploadedFileType
        "
        [document]="document"
        [typeId]="companyId"
        [type]="documentParentType.CompanyProfile"
        [module]="moduleName.CompanyProfile"
        [popOverPlacement]="
          i == companyDocuments?.length - 1 ? 'top-left' : 'bottom-left'
        "
        [canDownloadDocument]="true"
        [canReplaceDocument]="true"
        [canDeleteDocument]="true"
      ></app-uploaded-documents-details>

      <app-uploaded-documents-details-loader
        [documentType]="uploadedFileType"
        *ngIf="isUploadingFile && document.documentTypeName == uploadedFileType"
      >
      </app-uploaded-documents-details-loader>
    </div>
  </div>
  <ng-template #noDocumentsUploadedBlock>
    <app-add-empty-data-msg
      [boldText]="'There are no documents yet!'"
      [normalText]="'Documents will appear here once uploaded.'"
    ></app-add-empty-data-msg>
  </ng-template>
</div>
