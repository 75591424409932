<div class="container settings-page">
  <div [@fadeInOut]>
    <div class="d-sm-flex flex-row">
      <div class="tab-content w-100">
        <div id="users" *ngIf="canViewUsers && isUsersActivated">
          <div [@fadeInOut] class="content-container pl-lg-1">
            <app-users-management></app-users-management>
          </div>
        </div>
        <div id="roles" *ngIf="canViewRoles && isRolesActivated">
          <div [@fadeInOut] class="content-container pl-lg-1">
            <app-roles-management></app-roles-management>
          </div>
        </div>
        <div id="config" *ngIf="isConfigActivated">
          <h4>{{ "settings.header.QuickLinks" | translate }}</h4>
          <hr class="separator-hr" />
          <div [@fadeInOut] class="content-container pl-lg-1">
            <app-configs-list></app-configs-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
