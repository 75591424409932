<div class="container">
  <app-page-header [title]="'Users'"></app-page-header>

  <app-page-action-header
    placeholder=" {{ 'users.management.Search' | translate }}"
    addLabel="{{ 'users.management.NewUser' | translate }}"
    [canAdd]="false"
    (searchChanged)="onSearchChanged($event)"
  ></app-page-action-header>

  <app-add-empty-data-msg
    *ngIf="!rows?.length && !loadingIndicator"
    [boldText]="'users.management.UserEmpty' | translate"
    [normalText]="'users.management.UserWillAppear' | translate"
  ></app-add-empty-data-msg>

  <ngx-datatable
    *ngIf="rows?.length"
    class="material colored-header sm table table-striped table-hover mt-5"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="40"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
  </ngx-datatable>

  <ng-template #statusTemplate let-row="row" let-value="value" let-i="index">
    <b *ngIf="!value; else falseLabel" class=" text-success fw-bold">
      {{ "company.Active" | translate }}
    </b>
    <ng-template #falseLabel>
      <b class="text-danger fw-bold">
        {{ "company.Inactive" | translate }}
      </b>
    </ng-template>
  </ng-template>

  <ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
    <div>
      <svg-icon
        *ngIf="canUpdateUsers"
        class="action-btn cursor-pointer pr-2"
        name="edit-circle"
        (click)="editUser(row)"
      ></svg-icon>
      <svg-icon
        class="action-btn cursor-pointer pr-2"
        [name]="!row.isLockedOut ? 'circle-lock' : 'circle-unlock'"
      ></svg-icon>
      <svg-icon
        *ngIf="canDeleteUsers"
        class="action-btn cursor-pointer pr-2"
        name="delete-circle"
        (click)="deleteUser(row)"
      ></svg-icon>
    </div>
  </ng-template>
</div>

<div
  class="modal fade"
  bsModal
  #editorModal="bs-modal"
  (onHidden)="onEditorModalHidden()"
  [config]="{ backdrop: false }"
  tabindex="-1"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 *ngIf="!editingUserName" class="modal-title float-left">
          <i class="fa fa-user-plus"></i>
          {{ "users.management.NewUser" | translate }}
        </h4>
        <h4 *ngIf="editingUserName" class="modal-title float-left">
          <i class="fa fa-user-circle-o"></i>
          {{ "users.management.EditUser" | translate: editingUserName }}
        </h4>
        <button
          type="button"
          class="close float-right"
          title="Close"
          (click)="editorModal.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-user-info #userEditor [isGeneralEditor]="true"></app-user-info>
      </div>
    </div>
  </div>
</div>
