export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(
    id?: string,
    employeeId?: string,
    userName?: string,
    fullName?: string,
    email?: string,
    jobTitle?: string,
    companyName?: string,
    companyId?: string,
    customerCompanies?: string[],
    defaultCustomerCompany?: string,
    profilePictureUrl?: string,
    phoneNumber?: string,
    roles?: string[],
    industry?: string,
    defaultCustomerCompanyName?: string,
    tenant?: string

  ) {
    this.id = id;
    this.employeeId = employeeId;
    this.userName = userName;
    this.fullName = fullName;
    this.email = email;
    this.jobTitle = jobTitle;
    this.companyName = companyName;
    this.companyId = companyId;
    this.customerCompanies = customerCompanies;
    this.defaultCustomerCompany = defaultCustomerCompany;
    this.profilePictureUrl = profilePictureUrl;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.industry = industry;
    this.defaultCustomerCompanyName = defaultCustomerCompanyName;
    this.tenant = tenant
    
    if (customerCompanies && companyId) {
      this.customerCompanies.push(companyId);
    } else if (companyId) {
      this.customerCompanies = [companyId];
    }
  }

  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle) {
      name = this.jobTitle + ' ' + name;
    }

    return name;
  }

  public id: string;
  public employeeId: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public jobTitle: string;
  public companyName: string;
  public companyId: string;
  public customerCompanies: string[];
  public defaultCustomerCompany: string;
  public defaultCustomerCompanyName: string;
  public profilePictureUrl: string;
  public phoneNumber: string;
  public isEnabled: boolean;
  public isLockedOut: boolean;
  public roles: string[];
  public industry?: string;
  public tenant?: string;
}
