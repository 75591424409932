import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { UploadDocsComponent } from '../pages/upload-docs/upload-docs.component';
import { ShipmentsService } from '../../shipments/services/shipments.service';
import { UnSavedDataNotificationComponent } from '../../shipments/sub-components/un-saved-data-notification/un-saved-data-notification.component';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class PreventUnsavedChanges
  implements CanDeactivate<UploadDocsComponent> {
  constructor(
    private router: Router,
    private shipmentService: ShipmentsService,
    private dialogService: DialogService
  ) {}

  canDeactivate(component: any): boolean {
    if (component?.formDirty) {
      this.dialogService.open(
        UnSavedDataNotificationComponent,
        {
          message: component.unsavedDataConfirmationMessage
        },
        '600px',
        '675px'
      );

      this.shipmentService.confirmUnSavedData.subscribe(data => {
        this.dialogService.close();
        component.uploadForm.reset();
        this.router.navigate([component.redirectionURL], {
          relativeTo: component.activeRoute
        });
      });

      return false;
    } else {
      return true;
    }
  }
}
