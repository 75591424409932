import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadTimeLogService {
  baseURL = environment.apiUrl + 'LeadTimeLog';

  constructor(private http: HttpClient) {}

  updateLeadTimeNotes(leadTimeId: number, notes?: string): Observable<any> {
    return this.http.put(
      this.baseURL + `/${leadTimeId}/Notes?notes=${notes}`,
      null
    );
  }

  exportLeadTimeLogsToExcel(shipmentId: string): Observable<any> {
    return this.http.post(this.baseURL + `/ExportExcel/${shipmentId}`, null, {
      responseType: 'blob',
      observe: 'response'
    });
  }
}
