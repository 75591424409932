<div class="supplier-container">
  <div class="d-flex justify-content-between">
    <app-page-header
      [title]="'mainMenu.Suppliers' | translate"
    ></app-page-header>
    <button class="create-supplier" [routerLink]="['/suppliers/add']">
      <span class="plus">+</span>
      <span class="create-supplier-btn ">New Supplier</span>
    </button>
  </div>

  <div class="search-filter-margin">
    <app-page-action-header
      (searchChanged)="onSearchChanged($event)"
      [placeholder]="'Search for Supplier'"
    ></app-page-action-header>
  </div>
  <div class="mt-3">
    <ngx-datatable
      class="material colored-header sm table table-striped table-hover"
      [rows]="suppliersList"
      [rowHeight]="40"
      [headerHeight]="40"
      [footerHeight]="35"
      [columns]="columns"
      [scrollbarV]="true"
      [columnMode]="'force'"
      *ngIf="
        suppliersList?.length > 0 && !isLoading;
        else emptySupplierTemplate
      "
      (scroll)="onScroll($event)"
    >
    </ngx-datatable>

    <ng-template #nameTemplate let-value="value">
      <span class="supplier-name">{{ value }}</span>
    </ng-template>

    <ng-template #headquatersTemplate let-value="value">
      <span class="headquaters">{{ getHeadquarters(value) }}</span>
    </ng-template>

    <ng-template #statusTemplate let-value="value">
      <span
        class="status"
        [ngClass]="{
          'active-status': value === 'Active',
          'inactive-status': value === 'Inactive'
        }"
        >{{ value }}</span
      >
    </ng-template>

    <ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
      <button
        class="btn btn-link btn-sm"
        [routerLink]="['./' + row.id + '/details']"
      >
        <svg-icon name="view-supplier"></svg-icon>
      </button>
    </ng-template>

    <ng-template #emptySupplierTemplate>
      <app-add-empty-data-msg
        [boldText]="'There are no suppliers yet!'"
        [normalText]="'Suppliers will appear here once created.'"
      ></app-add-empty-data-msg>
    </ng-template>
  </div>
</div>
