<div class="undo-container">
  <svg-icon name="undo-status"></svg-icon>
  <div class="spacing-between-icon-and-title"></div>
  <app-page-header [title]="'Undo Status Change'"></app-page-header>
  <div class="spacing-between-icon-and-title"></div>
  <div class="undo-warning-paragraph">
    You are about to revert this shipment to its previous status.
  </div>
  <div class="undo-note-title">
    Why would you like to undo the last status change?
    <span class="required-symbol">*</span>
  </div>
  <textarea
    [(ngModel)]="reason"
    type="text"
    placeholder="Add note"
    class="undo-reason"
    [class]="reasonError ? 'error' : ''"
    maxlength="180"
  >
  </textarea>

  <div class="form-buttons row">
    <div class="col-6">
      <button class="keep removal-form-buttons" (click)="cancel()">
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        class="undo removal-form-buttons"
        (click)="undo()"
        [disabled]="isLoading"
      >
        Undo

        <svg-icon *ngIf="!isLoading" name="undo"></svg-icon>
        <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
      </button>
      <div class="confirmation-warning">
        <svg-icon [name]="'mark!'"></svg-icon>
        <div class="confirmation-warning-text">
          This action cannot be undone.
        </div>
      </div>
    </div>
  </div>
</div>
