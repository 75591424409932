import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApprovalKPIsAddEditGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const isEditRoute = route.url.some(segment =>
      segment.path.includes('edit')
    );
    if (
      (!this.canUpdateApprovalKPIs && isEditRoute) ||
      (!this.canCreateApprovalKPIs && !isEditRoute)
    ) {
      return this.router.createUrlTree(['settings'], { fragment: 'config' });
    }
    return true;
  }

  private get canUpdateApprovalKPIs(): boolean {
    return this.authService.canUpdateApprovalKPIs;
  }

  private get canCreateApprovalKPIs(): boolean {
    return this.authService.canCreateApprovalKPIs;
  }
}
