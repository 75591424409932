import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ActivityLog } from 'src/app/shared/models/shipment/activity-log.model';
import { ShipmentDetails } from '../../models/shipment-details.model';
import { ShipmentHelpersService } from '../../services/shipment-helpers.service';
import { ShipmentsService } from '../../services/shipments.service';
import { ShipmentActivityLog } from '../../models/shipment-activity-log.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activty-log-pdf',
  templateUrl: './activty-log-pdf.component.html',
  styleUrls: ['./activty-log-pdf.component.scss']
})
export class ActivtyLogPdfComponent implements OnInit {
  activityLogs: ShipmentActivityLog[];
  shipmentNumber: string;
  shipmentDetails: ShipmentDetails;
  logoUrl = 'assets/images/PNG/FreePL_Logo.png';

  constructor(
    private authService: AuthService,
    private shipmentService: ShipmentsService,
    private route: ActivatedRoute,
    private shipmentHelpersService: ShipmentHelpersService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    if (this.authService.tenantLogo) {
      this.logoUrl = this.authService.tenantLogo;
    }

    this.shipmentService
      .getShipmentDetails(
        this.route.snapshot.queryParams.shipmentId,
        this.route.snapshot.queryParams.companyId
      )
      .subscribe(details => {
        this.shipmentDetails = details;
        this.shipmentNumber = details?.shipment?.freePlShipmentNumberString;
        this.activityLogs = details?.shipmentActivityLogs;
        this.titleService.setTitle(`${this.shipmentNumber} - Activity Log`);
        setTimeout(() => {
          window.print();
          window.close();
        }, 500);
      });
  }

  get lastActivityLog(): ActivityLog {
    return this.shipmentHelpersService.getShipmentLastActivityLog(
      this.shipmentDetails?.shipmentActivityLogs
    );
  }

  get oceanContainersNumbers(): string[] {
    return this.shipmentHelpersService.getOceanContainersNumbers(
      this.shipmentDetails?.shipment
    );
  }
}
