import { Injectable } from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { IndustryService } from '../services/industry.service';
import { catchError } from 'rxjs/operators';
import { DdlTableWithId } from '../models/ddl-table-with-id';

@Injectable({
  providedIn: 'root'
})
export class IndustryParentsWithChildrenResolver implements Resolve<DdlTableWithId[]> {

  constructor(
    private industryService: IndustryService
  ) { }


  resolve(): Observable<DdlTableWithId[]> {
    return this.industryService.getParentsWithChildren().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
