import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShipmentsService } from '../../shipments/services/shipments.service';
import { ShipmentServices } from '../../shared/models/shipment/shipmentServices.model';

@Injectable()
export class ShipmentServicesResolver implements Resolve<ShipmentServices> {
  constructor(private shipmentService: ShipmentsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ShipmentServices> {
    var shipmentId = route.params.shipmentId;
    if (shipmentId) {
      return this.shipmentService.getShipmentServices(shipmentId).pipe(
        catchError(error => {
          return of(null);
        })
      );
    } else {
      return null;
    }
  }
}
