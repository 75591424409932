import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ActivityTransition } from '../../../models/activity-transition.model';
import { ShipmentLeadTimeResponse } from '../../../models/shipment-lead-time-response.model';
import { ActivityService } from '../../../services/activity.service';
import { ShipmentLeadTimeService } from '../../../services/shipment-lead-time.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ActivityCategory } from 'src/app/shipments/enums/activity-category.enum';

@Component({
  selector: 'app-add-or-edit-shipment-lead-time',
  templateUrl: './add-or-edit-shipment-lead-time.component.html',
  styleUrls: ['./add-or-edit-shipment-lead-time.component.scss']
})
export class AddOrEditShipmentLeadTimeComponent implements OnInit {
  shipmentLeadTimeForm: FormGroup;
  shipmentLeadTime: ShipmentLeadTimeResponse;
  isduplicateShipmentLeadTime: boolean = false;
  isNewShipmentLeadTime: boolean = false;
  activities: ActivityTransition[];
  endTriggers: ActivityTransition[] = [];
  daysHours: { label: string, value: boolean }[] = [
    { label: 'Days', value: false },
    { label: 'Hours', value: true }
  ];
  kpiCategories: ActivityCategory[];

  isSaving: boolean = false;
  hasErrorMessage = false;
  errorMessage: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private shipmentLeadTimeService: ShipmentLeadTimeService,
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private formsHelperService: FormsHelperService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.shipmentLeadTime = data['shipmentLeadTime'];
      this.kpiCategories = data['parentCategories'];
    });

    this.loadActivities();
    this.initForm();
  }

  loadActivities(): void {
    this.activityService.getAll(true).subscribe(res => {
      this.activities = res;
      if (this.shipmentLeadTime != null) {
        this.onChangingStartTrigger(null, this.shipmentLeadTime.startTriggerId);
      }
    });
  }

  initForm(): void {
    this.isNewShipmentLeadTime = this.shipmentLeadTime == null;
    this.shipmentLeadTime =
      this.shipmentLeadTime || ({} as ShipmentLeadTimeResponse);

    this.shipmentLeadTimeForm = this.fb.group({
      name: [this.shipmentLeadTime.name, [Validators.required]],
      leadTime: [this.shipmentLeadTime.leadTime, [Validators.required]],
      startTriggerId: [
        this.shipmentLeadTime.startTriggerId,
        [Validators.required]
      ],
      endTriggerId: [this.shipmentLeadTime.endTriggerId, [Validators.required]],
      kpiCategoryId: [this.shipmentLeadTime.kpiCategoryId, [Validators.required]],
      daysHours: [this.shipmentLeadTime?.isHours ? true : false, Validators.required]
    });
  }

  cancel(): void {
    this.router.navigate(['/settings/shipment-kpis']);
  }

  save(): void {
    this.isSaving = true;
    const formVal = this.shipmentLeadTimeForm.value;
    this.assignIsHoursValue(formVal);

    if (!this.validateForm(formVal)) {
      this.isSaving = false;
      this.alertService.error('Invalid Form!');
      return;
    }

    if (this.isNewShipmentLeadTime) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  assignIsHoursValue(formVal): void {
    formVal.isHours = formVal.daysHours;
  }

  addNewRequest(formVal: any): void {
    this.shipmentLeadTimeService.addShipmentLeadTime(formVal).subscribe(
      {
        next: () => {
          this.isSaving = false;
          this.router.navigate(['/settings/shipment-kpis']);
          this.alertService.success('Shipment lead time was Saved Successfully');
        },
        error: err => {
          this.isSaving = false;
          this.hasErrorMessage = true;
          this.errorMessage =
            err || 'An error occurred while Saving the Shipment Lead.';
        }
      });
  }

  updateRequest(formVal: any): void {
    this.shipmentLeadTimeService
      .updateShipmentLeadTime(this.shipmentLeadTime.id, formVal)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.router.navigate(['/settings/shipment-kpis']);
          this.alertService.success('shipment lead time was updated successfully');
        },
        error: err => {
          this.isSaving = false;
          this.hasErrorMessage = true;
          this.errorMessage =
            err || 'An error occurred while saving the shipment lead.';
        }
      }
      );
  }

  onChangingStartTrigger(event?: any, shipmentLeadTimeId?: number): void {
    if (shipmentLeadTimeId) {
      this.endTriggers = this.activities.filter(
        a => a.id != shipmentLeadTimeId
      );
    } else if (event) {
      this.endTriggers = this.activities.filter(
        a => a.id != event?.id
      );
      this.shipmentLeadTimeForm.controls['endTriggerId'].setValue(null)
    }
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.shipmentLeadTimeForm,
      formControlName
    );
  }

  get isFormDisabled(): boolean {
    return this.shipmentLeadTimeForm?.invalid;
  }

  private validateForm(formVal: any): boolean {
    const validTriggers =
      formVal.startTriggerId != 'null' && formVal.endTriggerId != 'null';
    return validTriggers && this.shipmentLeadTimeForm.valid;
  }

}
