import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Charge } from 'src/app/shared/models/charge.model';
import { ServiceTypes } from 'src/app/shared/models/shipment/service-types.enum';
import { AuthService } from 'src/app/shared/services/auth.service';
import { servicesOrder } from '../../constants/service-order';
import { AddServiceChargeComponent } from '../add-service-charge/add-service-charge.component';
import { DeleteShipmentChargeComponent } from '../delete-shipment-charge/delete-shipment-charge.component';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ShipmentsService } from '../../services/shipments.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-shipment-charges',
  templateUrl: './shipment-charges.component.html',
  styleUrls: ['./shipment-charges.component.scss']
})
export class ShipmentChargesComponent implements OnInit, OnChanges {
  @Input() companyId: string;
  @Input() shipmentId: string;
  @Input() charges: Charge[];
  @Input() totalShipmentValue: number;
  @Input() currency: string;
  @Input() status: string;

  servicesOrder: string[] = servicesOrder;
  existingServices: string[];
  existingChargesTables: ExistingChargesTables[] = [];
  serviceType = ServiceTypes;

  constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private shipmentsService: ShipmentsService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.charges) {
      this.charges = this.charges.filter(c => c.amount && c.currencyCode);
      this.getExistingServices();
      this.populateChargesTables();
    }
  }

  getExistingServices(): void {
    this.existingServices = [];

    for (let charge of this.charges) {
      if (!this.existingServices?.find(s => s === charge.serviceName)) {
        this.existingServices.push(charge.serviceName);
      }
    }
    this.existingServices = this.orderServices(this.existingServices);
  }

  orderServices(servicesUnordered: string[]): string[] {
    var orderedServices = [];

    for (let index = 0; index < this.servicesOrder.length; index++) {
      let service = servicesUnordered.find(
        s => s === this.servicesOrder[index]
      );
      if (service) {
        orderedServices.push(service);
      }
    }

    return orderedServices;
  }

  showModal(): void {
    this.dialogService.open(
      AddServiceChargeComponent,
      {
        shipmentId: this.shipmentId,
        charges: this.charges
      },
      '600px',
      '675px'
    );
  }

  showEditChargePopUp(editedCharge: Charge): void {
    this.dialogService.open(
      AddServiceChargeComponent,
      {
        shipmentId: this.shipmentId,
        charges: this.charges,
        charge: editedCharge
      },
      '600px',
      '675px'
    );
  }

  showDeleteChargePopUp(deletedCharge: Charge): void {
    this.dialogService.open(
      DeleteShipmentChargeComponent,
      {
        shipmentId: this.shipmentId,
        chargeId: deletedCharge.id
      },
      '540px',
      '675px'
    );
  }

  populateChargesTables(): void {
    this.existingChargesTables = [];

    this.existingServices.forEach(service => {
      this.existingChargesTables.push({
        serviceName: service,
        charges: this.getChargesByServiceName(service)
      });
    });
  }

  getChargesByServiceName(serviceName: string): Charge[] {
    return this.charges.filter(c => c.serviceName === serviceName);
  }

  exportChargesToExcel(): void {
    this.shipmentsService
      .exportShipmentChargesToExcel(this.shipmentId)
      .subscribe(res => {
        const fileName = res.headers.get('filename');

        const blob = new Blob([res.body], {
          type: res.headers.get('content-type')
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);

        this.alertService.success('Shipment charges successfully exported.');
      });
  }

  get canAddShipmentCharge(): boolean {
    return this.authService.canAddShipmentCharge;
  }

  get canEditShipmentCharge(): boolean {
    return this.authService.canEditShipmentCharge;
  }

  get canDeleteShipmentCharge(): boolean {
    return this.authService.canDeleteShipmentCharge;
  }
}

export interface ExistingChargesTables {
  serviceName: string;
  charges: Charge[];
}
