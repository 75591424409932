<div>
  <div class="d-flex justify-content-end align-items-center gap-1 mb-4 ">
    <img [src]="logoUrl" class="logo-navy" alt="logo-navy" />
  </div>
  <div class="d-flex align-items-center gap-1 mb-4 ">
    <p class="mb-0 shipment-number">{{ shipmentNumber }} - Lead Times</p>
  </div>
  <app-lead-times
    [shipmentLeadTimes]="shipmentDetails?.leadTimeLogs"
  ></app-lead-times>
</div>
