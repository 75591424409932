import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ShipmentsService } from '../../services/shipments.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-delete-shipment-charge',
  templateUrl: './delete-shipment-charge.component.html',
  styleUrls: ['./delete-shipment-charge.component.scss']
})
export class DeleteShipmentChargeComponent implements OnInit {
  chargeId: string;
  shipmentId: string;

  constructor(
    private alertService: AlertService,
    private shipmentService: ShipmentsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.data['shipmentId']) {
      this.shipmentId = this.data['shipmentId'];
    }

    if (this.data['chargeId']) {
      this.chargeId = this.data['chargeId'];
    }
  }

  cancel() {
    this.dialogService.close();
  }

  deleteCharge() {
    this.shipmentService
      .deleteCharge(this.shipmentId, this.chargeId)
      .subscribe(res => {
        this.shipmentService.chargeDeleted.emit({ chargeId: this.chargeId });
        this.cancel();
        this.alertService.success('Charge successfully deleted.');
      });
  }
}
