import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChargeTypeWithService } from 'src/app/admin-portal-configs/models/charge-type-with-service.model';
import { ChargeTypeService } from 'src/app/admin-portal-configs/services/charge-type.service';
import { Currency } from 'src/app/shared/models/currency.model';
import { Charge } from 'src/app/shared/models/charge.model';

import { AlertService } from 'src/app/shared/services/alert.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { FreePlService } from '../../../shared/models/free-pl-service.model';
import { ShipmentsService } from '../../services/shipments.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-add-service-charge',
  templateUrl: './add-service-charge.component.html',
  styleUrls: ['./add-service-charge.component.scss']
})
export class AddServiceChargeComponent implements OnInit {
  TOTALSHIPMENTVALUE = 'Total Shipment Value';
  shipmentId: string = null;

  chargeCategories: FreePlService[];
  chargeTypes: ChargeTypeWithService[];
  filteredChargeTypes: ChargeTypeWithService[];
  currencies: Currency[];

  showCustomName: boolean = false;
  isSaving: boolean = false;
  charge: Charge = {} as Charge;
  charges: Charge[] = [];
  formTitle: string = 'Add Charge';
  chargeForm: UntypedFormGroup;

  idForOtherOption: number;

  constructor(
    private alertService: AlertService,
    private shipmentService: ShipmentsService,
    private helperService: HelperService,
    private chargeTypeService: ChargeTypeService,
    private fb: UntypedFormBuilder,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.loadLookUps();

    if (this.data['charge']) {
      this.charge = { ...this.data['charge'] };
      this.formTitle = 'Edit Charge';
    }

    if (this.data['charges']) {
      this.charges = this.data['charges'];
      this.charges = this.charges.filter(c => c.id != this.charge?.id);
    }

    this.shipmentId = this.data['shipmentId'];

    this.initializeForm();

    this.listenOnChargeTypeName();
  }

  loadLookUps() {
    if (!this.charge.id) {
      this.loadChargeCategories();
    }
    this.loadChargeTypes();
    this.loadCurrencies();
  }

  loadChargeCategories() {
    this.helperService.getServices().subscribe(res => {
      this.chargeCategories = res;
      this.chargeCategories.push({
        id: -1,
        name: 'Additional',
        isHasContractualRates: true,
        rate: -1,
        paymentTerm: -1
      });
    });
  }

  loadChargeTypes() {
    this.chargeTypeService.getAllWithServices().subscribe(res => {
      this.chargeTypes = res;
      this.filteredChargeTypes = res;
      this.chargeTypes = this.chargeTypes.sort((a, b) =>
        a.name < b.name ? -1 : 1
      );

      this.idForOtherOption = this.chargeTypes.find(c => c.name === 'Other').id;
      if (this.idForOtherOption === this.charge.chargeTypeId) {
        this.handleCustomFieldShowing(this.charge.chargeTypeId);
      }

      if (this.charge.serviceId) {
        this.changeChargeTypesBasedOnSelectedCategory(this.charge.serviceId);
      }
    });
  }

  loadCurrencies() {
    this.helperService.getCurrencies().subscribe(res => {
      this.currencies = res;
    });
  }

  handleCustomFieldShowing(selectedChargTypeId: number) {
    if (selectedChargTypeId === this.idForOtherOption) {
      this.showCustomName = true;
      this.chargeForm.get('chargeTypeName').setValidators(Validators.required);
    } else {
      this.showCustomName = false;
      this.chargeForm.get('chargeTypeName').clearValidators();
    }
  }

  listenOnChargeTypeName() {
    this.chargeForm
      .get('chargeTypeId')
      .valueChanges.subscribe(selectedChargTypeId => {
        this.handleCustomFieldShowing(selectedChargTypeId);
      });
  }

  private initializeForm(): void {
    this.chargeForm = this.fb.group({
      serviceId: [this.charge.serviceId, [Validators.required]],
      chargeTypeId: [this.charge.chargeTypeId, [Validators.required]],
      chargeTypeName: [this.charge.chargeTypeName],
      hasPercentageRate: [this.charge.hasPercentageRate],
      percentageRate: [this.charge.percentageRate],
      parentChargeId: [this.charge.parentChargeId],
      rate: [this.charge.rate?.toString()],
      currency: [this.charge.currency],
      quantity: [this.charge.quantity]
    });

    this.onChargeTypeChanged();
  }

  closeDialog() {
    this.dialogService.close();
  }

  addEditCharge() {
    this.checkValidation();

    if (this.chargeForm.valid && !this.isSaving) {
      this.isSaving = true;
      let formVal = this.chargeForm.value as Charge;
      formVal.currencyCode = this.currecncyCode;
      if (formVal.hasPercentageRate) {
        formVal.rate = null;
        formVal.quantity = null;
        formVal.currency = null;
      } else {
        formVal.parentChargeId = null;
        formVal.percentageRate = null;
      }

      if (formVal.chargeTypeId != this.idForOtherOption) {
        formVal.chargeTypeName = this.chargeTypes.find(
          c => c.id === formVal.chargeTypeId
        ).name;
      }

      if (this.charge.id) {
        formVal.id = this.charge.id;
        formVal.serviceName = this.charge.serviceName;
        this.editCharge(formVal, this.shipmentId);
      } else {
        formVal.serviceName = this.chargeCategories.find(
          c => c.id === formVal.serviceId
        ).name;
        this.addCharge(formVal, this.shipmentId);
      }
    }
  }

  get currecncyCode() {
    const currencyName = this.chargeForm.get('currency').value;
    return this.currencies.find(c => {
      return c.name === currencyName;
    })?.code;
  }

  editCharge(formVal: Charge, shipmentId: string) {
    this.shipmentService.updateCharge(shipmentId, formVal).subscribe(res => {
      this.shipmentService.chargeAddedEmitter.emit({
        added: false,
        addedCharge: res
      });
      this.alertService.success('Charge successfully updated.');
      this.closeDialog();
      this.isSaving = false;
    });
  }

  addCharge(formVal: Charge, shipmentId: string) {
    this.shipmentService.addCharge(shipmentId, formVal).subscribe(res => {
      this.shipmentService.chargeAddedEmitter.emit({
        added: true,
        addedCharge: res
      });
      this.alertService.success('Charge successfully added.');
      this.closeDialog();
      this.isSaving = false;
    });
  }

  private checkValidation(): void {
    this.chargeForm.markAllAsTouched();
    this.cdr.detectChanges();
  }

  selectCategory(event: any) {
    this.changeChargeTypesBasedOnSelectedCategory(event);
    this.chargeForm.get('chargeTypeId').reset();
  }

  changeChargeTypesBasedOnSelectedCategory(event: any) {
    if (event == -1) {
      this.filteredChargeTypes = this.chargeTypes.filter(
        i => i.isNotServiceCharge
      );
    } else {
      this.filteredChargeTypes = this.chargeTypes.filter(
        i => i.services.findIndex(s => s.id == event) > -1
      );
    }
  }

  onChargeTypeChanged() {
    (this.chargeForm.get(
      'hasPercentageRate'
    ) as UntypedFormGroup).valueChanges.subscribe(data => {
      if (data) {
        this.chargeForm.controls['percentageRate'].setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(100)
        ]);
        this.chargeForm.controls['percentageRate'].updateValueAndValidity();

        this.chargeForm.controls['parentChargeId'].setValidators([
          Validators.required
        ]);
        this.chargeForm.controls['parentChargeId'].updateValueAndValidity();

        this.chargeForm.controls['rate'].clearValidators();
        this.chargeForm.controls['rate'].setErrors(null);
        this.chargeForm.controls['quantity'].clearValidators();
        this.chargeForm.controls['quantity'].setErrors(null);
        this.chargeForm.controls['currency'].clearValidators();
        this.chargeForm.controls['currency'].setErrors(null);
      } else {
        this.chargeForm.controls['percentageRate'].clearValidators();
        this.chargeForm.controls['percentageRate'].setErrors(null);
        this.chargeForm.controls['parentChargeId'].clearValidators();
        this.chargeForm.controls['parentChargeId'].setErrors(null);

        this.chargeForm.controls['rate'].setValidators([Validators.required]);
        this.chargeForm.controls['rate'].updateValueAndValidity();
        this.chargeForm.controls['quantity'].setValidators([
          Validators.required,
          Validators.min(1)
        ]);
        this.chargeForm.controls['quantity'].updateValueAndValidity();
        this.chargeForm.controls['currency'].setValidators([
          Validators.required
        ]);
        this.chargeForm.controls['currency'].updateValueAndValidity();
      }
    });
  }
}
