import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ProductRegistrationLeadTime } from 'src/app/admin-portal-configs/models/product-registration-lead-time.model';
import { ProductRegistrationKpisService } from 'src/app/admin-portal-configs/services/product-registration-kpis.service';
import { RegistrationActivityService } from 'src/app/admin-portal-configs/services/registration-activity.service';
import { RegistrationActivity } from 'src/app/admin-portal-configs/models/registration-activity.model';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';

@Component({
  selector: 'app-add-or-edit-product-registration-kpis',
  templateUrl: './add-or-edit-product-registration-kpis.component.html',
  styleUrls: ['./add-or-edit-product-registration-kpis.component.scss']
})
export class AddOrEditProductRegistrationKpisComponent implements OnInit {
  leadTimeForm: UntypedFormGroup;
  productLeadTime: ProductRegistrationLeadTime;
  isNewProductLeadTime: boolean = false;
  activities: RegistrationActivity[];
  endTriggers: RegistrationActivity[] = [];

  daysHours: { label: string, value: boolean }[] = [
    { label: 'Days', value: false },
    { label: 'Hours', value: true }
  ];

  isSaving: boolean = false;
  hasErrorMessage = false;
  errorMessage: string = '';


  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private productLeadTimeService: ProductRegistrationKpisService,
    private route: ActivatedRoute,
    private activityService: RegistrationActivityService,
    private formsHelperService: FormsHelperService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.productLeadTime = data['productRegistrationLeadTime'];
    });

    this.loadActivities();
    this.initForm();
  }

  loadActivities(): void {
    this.activityService.getAll().subscribe(res => {
      this.activities = res;
      if (this.productLeadTime != null) {
        this.onChangingStartTrigger(null, this.productLeadTime.startTriggerId);
      }
    });
  }

  initForm(): void {
    this.isNewProductLeadTime = this.productLeadTime == null;
    this.productLeadTime =
      this.productLeadTime || ({} as ProductRegistrationLeadTime);

    this.leadTimeForm = this.fb.group({
      name: [this.productLeadTime.name, [Validators.required]],
      leadTime: [
        this.productLeadTime.leadTime,
        [Validators.required, Validators.min(1)]
      ],
      startTriggerId: [
        this.productLeadTime.startTriggerId,
        [Validators.required]
      ],
      endTriggerId: [this.productLeadTime.endTriggerId, [Validators.required]],
      daysHours: [this.productLeadTime?.isHours ? true : false, Validators.required]
    });
  }

  save(): void {
    this.isSaving = true;
    const formVal = this.leadTimeForm.value;
    this.assignIsHoursValue(formVal);

    if (!this.validateForm(formVal)) {
      this.isSaving = false;
      this.alertService.error('Invalid Form!');
      return;
    }

    formVal.isActive = true;

    if (this.isNewProductLeadTime) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  assignIsHoursValue(formVal): void {
    formVal.isHours = formVal.daysHours;
  }

  addNewRequest(formVal: any): void {
    this.productLeadTimeService.addNewLeadTime(formVal).subscribe(
      {
        next: () => {
          this.isSaving = false;
          this.router.navigate(['/settings/product-registration-kpis']);
          this.alertService.success('Product Registration KPI was Saved Successfully');
        },
        error: err => {
          this.isSaving = false;
          this.hasErrorMessage = true;
          this.errorMessage =
            err || 'An error occurred while Saving the Product Registration KPI';
        }
      }
    );
  }

  updateRequest(formVal: any): void {
    this.productLeadTimeService
      .updateLeadTime(this.productLeadTime.id, formVal)
      .subscribe(
        {
          next: () => {
            this.isSaving = false;
            this.router.navigate(['/settings/product-registration-kpis']);
            this.alertService.success('Product Registration KPI was Updated Successfully');
          },
          error: err => {
            this.isSaving = false;
            this.hasErrorMessage = true;
            this.errorMessage =
              err || 'An error occurred while Updating the Product Registration KPI';
          }
        }
      );
  }

  onChangingStartTrigger(event?: any, id?: number) {
    if (id) {
      this.endTriggers = this.activities.filter(a => a.id != id);
    } else if (event) {
      this.endTriggers = this.activities.filter(
        a => a.id != event?.id
      );
      this.leadTimeForm.controls['endTriggerId'].setValue(null);
    }
  }

  cancel() {
    this.resetForm();

    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );

    this.router.navigate(['/settings/product-registration-kpis']);
  }

  resetForm() {
    this.leadTimeForm.reset();
  }
  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.leadTimeForm,
      formControlName
    );
  }

  get isFormDisabled(): boolean {
    return this.leadTimeForm?.invalid;
  }

  private validateForm(formVal: any): boolean {
    const validTriggers =
      formVal.startTriggerId != 'null' && formVal.endTriggerId != 'null';
    return validTriggers && this.leadTimeForm.valid;
  }
}
