<div class="main-container">
  <h4 class="font-weight-bold">
    <span *ngIf="!isSuccess">
      {{ "resetPassword.ResetPassword" | translate }}
    </span>
  </h4>
  <h4 class="font-weight-bold title-welcome" *ngIf="isSuccess">
    <span>{{ "app.Welcome" | translate }}!</span>
  </h4>
  <p class="font-weight-bold reset-password-success" *ngIf="isSuccess">
    {{ "resetPassword.PasswordResetSuccessful" | translate }}
    {{ "resetPassword.Click" | translate }}
    <a class="reset-link" routerLink="/login"
      >{{ "resetPassword.Here" | translate }}
    </a>
    {{ "resetPassword.ToLogin" | translate }}
  </p>
  <form
    *ngIf="formResetToggle && !isSuccess"
    class="reset"
    name="resetForm"
    #f="ngForm"
    novalidate
    (ngSubmit)="
      f.form.valid
        ? resetPassword()
        : !username.valid &&
          showErrorAlert(
            'resetPassword.alerts.UsernameOrEmailRequired',
            'resetPassword.alerts.EnterUsernameOrEmail'
          );
      !password.valid &&
        showErrorAlert(
          'resetPassword.alerts.NewPasswordRequired',
          'resetPassword.alerts.EnterNewValidPassword'
        );
      password.valid &&
        confirmPassword.errors?.required &&
        showErrorAlert(
          'resetPassword.alerts.ConfirmationPasswordRequired',
          'resetPassword.alerts.EnterConfirmationPassword'
        );
      password.valid &&
        confirmPassword.errors?.validateEqual &&
        showErrorAlert(
          'resetPassword.alerts.PasswordMismatch',
          'resetPassword.alerts.NewAndConfirmationPasswordMismatch'
        )
    "
  >
    <div class="form-group row reset-password-form position-relative">
      <label class="form-label">
        Email
      </label>
      <input
        type="text"
        id="reset-username"
        name="username"
        placeholder="{{
          'resetPassword.UsernameOrEmailPlaceholder' | translate
        }}"
        class="form-control"
        [ngClass]="{
          'is-valid': f.submitted && username.valid,
          'is-invalid': f.submitted && !username.valid
        }"
        [(ngModel)]="usernameOrEmail"
        #username="ngModel"
        required
      />
      <div class=" invalid-login" *ngIf="f.submitted && !username.valid">
        <span class="invalid-login-message">
          {{ "resetPassword.UsernameOrEmailRequired" | translate }}
        </span>
      </div>
    </div>

    <div class="form-group row reset-password-form position-relative">
      <label class="form-label">
        Password
      </label>
      <i
        (click)="toggleShowPassword(false)"
        class="fa password-reveal-icon"
        [class]="isShowPassword ? 'fa-eye-slash' : 'fa-eye'"
        aria-hidden="true"
      ></i>
      <input
        [type]="isShowPassword ? 'text' : 'password'"
        id="reset-password"
        name="password"
        placeholder="{{ 'resetPassword.NewPasswordPlaceholder' | translate }}"
        class="form-control"
        [ngClass]="{
          'is-valid': f.submitted && password.valid,
          'is-invalid': f.submitted && !password.valid
        }"
        [(ngModel)]="newPassword"
        #password="ngModel"
        required
        minlength="6"
        appValidateEqual="confirmPassword"
        reverse="true"
      />
      <div class=" invalid-login" *ngIf="f.submitted && !password.valid">
        <span class="invalid-login-message">
          {{ "resetPassword.NewPasswordRequired" | translate }}
        </span>
      </div>
    </div>

    <div class="form-group row reset-password-form position-relative">
      <label class="form-label">
        Confirm Password
      </label>
      <i
        (click)="toggleShowPassword(true)"
        class="fa password-reveal-icon"
        [class]="isShowConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
        aria-hidden="true"
      ></i>
      <input
        [type]="isShowConfirmPassword ? 'text' : 'password'"
        id="reset-confirm-password"
        name="confirmPassword"
        placeholder="{{
          'resetPassword.ConfirmPasswordPlaceholder' | translate
        }}"
        class="form-control"
        [ngClass]="{
          'is-valid': f.submitted && confirmPassword.valid,
          'is-invalid': f.submitted && !confirmPassword.valid
        }"
        [(ngModel)]="confirmationPassword"
        #confirmPassword="ngModel"
        required
        appValidateEqual="password"
      />

      <div
        class=" invalid-login"
        *ngIf="f.submitted && confirmPassword.errors?.required"
      >
        <span class="invalid-login-message">
          {{ "resetPassword.ConfirmPasswordRequired" | translate }}
        </span>
      </div>
      <span
        class="invalid-login"
        *ngIf="
          f.submitted &&
          confirmPassword.errors?.validateEqual &&
          !confirmPassword.errors?.required
        "
        ><span class="invalid-login-message">
          {{ "resetPassword.PasswordMismatch" | translate }}
        </span>
      </span>
    </div>

    <div class="form-group  reset-password-form">
      <div ngPreserveWhitespaces>
        <button
          *ngIf="!isSuccess"
          type="submit"
          class="btn submit-button"
          [disabled]="isLoading"
        >
          <i *ngIf="isLoading" class="fa fa-circle-o-notch fa-spin"></i>
          {{
            isLoading
              ? ("resetPassword.Saving" | translate)
              : ("resetPassword.ChangePassword" | translate)
          }}
        </button>
      </div>
    </div>
  </form>
</div>
