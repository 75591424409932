import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';
import { Supplier } from '../../models/supplier.model';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SupplierService } from 'src/app/supplier/services/supplier.service';
import { PagedResult } from 'src/app/shared/models/paged-result';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';

@Component({
  selector: 'app-suppliers-list',
  templateUrl: './suppliers-list.component.html',
  styleUrls: ['./suppliers-list.component.scss']
})
export class SuppliersListComponent implements OnInit, OnDestroy {
  entityType = EntityType;
  columns: any[] = [];
  suppliersList: Supplier[] = [];
  supplierData: PagedResult<Supplier>;
  isLoading = false;

  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<
    any
  >;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<
    any
  >;
  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('headquatersTemplate', { static: true })
  headquatersTemplate: TemplateRef<any>;
  searchTerm = new Subject<string>();
  private destroySearch$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private supplierService: SupplierService
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.initializeTable();
    this.initSearch();
    this.supplierService.supplier = null;
  }

  ngOnDestroy(): void {
    this.destroySearch$.next();
    this.destroySearch$.complete();
  }

  initSearch(): void {
    this.searchTerm
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        filter(term => term && term.length >= 3),
        switchMap(term => this.loadSuppliers(true, term)),
        takeUntil(this.destroySearch$)
      )
      .subscribe(pagedResult => {
        this.supplierData = pagedResult;
        this.suppliersList = pagedResult.data;
      });
  }

  onSearchChanged(term: string) {
    this.searchTerm.next(term);
  }

  initializeData(): void {
    this.supplierData = this.activatedRoute.snapshot.data['suppliers'];
    this.suppliersList = this.supplierData?.data || [];
  }

  initializeTable(): void {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'name',
        name: gT('config.management.Supplier.Name'),
        cellTemplate: this.nameTemplate,
        width: 560
      },
      {
        prop: 'type.name',
        name: gT('config.management.Supplier.Type'),
        width: 560
      },
      {
        prop: 'id',
        name: gT('config.management.Supplier.Headquarters'),
        cellTemplate: this.headquatersTemplate,
        width: 560
      },
      {
        prop: 'status.name',
        name: gT('config.management.Supplier.Status'),
        cellTemplate: this.statusTemplate,
        width: 560,
        resizeable: false
      },
      {
        prop: 'options',
        name: gT('config.management.Supplier.Actions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  loadSuppliers(
    isNewSearch: boolean,
    searchTerm?: string
  ): Observable<PagedResult<Supplier>> {
    if (isNewSearch) {
      this.supplierData.pageIndex = 1;
    }

    this.isLoading = true;

    return this.supplierService
      .getSuppliersPaged(
        this.supplierData?.pageIndex,
        this.supplierData?.pageSize,
        searchTerm
      )
      .pipe(
        tap(pagedResult => {
          this.isLoading = false;
        })
      );
  }

  getHeadquarters(id: string): string {
    const supplier = this.suppliersList.find(e => e.id === id);
    const headquarters =
      supplier?.city?.name && supplier?.country?.name
        ? `${supplier.city.name}, ${supplier.country.name}`
        : '';
    return headquarters;
  }

  onScroll(): void {
    if (
      this.supplierData?.pageIndex >= this.supplierData?.totalPages ||
      this.isLoading
    )
      return;
    this.supplierData.pageIndex++;
    this.loadSuppliers(false).subscribe(pagedResult => {
      this.supplierData = pagedResult;
      this.suppliersList = [...this.suppliersList, ...pagedResult.data];
    });
  }

  get currentCompany(): number {
    return Number(this.authService.currentUser.defaultCustomerCompany);
  }
}
