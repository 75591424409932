<div class="icon-addon addon-sm d-flex">
  <div><i class="fa fa-search left-icon search-icon"></i></div>
  <input
    id="searchInput"
    #searchInput
    type="search"
    [ngModel]="''"
    (ngModelChange)="onValueChange($event)"
    [attr.placeholder]="placeholder"
    class="form-control left-icon right-icon"
  />
  <a
    *ngIf="searchInput.value"
    href="javascript:;"
    title="Clear"
    (click)="clear()"
    class="fa fa-times-circle clear-input right-icon"
  ></a>
</div>
