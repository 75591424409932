import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivityService } from '../../admin-portal-configs/services/activity.service';
import { Activity } from '../models/shipment/activity.model';
import { PagedResult } from '../models/paged-result';

@Injectable({
  providedIn: 'root'
})
export class ActivityListResolver implements Resolve<PagedResult<Activity>> {
  constructor(
    private router: Router,
    private activityService: ActivityService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PagedResult<Activity>> {
    const isVoidActivities = route.data['isVoidActivities'] || false;
    return this.activityService.getAllActivities(isVoidActivities).pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
