<div class="timeline-container">
  <div
    *ngFor="let step of steps"
    class="timeline-group"
    [routerLink]="'./' + step.url"
    routerLinkActive="active"
    [ngClass]="{
      active: step.isActive,
      submitted: step.isSubmitted,
      disabled: step.isDisabled
    }"
  >
    <svg-icon [name]="step.icon" class="icon"></svg-icon>
    <div>
      <div class="timeline-title">{{ step.name }}</div>
      <div class="timeline-description">
        {{ step.description }}
      </div>
    </div>
  </div>
</div>
