import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndustryService } from 'src/app/admin-portal-configs/services/industry.service';
import { IndustryEnum } from 'src/app/shared/models/enums/industry.enum';
import { Industry } from 'src/app/shared/models/industry.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ColumnNames } from 'src/app/shipments/enums/shipments-lists-column-name.enum';
import { ShipmentsService } from '../../services/shipments.service';
import { ModuleName } from 'src/app/shared/models/enums/module-name.enum';
import { ProductRegistrationColumnsService } from 'src/app/product-registration/services/product-registration-columns.service';
import { ProductRegistrationViewColumn } from 'src/app/product-registration/models/product-registration-view-column.model';
import { LocalStoreManager } from 'src/app/shared/services/local-store-manager.service';
import { ViewColumn } from 'src/app/shared/models/view-column.model';
import { EmployeeViewPreferenceService } from 'src/app/shared/services/employee-view-preference.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { ProductColumnsService } from 'src/app/product-library/services/product.columns.service';
import { ProductHelperService } from 'src/app/create-product/services/product-helper.service';
import { CreateQuoteHelperService } from 'src/app/create-quote/services/create-quote-helper.service';
import { QuoteViewColumnService } from 'src/app/quote/services/quote-view-column.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-table-view-columns-filter',
  templateUrl: './table-view-columns-filter.component.html',
  styleUrls: ['./table-view-columns-filter.component.scss']
})
export class TableViewColumnsFilterComponent implements OnInit {
  @Input() moduleName: string;
  @Input() tableViewColumns: string[];
  @Input() columnsFilter: string[];
  @Input() defaultColumns: string[];
  @Input() minColumns: number;
  industry: Industry;
  viewColumns: ViewColumn[];
  selectedViewColumns: ViewColumn[];
  columnsForm: UntypedFormGroup;
  isColumnsFormValid: boolean = true;
  columns: string[] = [];

  constructor(
    private shipmentsService: ShipmentsService,
    private industryService: IndustryService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productRegistrationColumnsService: ProductRegistrationColumnsService,
    private localStorage: LocalStoreManager,
    private employeeViewPreferenceService: EmployeeViewPreferenceService,
    private productColumnsService: ProductColumnsService,
    private productHelperService: ProductHelperService,
    private quoteHelperService: CreateQuoteHelperService,
    private quoteViewColumnService: QuoteViewColumnService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.getIndustryByCompanyId();
    this.intVariablesFromDialogData();
    this.getShipmentColumns();
    this.intializeForm();
  }

  intVariablesFromDialogData() {
    if (!this.moduleName) {
      this.moduleName = this.data['moduleName'];
    }

    if (!this.tableViewColumns) {
      this.tableViewColumns = this.data['columnNames'];
      this.columns = this.data['columnNames'];
    }

    if (!this.defaultColumns) {
      this.defaultColumns = this.data['defaultColumns'];
    }

    if (!this.columnsFilter && this.data['columnsFilter']) {
      this.columnsFilter = this.data['columnsFilter'];
    }

    if (!this.columnsFilter && !this.data['columnsFilter']) {
      this.columnsFilter = this.defaultColumns;
    }

    if (!this.minColumns) {
      this.minColumns = this.data['minColumns'];
    }
    if (this.data['viewColumns']) {
      this.viewColumns = this.data['viewColumns'];
    }

    if (this.data['selectedViewColumns']) {
      this.selectedViewColumns = this.data['selectedViewColumns'];
    }
  }

  getShipmentColumns() {
    if (this.isShipmentModule) {
      this.columns = this.viewColumns
        .filter(
          columnName => !this.fixedShipmentColumns.includes(columnName.name)
        )
        .map(c => c.name);
    }
  }

  intializeForm() {
    this.columnsForm = new UntypedFormGroup({});
    if (!this.isShipmentModule) {
      this.tableViewColumns.forEach(columnName => {
        let formControlValue = this.columnsFilter?.includes(columnName);
        this.columnsForm.addControl(
          columnName,
          new UntypedFormControl(formControlValue)
        );
      });
    } else {
      this.viewColumns.forEach(columnName => {
        let formControlValue = this.selectedViewColumns?.some(
          c => c.id == columnName.id
        );
        this.columnsForm.addControl(
          columnName.name,
          new UntypedFormControl(formControlValue)
        );
      });
    }
  }

  save() {
    this.isColumnsFormValid = this.isFormValid();

    if (this.isColumnsFormValid == true) {
      if (this.moduleName == ModuleName.Shipment) {
        this.employeeViewPreferenceService
          .updateShipmentSelectedColumn(this.employeeId, this.selectedColumnsId)
          .subscribe(response => {
            //selected columns name
            this.selectedViewColumns = [];
            this.selectedViewColumns = response;
            this.localStorage.saveSyncedSessionData(
              this.selectedViewColumns,
              'selectedShipmentView'
            );
            this.shipmentsService.displayedColumnsChanged.emit({
              columnNames: this.selectedColumns
            });
            this.cancel(this.selectedViewColumns);
          });
      } else if (this.moduleName == ModuleName.Registration) {
        let productRegistrationSelectedColumns: ProductRegistrationViewColumn[] = this.selectedColumns.map(
          column => ({ name: column })
        );

        this.productRegistrationColumnsService
          .saveSelectedColumns(productRegistrationSelectedColumns)
          .subscribe(data => {
            this.localStorage.saveSyncedSessionData(
              productRegistrationSelectedColumns,
              'productRegistrationSelectedColumns'
            );
            this.productRegistrationColumnsService.proudctRegistrationDisplayedColumnsEmitter.emit(
              {
                columns: productRegistrationSelectedColumns
              }
            );
          });
      } else if (this.moduleName == ModuleName.ProductLibrary) {
        this.productColumnsService
          .updateEmployeeSelectedColumns(
            this.isMedicalOrPharmaceuticalIndustry,
            this.selectedColumns
          )
          .subscribe(data => {
            this.productColumnsService.selectedColumnsChanged.emit(data);
          });
      } else if (this.moduleName == ModuleName.Quote) {
        this.quoteViewColumnService
          .updateEmployeeSelectedColumns(this.selectedColumns)
          .subscribe(data => {
            this.quoteHelperService.selectedColumnsChanged.emit(data);
          });
      }
      this.cancel(null);
    }
  }

  getFormControlValue(controlName: string): boolean {
    return this.columnsForm.controls[controlName].value;
  }

  isFormValid(): boolean {
    if (this.numberOfSelectedColumns >= this.minColumns) {
      return true;
    } else {
      return false;
    }
  }
  get isShipmentModule(): boolean {
    return this.moduleName == ModuleName.Shipment;
  }
  get selectedColumns(): ColumnNames[] {
    let columns = [];
    //this is a temporary implementation because this component is reusable
    if (!this.isShipmentModule) {
      this.tableViewColumns.forEach(columnName => {
        let value = this.getFormControlValue(columnName);
        if (value == true) {
          columns.push(columnName);
        }
      });
    } else {
      this.viewColumns.forEach(column => {
        let value = this.getFormControlValue(column.name);
        if (value == true) {
          columns.push(Utilities.getColumnNamesValueFromString(column.name));
        }
      });
      columns.concat(this.fixedShipmentColumns);
    }

    return columns;
  }
  get selectedColumnsId(): number[] {
    let columnIds: number[] = [];
    this.selectedColumns.forEach(column => {
      let id = this.viewColumns.find(s => s.name == column).id;

      columnIds.push(id);
    });
    return columnIds;
  }

  cancel(columns: ViewColumn[]) {
    this.dialogService.close(columns);
  }

  get employeeId(): number {
    return +this.authService.currentUser?.employeeId;
  }
  get numberOfSelectedColumns(): number {
    let selectedColumnsNumber = 0;

    Object.entries(this.columnsForm.controls).forEach(([key, value]) => {
      if (key) selectedColumnsNumber += value.value;
    });
    return selectedColumnsNumber;
  }

  get defaultcompany() {
    return this.authService?.currentUser?.defaultCustomerCompany;
  }

  getIndustryByCompanyId() {
    this.industryService
      .getIndustryByCompanyId(+this.defaultcompany)
      .subscribe(data => {
        this.industry = data;
        this.hideColumns();
      });
  }

  hideColumns() {
    let industryName = this.industry?.name;
    if (
      !(
        industryName == IndustryEnum.MedicalEquipmentAndSuppliesManufacturing ||
        industryName == IndustryEnum.PharmaceuticalManufacturing
      )
    ) {
      this.tableViewColumns = this.tableViewColumns.filter(item => {
        return !this.columnsNamesBasedOnIndustry.includes(item);
      });
    }
  }

  get columnsNamesBasedOnIndustry(): string[] {
    return [
      ColumnNames.ProductClassification,
      ColumnNames.EDASubmissionNo,
      ColumnNames.EDASubmissionDate,
      ColumnNames.EDAImportationApprovalNo,
      ColumnNames.EDAImportationApprovalDate,
      ColumnNames.RUINo,
      ColumnNames.RUIDate
    ];
  }

  get fixedShipmentColumns(): string[] {
    return [
      ColumnNames.MoT,
      ColumnNames.ShipmentNo,
      ColumnNames.Status,
      ColumnNames.RefNo,
      ColumnNames.DateCreated
    ];
  }
  get columnslist(): string[] {
    if (!this.isShipmentModule) {
      return this.tableViewColumns;
    } else {
      return this.columns;
    }
  }

  get isMedicalOrPharmaceuticalIndustry(): boolean {
    return this.authService?.isMedicalOrPharmaceuticalIndustry;
  }
}
