<app-page-header
  [title]="'config.management.DocumentTypes.Manage' | translate"
></app-page-header>

<app-page-action-header
  placeholder="{{ 'config.management.DocumentTypes.Search' | translate }}"
  addLabel="{{ 'config.management.DocumentTypes.New' | translate }}"
  [addUrl]="'./add'"
  [canAdd]="canCreateDocumentType"
  (searchChanged)="onSearchChanged($event)"
></app-page-action-header>
<ngx-datatable
  class="material colored-header sm table table-striped table-hover mt-5"
  [loadingIndicator]="loadingIndicator"
  [rows]="rows"
  [rowHeight]="35"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
>
</ngx-datatable>

<ng-template #indexTemplate let-value="value">
  <strong>{{ value }}</strong>
</ng-template>

<ng-template #isPinnedTemplate let-row="row" let-value="value" let-i="id">
  <span
    *ngIf="value; else falseLabel"
    class="user-role badge badge-pill badge-success"
  >
    {{ "forms.Yes" | translate }}
    <i class="fa fa-check" aria-hidden="true"></i>
  </span>

  <ng-template #falseLabel>
    <span class="user-role badge badge-pill badge-danger">
      {{ "forms.No" | translate }}
      <i class="fa fa-times" aria-hidden="true"></i>
    </span>
  </ng-template>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
  <div>
    <svg-icon
      *ngIf="canUpdateDocumentType"
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      [routerLink]="['./' + row.id + '/edit']"
    ></svg-icon>
    <svg-icon
      *ngIf="canDeleteDocumentType"
      class="action-btn cursor-pointer pr-2"
      name="delete-circle"
      (click)="deleteDocumentType(row)"
    ></svg-icon>
  </div>
</ng-template>
