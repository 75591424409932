import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDatePickerHeaderComponent } from 'src/app/shared/components/mat-date-picker-header/mat-date-picker-header.component';
import { ProductUploadFile } from '../../../create-product-registration/models/upload-file.model';
import { ProductDocumentTypesCritirea } from '../../../create-product-registration/models/product-document-types-criteria.model';
import { DocumentState } from 'src/app/shared/models/document-state.model';
import { UploadedFile } from 'src/app/shared/models/upload-file.model';
import { DocumentTypeEnum } from 'src/app/shared/models/enums/document-type.enum';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { DocumentService } from 'src/app/shared/services/document.service';
import {
  HttpEventType,
  HttpResponse,
  HttpStatusCode
} from '@angular/common/http';
import { ProductUpdateDocumentNote } from '../../models/product-update-document-notes.model';
import { DatePipe } from '@angular/common';
import { ProductCompatibleDocuments } from '../../models/product-compatible-documents';
import { FileViewerService } from 'src/app/shared/components/file-viewer/file-viewer.service';
import { CreateProductService } from 'src/app/create-product/services/create-product.service';
import { ProductBase } from 'src/app/shared/models/product-library/product-base.model';
import { ProductDocument } from '../../models/product-document.model';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { ProductDocumentCategory } from '../../models/enums/product-document-category.enum';
import { AuthService } from '../../services/auth.service';
import { CreateProductRegistrationService } from 'src/app/create-product-registration/service/create-product-registration.service';
import { ProductWithSimilarDocumentType } from '../../models/product-library/product-with-similar-document-type.model';
import { ProductCategoryEnum } from 'src/app/create-product/enums/product-category.enum';

@Component({
  selector: 'app-product-upload-documents',
  templateUrl: './product-upload-documents.component.html',
  styleUrls: ['./product-upload-documents.component.scss']
})
export class ProductUploadDocumentsComponent implements OnInit {
  matDateHeader = MatDatePickerHeaderComponent;
  disableAnyAction = false;
  uploadedFiles: ProductUploadFile[] = [];
  documentTypes: DocumentState[] = [];
  percentageValue = 0;
  formData: FormData = new FormData();
  fileName: string;
  isUploadFileErrorState = false;
  isDocumentAdded = false;
  uploadError = { isError: false, type: '', msg: '' };
  documentType: DocumentType;
  isExistDocumentOtherName = false;
  isDocumentTypeErrorState = false;
  fileUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Input() parentType: DocumentParentType;
  @Input() ProductCompatibleDocuments: ProductCompatibleDocuments[];
  @Input() form: UntypedFormGroup;
  @Output() isUploading = new EventEmitter<boolean>();
  @Output() isAllDocumentUploaded = new EventEmitter<boolean>();

  isLinkExistingDocument: boolean = false;
  existingDocuments: ProductWithSimilarDocumentType[];
  isLoading: boolean = false;

  existingDocumentsObservable: Observable<any> = new Observable<any>();
  existingDocuments$ = new Subject<string>();

  constructor(
    private productRegistrationService: CreateProductRegistrationService,
    private formsHelperService: FormsHelperService,
    private documentService: DocumentService,
    private fileViewerService: FileViewerService,
    private createProductService: CreateProductService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getProductDocumentType();
    this.populateExistingValues();
    this.ProductCompatibleDocuments = this.ProductCompatibleDocuments.filter(
      d => d.isRequired
    );
    this.form.get('documentType').valueChanges.subscribe(res => {
      if (res !== '' && !this.disableAnyAction) {
        this.documentType = res;
        this.form.get('otherDocumentType').setValue('');
        this.isDocumentTypeErrorState = false;
      }
      this.addOrClearRequiredValidatorToExpirationDate();
      this.getProductsWithRecentlyUploadedDocumentType();
    });

    this.form.get('otherDocumentType').valueChanges.subscribe(res => {
      if (res !== '' && !this.disableAnyAction) {
        this.documentType = res;
        this.form.get('documentType').setValue('');
        this.isExistDocumentOtherName = false;
        this.isDocumentTypeErrorState = false;
        this.getProductsWithRecentlyUploadedDocumentType();
      }
    });

    this.validateIsAllProductCompatibleDocumentsUploaded();
  }

  browseFile(input: HTMLInputElement): void {
    if (this.disableAnyAction) {
      return;
    }
    input.click();
  }

  onDragOver(event): void {
    event.preventDefault();
  }

  onDropSuccess(event): void {
    event.preventDefault();
    this.upload(event.dataTransfer);
  }

  upload(e): void {
    const file = e.files[0];
    if (file == null) {
      return;
    }
    this.isUploadFileErrorState = false;
    this.isDocumentTypeErrorState = false;
    if (
      file.size / 1024 / 1024 > 20 ||
      !file.type.match(
        `image.*|text.*|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/pdf|image/png`
      )
    ) {
      this.uploadError = {
        isError: true,
        type: file.size / 1024 / 1024 > 20 ? 'size' : 'type',
        msg:
          file.size / 1024 / 1024 > 20
            ? 'File size exceeds maximum limit allowed. Add another file with size less than or equal to 10 MB.'
            : 'File type is not supported. Add another file with either of the following formats (pdf, jpg, jpeg, png, xlsx, xls, xlsm, docx, doc, csv)'
      };
    }

    this.form.get('file').setValue(e.files[0]);
    this.formData.set('submittedFile', file);
    this.fileName = file.name;
  }

  addDocument() {
    if (!this.uploadValidation()) {
      return;
    }

    if (this.isLinkExistingDocument) {
      this.addExistingDocumentToProduct();
      return;
    }

    const note = this.form.get('notes').value;
    this.resetExpirationDate();
    this.disableAnyAction = true;
    const expirationDate = this.form.get('expirationDate').value;

    this.formData.set('parentId', this.parentTypeId);
    this.formData.set('parentType', this.parentType);
    this.formData.set('description', this.form.get('notes').value);
    this.formData.set('documentTypeId', this.documentType['id'].toString());
    this.formData.set('expirationDate', this.formatDate(expirationDate));

    if (this.isRepatedName(this.documentTypeNameFormat)) {
      this.disableAnyAction = false;
      this.isExistDocumentOtherName = true;
      return;
    } else {
      this.formData.set('documentOtherName', this.documentTypeNameFormat);
    }
    if (!this.isSelectedOtherDocumentType) {
      this.changeFileTypeStatus(
        '',
        this.documentType['id'].toString(),
        false,
        true
      );
    }
    const uploadedFile: ProductUploadFile = {
      typeName: this.documentTypeNameFormat,
      fileName: this.fileName,
      path: '',
      id: this.documentType['id'],
      isUploaded: false,
      isCanceled: false,
      isError: false,
      isUploading: true,
      percentageValue: 0,
      note: note,
      file: null,
      documentTypeId: this.documentType['id'],
      expirationDate: expirationDate,
      isEditing: false
    };

    this.UploadDocument(uploadedFile);
  }

  isRepatedName(documentOtherName): boolean {
    return this.uploadedFiles?.find(d => d.typeName == documentOtherName)
      ? true
      : false;
  }

  removeFile(): void {
    this.form.get('file').setValue('');
    this.uploadError.isError = false;
    this.isUploadFileErrorState = false;
  }

  get isProduct(): boolean {
    return this.parentType == DocumentParentType.Product;
  }
  get currentDate(): Date {
    return new Date();
  }

  get hasFile(): boolean {
    return this.form.get('file').value !== '';
  }

  get parentTypeId(): string {
    return this.product?.id;
  }

  get isdocumentUploaded(): boolean {
    return (
      this.form.get('documentType').value != '' &&
      this.isDocumentAdded &&
      !this.form.invalid
    );
  }
  get documentTypeNameFormat() {
    if (this.isSelectedOtherDocumentType) {
      return (
        DocumentTypeEnum.Other +
        ' - ' +
        this.form.get('otherDocumentType')?.value
      );
    }
    return this.documentType?.name;
  }

  get isSelectedOtherDocumentType() {
    let selectedDocumentType = this.form?.get('otherDocumentType')?.value?.name;
    return this.isOtherDocumentType(selectedDocumentType);
  }

  isOtherDocumentType(documentTypeName: string) {
    return documentTypeName == DocumentTypeEnum.Other;
  }

  isInvalidController(controllerName: string): boolean {
    if (this.isDocumentAdded) {
      const control = this.form.get(controllerName);

      return control.hasError('required');
    }
  }

  deleteUploadedFile(document: ProductUploadFile) {
    if (this.disableAnyAction) {
      return;
    }

    this.createProductService
      .deleteDocumet(this.parentTypeId, document.id)
      .subscribe(response => {
        this.changeFileTypeStatus(
          document.id.toString(),
          document.documentTypeId.toString(),
          false,
          false
        );
        this.removeDocumentFromUplodedList(document.documentTypeId);
      });
  }

  removeDocumentFromUplodedList(documentTypeId: number) {
    const index = this.uploadedFiles.findIndex(
      i => i.documentTypeId == documentTypeId
    );

    if (index != -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  selectDocumentType(documentType: DocumentType) {
    if (!documentType?.name) {
      return;
    } else {
      if (this.isOtherDocumentType(documentType?.name)) {
        this.formsHelperService.addRequiredValidator(
          this.form,
          'otherDocumentType'
        );
      } else {
        this.formsHelperService.clearValidator(this.form, 'otherDocumentType');
      }
    }
  }

  get isNonExpiring(): boolean {
    return this.documentType?.isNonExpiring;
  }

  cancelUpload(
    uploadedFile: ProductUploadFile,
    file: File,
    note: string
  ): void {
    this.disableAnyAction = false;
    this.fileUploadingSubject.next(true);

    const updatedFile: ProductUploadFile = Object.assign({}, uploadedFile, {
      isUploaded: false,
      isUploading: false,
      isCanceled: true,
      file: file,
      note: note
    });

    const updatedFileIndex = this.uploadedFiles.findIndex(
      x => x.documentTypeId == updatedFile.id
    );

    const typeIndex = this.documentTypes.findIndex(
      x => x.id == updatedFile.documentTypeId
    );

    this.documentTypes[typeIndex].uploading = false;

    this.uploadedFiles[updatedFileIndex] = updatedFile;
  }

  reuploadFile(file: ProductUploadFile): void {
    if (this.disableAnyAction) {
      return;
    }
    this.form.controls.notes.patchValue(file.note);
    const e = { files: [file.file] };
    this.upload(e);
    this.UploadDocument(file);
  }

  toggleEditMode(uploadedFile: ProductUploadFile) {
    uploadedFile.isEditing = true;
  }

  saveNote(uploadedFile: ProductUploadFile) {
    const notes: ProductUpdateDocumentNote = {
      documentSqlId: uploadedFile.id,
      note: uploadedFile.note
    };

    this.createProductService
      .updateProductDocumentNote(this.parentTypeId, notes)
      .subscribe(res => { });
  }

  get product(): ProductBase {
    return this.createProductService?.product;
  }
  private resetUploadDocumentsForm() {
    this.isUploadFileErrorState = false;
    this.isDocumentAdded = false;
    this.disableAnyAction = false;
    this.form.reset();
    this.form.get('file').setValue('');
    this.form.get('notes').setValue('');
    this.form.get('otherDocumentType').setValue(null);
    this.uploadError = {
      isError: false,
      type: '',
      msg: ''
    };
    this.removeFile();
    this.isUploading.emit(false);
    this.isLinkExistingDocument = false;
    this.form.get('existingDocument').setValue('');
    this.existingDocuments = [];
  }

  private populateExistingValues() {
    this.pushProductDocumentToUploadedfiles(this.product?.productDocuments);
  }

  get companyType(): string {
    let productCategory = this.product?.productInformation?.productCategory;
    return (productCategory == ProductCategoryEnum.Healthcare || productCategory == ProductCategoryEnum.Pharmaceuticals)
      ? ProductDocumentCategory.Medical
      : ProductDocumentCategory.NonMedical;
  }

  get productDocumentTypesCritirea(): ProductDocumentTypesCritirea {
    let productCritirea: ProductDocumentTypesCritirea;

    productCritirea = {
      classificationType: this.product?.complianceInformation
        ?.classificationType,
      certificationType: this.product?.complianceInformation?.certificationType,
      documentType: this.companyType
    };

    return productCritirea;
  }

  private pushProductDocumentToUploadedfiles(
    productDocument: ProductDocument[]
  ) {
    productDocument?.forEach(el => {
      const uploadedFile: ProductUploadFile = {
        typeName: el.documentTypeName,
        fileName: el.fileName,
        path: el.path,
        id: el.documentSqlId,
        isUploaded: true,
        isCanceled: false,
        isError: false,
        isUploading: false,
        percentageValue: 100,
        note: el.notes,
        file: null,
        documentTypeId: el.documentTypeId,
        expirationDate: el.expirationDate,
        isEditing: false
      };

      this.uploadedFiles.push(uploadedFile);
    });
  }

  private getProductDocumentType() {
    this.productRegistrationService
      .getProductRegistartionDocumentTypes(this.productDocumentTypesCritirea)
      .subscribe(response => {
        this.documentTypes = response.map(x => {
          return {
            id: x.id,
            name: x.name,
            nameKey: x.nameKey,
            isPinned: x.isPinned,
            uploaded: this.uploadedFiles.some(y => y.documentTypeId == x.id),
            uploading: false,
            isRequired: this.isProduct ? false : x.isRequired,
            isNonExpiring: x.isNonExpiring
          } as DocumentState;
        });
        this.excludeRegistrationLicenseDocumentType();
      });
  }

  private uploadValidation(): boolean {
    this.isUploading.emit(true);
    this.isDocumentAdded = true;

    if (
      !this.documentType ||
      !this.form.valid ||
      (!this.isLinkExistingDocument &&
        (this.uploadError.isError || !this.hasFile))
    ) {
      this.isUploadFileErrorState = !this.hasFile;
      this.isDocumentTypeErrorState = !this.documentType;
      return false;
    }
    return true;
  }

  private changeFileTypeStatus(
    id: string,
    documentTypeId: string,
    isUploaded: boolean,
    isUploading: boolean
  ): void {
    if (isUploaded === false) {
      this.uploadedFiles = this.uploadedFiles.filter(
        item => item.id !== parseInt(id)
      );
    }
    const fileIndex = this.uploadedFiles.findIndex(
      x => x.documentTypeId === parseInt(documentTypeId)
    );

    this.uploadedFiles[fileIndex] = {
      ...this.uploadedFiles[fileIndex],
      isCanceled: false,
      isUploading: isUploading,
      isError: false
    };

    Object.assign(
      this.documentTypes,
      this.documentTypes.map(el =>
        el.id === parseInt(documentTypeId)
          ? {
            uploaded: isUploaded,
            uploading: isUploading,
            id: el.id,
            name: el.name,
            isPinned: el.isPinned,
            nameKey: el.nameKey,
            isRequired: el.isRequired
          }
          : el
      )
    );
    this.validateIsAllProductCompatibleDocumentsUploaded();
  }

  private updateUploadedDocumentStatus(id: string, isUploaded: boolean = true) {
    this.documentTypes.find(d => d.id == parseInt(id)).uploaded = isUploaded;
  }

  percentageValueHandler(
    data: {
      loaded: number;
      total: number;
    },
    uploadedFileIndex: number
  ): void {
    const percentDone = Math.round((100 * data.loaded) / data.total);
    this.percentageValue = percentDone;
    if (this.uploadedFiles[uploadedFileIndex]) {
      this.uploadedFiles[uploadedFileIndex].percentageValue = percentDone;
    }
  }

  viewFile(path) {
    this.fileViewerService.view(path);
  }
  handleProgress(
    data: any,
    file: ProductUploadFile,
    isError: boolean = false
  ): void {
    if (isError) {
      const uploadedFiles: ProductUploadFile = Object.assign({}, file, {
        id: file.documentTypeId,
        isUploaded: false,
        isUploading: false,
        isError: true
      });
      this.updateFileAtIndex(uploadedFiles, uploadedFiles.documentTypeId);

      if (data == HttpStatusCode.Conflict) {
        this.isExistDocumentOtherName = true;
      }
    }

    if (data.type === HttpEventType['UploadProgress']) {
      const uploadedFile: UploadedFile = Object.assign({}, file, {
        isUploaded: false,
        isUploading: true
      });

      var uploadedFileIndex = this.updateFileAtIndex(uploadedFile, file.id);

      this.percentageValueHandler(data, uploadedFileIndex);
    } else if (data.type === HttpEventType['Response']) {
      const uploadedFile: ProductUploadFile = Object.assign({}, file, {
        id: data.body.documentSqlId,
        isUploaded: true,
        path: data.body.path,
        isUploading: false
      });

      this.updateUploadedDocumentStatus(this.documentType['id'].toString());
      this.updateFileAtIndex(uploadedFile, uploadedFile.documentTypeId);

      if (!this.isOtherDocumentType(this.documentType.name)) {
        this.changeFileTypeStatus(
          '',
          this.documentType['id'].toString(),
          true,
          false
        );
      }

      this.documentType = null;
      this.disableAnyAction = false;
      this.isExistDocumentOtherName = false;
      this.resetUploadDocumentsForm();
    }
  }
  updateFileAtIndex(file: any, id: number): number {
    const FileIndex = this.uploadedFiles.findIndex(x => x.id === id);

    if (FileIndex < 0) {
      this.uploadedFiles.push(file);
    } else {
      this.uploadedFiles[FileIndex] = file;
    }

    return FileIndex;
  }

  private UploadDocument(uploadedFile: ProductUploadFile) {
    this.fileUploadingSubject.next(false);
    this.documentService
      .Upload(this.formData)
      .pipe(
        takeUntil(this.fileUploadingSubject.pipe(filter((v: boolean) => v)))
      )
      .subscribe(
        (data: HttpResponse<ProductDocument>) => {
          this.disableAnyAction = true;
          this.handleProgress(data, uploadedFile);
          this.validateIsAllProductCompatibleDocumentsUploaded();
        },
        error => {
          this.handleProgress(error, uploadedFile, true);
          uploadedFile.isError = true;
          uploadedFile.isUploading = false;
          this.disableAnyAction = false;
        }
      );
  }
  private formatDate(date: any): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd');
  }
  private validateIsAllProductCompatibleDocumentsUploaded() {
    let res = this.ProductCompatibleDocuments.every(type =>
      this.isTypeUploaded(type.documentName, this.uploadedFiles)
    );
    if (res == true) {
      this.isAllDocumentUploaded.emit(true);
    } else {
      this.isAllDocumentUploaded.emit(false);
    }
  }
  private isTypeUploaded(
    type: string,
    productDocument: UploadedFile[]
  ): boolean {
    return productDocument.some(s => s.typeName == type);
  }
  private AddDocumentIdForUploadFile(document: ProductDocument) {
    this.uploadedFiles.find(d => d.fileName == document.fileName).id =
      document.id;
  }
  excludeRegistrationLicenseDocumentType() {
    this.documentTypes = this.documentTypes.filter(item => {
      return item.name != DocumentTypeEnum.RegistrationLicense;
    });
  }

  resetExpirationDate() {
    if (this.isNonExpiring || this.isLinkExistingDocument) {
      this.form.get('expirationDate')?.reset();
    }
  }

  addOrClearRequiredValidatorToExpirationDate() {
    this.addOrClearRequiredValidator(
      'expirationDate',
      this.isNonExpiring || this.isLinkExistingDocument
    );
  }

  addOrClearRequiredValidator(formControlName: string, isClear: boolean) {
    if (isClear) {
      this.formsHelperService.clearValidator(this.form, formControlName);
      return;
    }
    this.formsHelperService.addRequiredValidator(this.form, formControlName);
  }

  onChangeFileUploadMethod(isLinkExistingDocument: boolean) {
    this.isLinkExistingDocument = isLinkExistingDocument;
    this.getProductsWithRecentlyUploadedDocumentType();
    this.loadProductsWithSelectedDocumentType();
    this.listenToChanges();
    this.addOrClearRequiredValidatorToExpirationDate();
    this.addOrClearRequiredValidator('file', isLinkExistingDocument);
    this.addOrClearRequiredValidator(
      'existingDocument',
      !isLinkExistingDocument
    );
  }

  getProductsWithRecentlyUploadedDocumentType() {
    if (!this.documentTypeNameFormat) {
      return;
    }
    this.isLoading = true;
    this.createProductService
      .getProductsWithRecentlyUploadedDocumentType(this.documentTypeNameFormat)
      .subscribe(res => {
        this.existingDocuments = res;
        this.isLoading = false;
      });
  }

  loadProductsWithSelectedDocumentType() {
    this.existingDocumentsObservable = concat(
      of([]),
      this.existingDocuments$.pipe(
        filter(res => {
          return (
            res !== null &&
            res.length >= 2 &&
            this.documentTypeNameFormat != null &&
            this.documentTypeNameFormat != ''
          );
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(_ => (this.isLoading = true)),
        switchMap(textOfSearch => {
          return this.createProductService
            .getProductsWithDocumentTypeAndPartNumber(
              this.documentTypeNameFormat,
              textOfSearch
            )
            .pipe(
              catchError(() => of([])),
              tap((value: ProductWithSimilarDocumentType[]) => {
                this.isLoading = false;
                this.existingDocuments = value;
              })
            );
        })
      )
    );
  }

  listenToChanges() {
    this.existingDocumentsObservable.subscribe(res => {
      this.existingDocuments = res;
    });
  }

  addExistingDocumentToProduct() {
    let product = this.form.get('existingDocument')?.value as ProductDocument;
    this.createProductService
      .addExistingDocumentToProduct(this.parentTypeId, product)
      .subscribe(res => {
        this.updateUploadedDocumentStatus(res?.documentTypeId.toString());
        let uploadedFile = this.convertProductDocumentToProductUploadFile(res);
        this.updateFileAtIndex(uploadedFile, res?.documentTypeId);
        this.validateIsAllProductCompatibleDocumentsUploaded();
        this.resetUploadDocumentsForm();
      });
  }

  convertProductDocumentToProductUploadFile(
    document: ProductDocument
  ): ProductUploadFile {
    let uploadedFile: ProductUploadFile = {
      expirationDate: document?.expirationDate,
      typeName: document?.documentTypeName,
      fileName: document?.fileName,
      path: document?.path,
      id: document?.documentSqlId,
      isUploaded: true,
      isUploading: false,
      isCanceled: false,
      isError: false,
      note: document?.notes,
      documentTypeId: document?.documentTypeId
    };
    return uploadedFile;
  }
}
