import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-action-header',
  templateUrl: './page-action-header.component.html',
  styleUrls: ['./page-action-header.component.scss']
})
export class PageActionHeader implements OnInit {
  @Input() placeholder: string;
  @Input() addLabel: string;
  @Input() addUrl: string;
  @Input() canAdd: boolean;
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAddClick: EventEmitter<string> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onSearchChanged(value: string) {
    this.searchChanged.emit(value);
  }
  addClick() {
    this.onAddClick.emit();
  }
}
