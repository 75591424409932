<app-page-header
  [title]="'config.management.District.Manage' | translate"
></app-page-header>

<app-page-action-header
  placeholder="{{ 'config.management.District.Search' | translate }}"
  addLabel="{{ 'config.management.District.New' | translate }}"
  [addUrl]="'./add'"
  [canAdd]="canCreateDistricts"
  (searchChanged)="onSearchChanged($event)"
></app-page-action-header>
<ngx-datatable
  class="material colored-header sm table table-striped table-hover mt-5"
  [loadingIndicator]="loadingIndicator"
  [rows]="rows"
  [rowHeight]="35"
  [headerHeight]="35"
  [footerHeight]="35"
  [columns]="columns"
  [scrollbarV]="true"
  [columnMode]="'force'"
>
</ngx-datatable>

<ng-template #indexTemplate let-value="value">
  <strong>{{ value }}</strong>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="id">
  <div>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      [routerLink]="['./' + row.id + '/edit']"
      *ngIf="canUpdateDistricts"
    ></svg-icon>
    <svg-icon
      class="action-btn cursor-pointer pr-2"
      name="delete-circle"
      (click)="deleteDistrict(row)"
      *ngIf="canDeleteDistricts"
    ></svg-icon>
  </div>
</ng-template>
