import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ApprovalLeadTime } from '../../../models/approval-lead-time.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ApprovalActivity } from '../../../models/approval-activity.model';
import { ApprovalActivityService } from '../../../services/approval-activity.service';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { FormsHelperService } from '../../../../shared/services/forms-helper.service';
import { ApprovalKpiService } from '../../../services/approval-kpi.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';

@Component({
  selector: 'app-add-or-edit-approval-kpis',
  templateUrl: './add-or-edit-approval-kpis.component.html',
  styleUrls: ['./add-or-edit-approval-kpis.component.scss']
})
export class AddOrEditApprovalKpisComponent implements OnInit {
  approvalLeadTimeForm: UntypedFormGroup;
  approvalLeadTimeModel: ApprovalLeadTime;
  isNewApprovalLeadTime: boolean = false;
  activities: ApprovalActivity[];
  endTriggers: ApprovalActivity[] = [];
  activityForm: UntypedFormGroup;

  daysHours: { label: string, value: boolean }[] = [
    { label: 'Days', value: false },
    { label: 'Hours', value: true }
  ];

  isSaving: boolean = false;
  hasErrorMessage = false;
  errorMessage: string = '';

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private activityService: ApprovalActivityService,
    private router: Router,
    private alertService: AlertService,
    private formsHelperService: FormsHelperService,
    private approvalHttpService: ApprovalKpiService,
    private translationService: AppTranslationService,
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.approvalLeadTimeModel = data['approvalLeadTime'];
    });
    this.loadApprovalActivities();
    this.initForm();
  }

  initForm() {
    this.isNewApprovalLeadTime = !this.approvalLeadTimeModel ? true : false;

    this.approvalLeadTimeModel =
      this.approvalLeadTimeModel || ({} as ApprovalLeadTime);

    this.approvalLeadTimeForm = this.fb.group({
      name: [this.approvalLeadTimeModel?.name, [Validators.required]],
      leadTime: [
        this.approvalLeadTimeModel?.leadTime,
        [Validators.required, Validators.min(1)]
      ],
      startTriggerId: [
        this.approvalLeadTimeModel?.startTriggerId,
        [Validators.required]
      ],
      endTriggerId: [
        this.approvalLeadTimeModel?.endTriggerId,
        [Validators.required]
      ],
      daysHours: [this.approvalLeadTimeModel?.isHours ? true : false, Validators.required]
    });

  }

  loadApprovalActivities() {
    this.activityService.getAll().subscribe(res => {
      this.activities = res;
      this.onChangingStartTrigger();
    });
  }

  onChangingStartTrigger(event?: any) {
    let startTriggerId = this.approvalLeadTimeForm.get('startTriggerId')?.value;
    this.endTriggers = this.activities.filter(a => a.id != startTriggerId);
    if (event) {
      this.approvalLeadTimeForm.get('endTriggerId')?.setValue(null);
    }
  }


  isInvalidFormControl(controlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(this.approvalLeadTimeForm, controlName);
  }

  save(): void {
    this.isSaving = true;
    const formVal = this.approvalLeadTimeForm.value;
    this.assignIsHoursValue(formVal);

    if (!this.approvalLeadTimeForm.valid) {
      this.isSaving = false;
      this.alertService.error('Invalid Form!');
      return;
    }

    formVal.isActive = true;

    if (this.isNewApprovalLeadTime) {
      this.addNewRequest(formVal);
    } else {
      this.updateRequest(formVal);
    }
  }

  assignIsHoursValue(formVal): void {
    formVal.isHours = formVal.daysHours;
  }

  addNewRequest(formVal: any): void {
    this.approvalHttpService.addApprovalLeadTime(formVal).subscribe(
      {
        next: () => {
          this.isSaving = false;
          this.router.navigate(['/settings/approval-kpis']);
          this.alertService.success('Approval KPI was Saved Successfully');
        },
        error: err => {
          this.isSaving = false;
          this.hasErrorMessage = true;
          this.errorMessage =
            err || 'An error occurred while Saving the Approval KPI';
        }
      }
    );
  }

  updateRequest(formVal: any): void {
    this.approvalHttpService.updateApprovalLeadTime(this.approvalLeadTimeModel.id, formVal)
      .subscribe(
        {
          next: () => {
            this.isSaving = false;
            this.router.navigate(['/settings/approval-kpis']);
            this.alertService.success('Approval KPI was Updated Successfully');
          },
          error: err => {
            this.isSaving = false;
            this.hasErrorMessage = true;
            this.errorMessage =
              err || 'An error occurred while Updating the Approval KPI';
          }
        }
      );
  }

  cancel() {
    this.alertService.showMessage(
      'Cancelled',
      'Operation cancelled by user',
      MessageSeverity.default
    );
    this.router.navigate(['/settings/approval-kpis']);
  }

  get isFormDisabled(): boolean {
    return this.approvalLeadTimeForm?.invalid;
  }
}
