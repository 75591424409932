<div
  class="upload-container text-center position-relative w-100 cursor-pointer"
  *ngIf="!selectedFile && !url"
>
  <input
    type="file"
    [id]="name"
    class="file-input"
    [accept]="allowedTypes.length ? allowedTypes.join(',') : 'image/*'"
    (change)="onFileSelected($event)"
  />

  <label
    [for]="name"
    class="d-flex flex-column align-items-center justify-content-center cursor-pointer"
  >
    <div class="upload-icon">
      <svg-icon name="upload-icon"></svg-icon>
    </div>
    <span class="upload-text">{{ uploadText }}</span>
    <span class="upload-specification-text">{{ uploadSpecificationText }}</span>
  </label>
</div>

<div
  class="file-container d-flex justify-content-between align-items-center"
  *ngIf="selectedFile || url"
>
  <div>
    <a
      class="file-name cursor-pointer text-underline"
      (click)="navigateToPreview()"
      target="_blank"
      *ngIf="!url"
    >
      {{ shortenFileName(selectedFile?.name, 20) }}
    </a>
    <a
      class="file-name cursor-pointer text-underline"
      [href]="url"
      target="_blank"
      *ngIf="url"
    >
      {{ shortenFileName(url.split("/")[4], 20) }}
    </a>
    <div class="file-size" *ngIf="!url">{{ fileSize }} KB</div>
  </div>

  <svg-icon
    name="trash-icon"
    class="cursor-pointer"
    (click)="clearFile()"
  ></svg-icon>
</div>
