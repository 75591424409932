import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  logo = 'assets/images/PNG/FreePL_Logo.png';
  theme: { [key: string]: string } = {};
  constructor(private route: ActivatedRoute, private authService: AuthService) {
    this.initTheme();
  }

  ngOnInit(): void {}

  initTheme() {
    this.theme = this.route.snapshot.data['theme'];
    if (this.theme?.logo) {
      this.logo = this.theme?.logo;
    }

    if (this.theme?.primaryBrandColor && this.theme?.secondBrandColor) {
      document.documentElement.style.setProperty(
        '--primary',
        this.theme?.primaryBrandColor
      );
      document.documentElement.style.setProperty(
        '--header-bg',
        this.theme?.secondBrandColor
      );
    }
    if (this.theme?.favicon) {
      (document.getElementById(
        'tab-favicon'
      ) as HTMLLinkElement).href = this.theme?.favicon;
    }

    this.authService.setTenantData({
      '--primary': this.theme?.primaryBrandColor,
      '--header-bg': this.theme?.secondBrandColor,
      logo: this.theme?.logo,
      favicon: this.theme?.favicon,
      name: this.theme?.name
    });
  }
}
