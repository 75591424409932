<div class="row p-3">
  <app-page-header
    class="col-sm-8 col-md-6 col-lg-8 col-xl-9 pl-0"
    [title]="
      (isNewShipmentLeadTime
        ? 'config.management.ShipmentLeadTime.New'
        : 'config.management.ShipmentLeadTime.Edit'
      ) | translate
    "
  ></app-page-header>

  <span class="col-sm-4 col-md-6 col-lg-4 col-xl-3 pl-0 mandatory-field">
    (<span class="required-text ">*</span>) means that this field is mandatory
  </span>

  <div class="mb-3 col-12">
    <app-error-badge
      *ngIf="hasErrorMessage"
      [message]="errorMessage"
    ></app-error-badge>
  </div>

  <div class="col-lg-7 col-md-10 col-sm-10 Card pb-1 pl-0">
    <form [formGroup]="shipmentLeadTimeForm" id="shipmentLeadTime">
      <div class="form-group">
        <label for="name" class="col-form-label"
          >{{ "config.management.ShipmentLeadTime.KpiName" | translate
          }}<span class="required-text">*</span></label
        >
        <input
          formControlName="name"
          type="text"
          class="form-control"
          name="txt-name"
          [ngClass]="{
            error: isInvalidFormControl('name')
          }"
        />
      </div>
      <div class="form-group">
        <label for="StartTrigger" class="col-form-label">
          {{ "config.management.ShipmentLeadTime.StartTrigger" | translate
          }}<span class="required-text">*</span>
        </label>
        <ng-select
          formControlName="startTriggerId"
          class="ddl-height"
          name="ddl-StartTrigger"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [searchable]="false"
          [clearable]="false"
          [items]="activities"
          (change)="onChangingStartTrigger($event)"
          [ngClass]="{
            'ddl-error': isInvalidFormControl('startTriggerId')
          }"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-item$="item$"
          >
            <div class="d-flex align-items-center">
              <mat-radio-button [value]="item" [checked]="item$.selected">
              </mat-radio-button>
              <span class="mx-1"></span>
              <span class="title">
                {{ item.name }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group">
        <label for="endTriggerId" class="col-form-label">
          {{ "config.management.ShipmentLeadTime.EndTrigger" | translate
          }}<span class="required-text">*</span>
        </label>
        <ng-select
          formControlName="endTriggerId"
          class="ddl-height"
          name="ddl-endTriggerId"
          [bindLabel]="'name'"
          [bindValue]="'id'"
          [searchable]="false"
          [clearable]="false"
          [items]="endTriggers"
          [ngClass]="{
            'ddl-error': isInvalidFormControl('endTriggerId')
          }"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-item$="item$"
          >
            <div class="d-flex align-items-center">
              <mat-radio-button [value]="item" [checked]="item$.selected">
              </mat-radio-button>
              <span class="mx-1"></span>
              <span class="title">
                {{ item.name }}
              </span>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="row">
        <div class="form-group col-6">
          <label for="leadTime" class="col-form-label">
            {{ "config.management.ShipmentLeadTime.LeadTime" | translate }}
            <span class="required-text">*</span>
          </label>
          <div class="row ml-0 mr-0">
            <input
              class="form-control col-md-8 col-lg-8 col-xl-9 right-combined-field"
              formControlName="leadTime"
              id="txt-leadTime"
              mask="0*"
              [ngClass]="{
                error: isInvalidFormControl('leadTime')
              }"
            />
            <ng-select
              formControlName="daysHours"
              class="ddl-height pr-0 pl-0 col-md-4 col-lg-4 col-xl-3 left-combined-field"
              name="ddl-daysHours"
              [bindLabel]="'label'"
              [bindValue]="'value'"
              [searchable]="false"
              [clearable]="false"
              [items]="daysHours"
              [ngClass]="{
                'ddl-error': isInvalidFormControl('daysHours')
              }"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-item$="item$"
              >
                <div class="d-flex align-items-center">
                  <mat-radio-button
                    class="radio-button"
                    [checked]="item$.selected"
                  >
                  </mat-radio-button>
                  <span class="mx-1"></span>
                  <span class="title">
                    {{ item.label }}
                  </span>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="form-group col-6 ">
          <label for="kpiCategory" class="col-form-label">
            {{ "config.management.ShipmentLeadTime.KPICategory" | translate
            }}<span class="required-text">*</span>
          </label>
          <ng-select
            class="ddl-height"
            formControlName="kpiCategoryId"
            name="ddl-kpiCategory"
            [bindLabel]="'name'"
            [bindValue]="'id'"
            [searchable]="false"
            [clearable]="false"
            [items]="kpiCategories"
            [ngClass]="{
              'ddl-error': isInvalidFormControl('kpiCategoryId')
            }"
          >
            <ng-template
              ng-option-tmp
              let-item="item"
              let-index="index"
              let-item$="item$"
            >
              <div class="d-flex align-items-center">
                <mat-radio-button [value]="item" [checked]="item$.selected">
                </mat-radio-button>
                <span class="mx-1"></span>
                <span class="title">
                  {{ item.name }}
                </span>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="btn-container">
  <button class="btn btn-cancel" (click)="cancel()">
    {{ "config.management.ShipmentLeadTime.Editor.Cancel" | translate }}
  </button>
  <button
    class="btn btn-save"
    (click)="save()"
    [disabled]="isSaving || isFormDisabled"
  >
    {{
      isSaving
        ? ("config.management.ShipmentLeadTime.Editor.Saving" | translate)
        : ("config.management.ShipmentLeadTime.Editor.Save" | translate)
    }}
  </button>
</div>
