import { ServiceTypes } from '../models/shipment/service-types.enum';

export const QuoteServicesClass: any[] = [
  {
    service: ServiceTypes.Freight,
    title: 'Freight',
    description: 'We will transport your products anywhere across the world.',
    errorMessage: 'Select at least one mode of transportation.',
    image: 'assets/images/PNG/choose-service-freight/1x.png'
  },
  {
    service: ServiceTypes.ImportExport,
    title: 'IOR/EOR',
    description:
      'Take your goods through the required import and export procedures.',
    errorMessage: 'Pick one of the two options either IOR or EOR',
    image: 'assets/images/PNG/choose-service-eor-ior/1x.png'
  },
  {
    service: ServiceTypes.CustomsClearance,
    title: 'Customs Clearance',
    description:
      'We can facilitate the movement of your goods through the Customs Authority.',
    image: 'assets/images/PNG/choose-service-custom-clearance/1x.png'
  },
  {
    service: ServiceTypes.Trucking,
    title: 'Trucking',
    description:
      'We will help transport your products locally using the truck type most suitable to your shipment.',
    errorMessage:
      'Pick one of the two options either Door to Port or Port to Door',
    image: 'assets/images/PNG/choose-service-trucking/1x.png'
  }
];
