import { Component, OnInit } from '@angular/core';
import { ShipmentDetails } from '../../models/shipment-details.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ShipmentsService } from '../../services/shipments.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-leadtimes-pdf',
  templateUrl: './leadtimes-pdf.component.html',
  styleUrls: ['./leadtimes-pdf.component.scss']
})
export class LeadtimesPdfComponent implements OnInit {
  shipmentNumber: string;
  shipmentDetails: ShipmentDetails;
  logoUrl = 'assets/images/PNG/FreePL_Logo.png';
  constructor(
    private authService: AuthService,
    private shipmentService: ShipmentsService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    if (this.authService.tenantLogo) {
      this.logoUrl = this.authService.tenantLogo;
    }

    this.shipmentService
      .getShipmentDetails(
        this.route.snapshot.queryParams.shipmentId,
        this.route.snapshot.queryParams.companyId
      )
      .subscribe(details => {
        this.shipmentDetails = details;
        this.shipmentNumber = details?.shipment?.freePlShipmentNumberString;
        this.titleService.setTitle(`${this.shipmentNumber} - Lead Times`);
        setTimeout(() => {
          window.print();
          window.close();
        }, 500);
      });
  }
}
