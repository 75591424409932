<div class="shipment-activity-log-container" #componentElement>
  <div class="activity-log-date-container row mx-0">
    <div class="pointGroup">
      <div
        class="line position-absolute"
        #lineElement
        *ngIf="isDisplayProgressLine"
        [style.height.px]="componentHeight"
      >
        <div
          class="subLine bg-transparent"
          [style.height.px]="componentHeight"
        ></div>
      </div>
      <div class="timelinePoint">
        <div
          class="point w-100 h-100"
          [ngClass]="{ 'upcoming-events': activityLogDate == null }"
        >
          <div class="subPoint w-100 h-100 bg-white"></div>
        </div>
      </div>
    </div>
    <span class="activity-log-date" *ngIf="activityLogDate != null">
      {{ activityLogDate | date: "MMMM dd, yyyy" }}
    </span>
    <span
      class="activity-log-date upcoming-events"
      *ngIf="activityLogDate == null"
    >
      Upcoming Events
    </span>
  </div>

  <div class="activity-log-container d-flex flex-column">
    <div class="headers">
      <div class="label light"></div>
      <div class="label light">
        Estimated (Cairo Time)
      </div>
      <div class="label light">
        Actual (Cairo Time)
      </div>
      <div class="label light" id="not-printed">
        Actions
      </div>
    </div>

    <div>
      <div
        class="content-details"
        *ngFor="let activityLog of activityLogs"
        [ngClass]="{
          delayed:
            isDelayedEstimateDate(activityLog) ||
            (isDelayedActualDate(activityLog) &&
              !isUpcomingActivityLog(activityLog)),
          'upcoming-events': isUpcomingActivityLog(activityLog),
          'ongoing-activity': isOngoingActivityLog(activityLog),
          'pending-action': activityLog?.isPending !== null
        }"
      >
        <div class="activity-content">
          <div
            class="label activity label-padding"
            [ngClass]="{
              delayed:
                isDelayedActivityLog(activityLog) &&
                !isUpcomingActivityLog(activityLog),
              upcoming: isUpcomingActivityLog(activityLog)
            }"
          >
            <span *ngIf="activityLog?.isPending === false" class="resolved"
              >Resolved -
            </span>
            {{ activityLog?.activityName }}
            <span
              *ngIf="activityLog.revertAction"
              [ngbPopover]="reasonTemplate"
              triggers="mouseenter:mouseleave"
              popoverClass="info-popover"
            >
              <svg-icon [name]="'info'"></svg-icon>
            </span>
            <ng-template #reasonTemplate>
              <app-action-author
                *ngIf="activityLog.revertAction"
                [autherName]="activityLog?.userFullName"
                [companyName]="activityLog?.userCompanyName"
                [reason]="activityLog?.reason"
                [date]="activityLog.createdAt"
                [actionLabel]="'Changed'"
              ></app-action-author>
            </ng-template>
          </div>
          <div
            class="label label-padding"
            [ngClass]="{
              upcoming: isUpcomingActivityLog(activityLog),
              delayed:
                isDelayedActivityLog(activityLog) &&
                !isUpcomingActivityLog(activityLog),
              'cursor-pointer delayed-time': isDelayedEstimateDate(activityLog)
            }"
            [ngbPopover]="delayedEstimatesTemplate"
            triggers="mouseenter:mouseleave"
            [disablePopover]="!isDelayedEstimateDate(activityLog)"
          >
            <span
              *ngIf="
                activityLog?.estimatedDate &&
                (!activityLog?.revertAction ||
                  !isRevertActivityLog(activityLog))
              "
            >
              {{ activityLog?.estimatedDate | date: "dd/MM/yyyy hh:mm a" }}

              <span
                *ngIf="
                  activityLog?.actualDate ||
                  !isActivityLogFromLeadTime(activityLog)
                "
              >
                -
                {{
                  isTrackingActivityLog(activityLog)
                    ? "FreePL TrackMate"
                    : getUserTruncatedName(activityLog?.userFullName) +
                      ", " +
                      activityLog?.userCompanyName
                }}</span
              >
            </span>
            <ng-template #delayedEstimatesTemplate>
              <span>Previous Estimates:</span>
              <div *ngFor="let estimate of activityLog?.delayedEstimates">
                {{ estimate | utcToAfricaCairo }}
              </div>
            </ng-template>
          </div>
          <div
            class="label label-padding"
            [ngClass]="{
              upcoming: isUpcomingActivityLog(activityLog),
              delayed:
                isDelayedActivityLog(activityLog) &&
                !isUpcomingActivityLog(activityLog),
              'cursor-pointer delayed-time': isDelayedActualDate(activityLog)
            }"
            [ngbPopover]="delayedActualsTemplate"
            triggers="mouseenter:mouseleave"
            [disablePopover]="!isDelayedActualDate(activityLog)"
          >
            <span
              *ngIf="
                activityLog?.actualDate &&
                (!activityLog?.revertAction ||
                  !isRevertActivityLog(activityLog))
              "
            >
              {{ activityLog?.actualDate | date: "dd/MM/yyyy hh:mm a" }} -
              {{
                isTrackingActivityLog(activityLog)
                  ? "FreePL TrackMate"
                  : getUserTruncatedName(activityLog?.userFullName) +
                    ", " +
                    activityLog?.userCompanyName
              }}
            </span>
            <ng-template #delayedActualsTemplate>
              <span>Previous Actuals:</span>
              <div *ngFor="let estimate of activityLog?.delayedActuals">
                {{ estimate | utcToAfricaCairo }}
              </div>
            </ng-template>
          </div>
          <div id="not-printed">
            <svg-icon
              name="undo-blue"
              class="cursor-pointer undo-btn"
              *ngIf="
                canUndoActivityLog(activityLog) &&
                activityLog?.isPending === null
              "
              (click)="onShipmentUndo()"
            ></svg-icon>
            <svg-icon
              name="check-success"
              class="cursor-pointer "
              *ngIf="activityLog?.isPending"
              (click)="markCommentAsFulfilled(activityLog)"
            ></svg-icon>
          </div>
        </div>
        <div
          class="activity-details"
          *ngIf="isTrackingActivityLog(activityLog)"
        >
          <div
            class="label details-label cursor-pointer"
            triggers="mouseenter:mouseleave"
            [ngbPopover]="activityLocationTemplate"
            placement="bottom-left"
          >
            {{ activityLog?.portName }}
            <ng-template #activityLocationTemplate>
              <span>{{ activityLog?.city }}, {{ activityLog?.country }}</span>
            </ng-template>
          </div>
          <div
            class="label details-label cargo-details"
            *ngIf="
              isAirTrackingActivity(activityLog) ||
              isOceanTrackingActivity ||
              hasDHLPieces(activityLog)
            "
          >
            <span
              class="cursor-pointer"
              [ngbPopover]="cargoDetailsTemplate"
              triggers="mouseenter:mouseleave"
              placement="bottom-left"
            >
              Cargo Details
            </span>
            <ng-template #cargoDetailsTemplate>
              <div *ngIf="isOceanTrackingActivity">
                <span>Containers:</span>
                <div *ngFor="let container of oceanContainersNumbers">
                  {{ container }}
                </div>
              </div>
              <div *ngIf="isAirTrackingActivity(activityLog)">
                {{ getAirActivityPackageDetails(activityLog) }}
              </div>
              <div *ngIf="hasDHLPieces(activityLog)">
                <span>Pieces:</span>
                <div *ngFor="let pieceId of getDHLPiecesIds(activityLog)">
                  {{ pieceId }}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
