import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ProductRegistrationLeadTime } from 'src/app/admin-portal-configs/models/product-registration-lead-time.model';
import { ProductRegistrationKpisService } from 'src/app/admin-portal-configs/services/product-registration-kpis.service';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Utilities } from 'src/app/shared/services/utilities';

@Component({
  selector: 'app-product-registration-kpis-list',
  templateUrl: './product-registration-kpis-list.component.html',
  styleUrls: ['./product-registration-kpis-list.component.scss']
})
export class ProductRegistrationKpisListComponent implements OnInit {
  columns: any[] = [];
  rows: ProductRegistrationLeadTime[] = [];
  rowsCache: ProductRegistrationLeadTime[] = [];
  loadingIndicator: boolean;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;
  @ViewChild('leadTimeNameTemplate', { static: true })
  leadTimeNameTemplate: TemplateRef<any>;
  @ViewChildren('leadTimeInfoPopover') leadTimeInfoPopover: QueryList<
    NgbPopover
  >;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private productRegistrationKpisService: ProductRegistrationKpisService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(data => {
      var productRegistrationLeadTimes = data['productRegistrationLeadTimes'];
      this.rowsCache = [...productRegistrationLeadTimes];
      this.rows = productRegistrationLeadTimes;
    });

    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'name',
        cellTemplate: this.leadTimeNameTemplate,
        name: gT('config.management.ShipmentLeadTime.KpiName'),
        width: 340
      },
      {
        prop: 'leadTime',
        name: gT('config.management.ShipmentLeadTime.LeadTime'),
        width: 560
      }
    ];
    if (
      this.canUpdateProductRegistrationKPI ||
      this.canDeleteProductRegistrationKPI
    ) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.ShipmentLeadTime.Actions'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteProductLeadTime(row: ProductRegistrationLeadTime) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' +
        row.name +
        '" product registration lead time ?',
      DialogType.confirm,
      () => this.deleteProductLeadTimeHelper(row)
    );
  }

  deleteProductLeadTimeHelper(row: ProductRegistrationLeadTime) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.productRegistrationKpisService.deleteLeadTime(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the product registration lead time.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  get canCreateProductRegistrationKPI(): boolean {
    return this.authService.canCreateProductRegistrationKPI;
  }

  get canUpdateProductRegistrationKPI(): boolean {
    return this.authService.canUpdateProductRegistrationKPI;
  }

  get canDeleteProductRegistrationKPI(): boolean {
    return this.authService.canDeleteProductRegistrationKPI;
  }

  
  togglePopover(popover: NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
  }

  closePopover(popover: NgbPopover): void {
    if (popover.isOpen()) {
      popover.close();
    }
  }

}
