import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true // Allows multiple providers for NG_VALUE_ACCESSOR
    }
  ]
})
export class FileUploadComponent implements OnInit, ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() name: string;
  @Input() allowedTypes: string[] = [];
  @Input() maxSizeKB?: number;
  @Input() uploadText: string;
  @Input() uploadSpecificationText: string;
  selectedFile: File | null = null;
  fileSize: string;
  url: string;
  constructor() {}

  ngOnInit(): void {
    if (this.formControl.value instanceof File) {
      this.selectedFile = this.formControl.value;
    } else {
      this.url = this.formControl.value;
    }
  }
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      if (
        this.allowedTypes.length > 0 &&
        !this.allowedTypes.includes(file.type)
      ) {
        alert(`Allowed file types: ${this.allowedTypes.join(', ')}`);
        return;
      }
      if (this.maxSizeKB && file.size > this.maxSizeKB * 1024) {
        alert(`File size must be ${this.maxSizeKB} KB or less.`);
        return;
      }
      this.selectedFile = file;
      this.formControl.setValue(file);

      const sizeInBytes = file.size;
      this.fileSize = (sizeInBytes / 1024).toFixed(2);
    }
  }

  clearFile() {
    this.selectedFile = null;
    this.url = null;
    this.formControl.setValue(null);
  }

  navigateToPreview(): void {
    if (this.selectedFile) {
      const fileURL = URL.createObjectURL(this.selectedFile);
      window.open(fileURL, '_blank');
    }
  }

  shortenFileName(fileName: string, maxLength: number): string {
    const lastDot = fileName.lastIndexOf('.');
    return lastDot > 0 && fileName.length > maxLength
      ? fileName.slice(0, maxLength - (fileName.length - lastDot)) +
          '..' +
          fileName.slice(lastDot)
      : fileName;
  }
}
