import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { Currency } from '../../../../shared/models/currency.model';
import { CurrencyService } from '../../../services/currency.service';
import { AddEditCurrencyComponent } from '../add-edit-currency/add-edit-currency.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styles: []
})
export class CurrencyListComponent implements OnInit {
  columns: any = [];
  currencyRows: Currency[] = [];
  currencyRowsCache: Currency[] = [];
  loadingIndicator: boolean;
  editingCurrencyName: { name: string };
  sourceCurrency: Currency;
  editedCurrency: Currency;

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  constructor(
    private currencyService: CurrencyService,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.currencyService.currenciesUpdated.subscribe(result => {
      this.updateCurrenciesRows(result);
    });

    this.currencyService.currenciesAdded.subscribe(result => {
      this.pushCurrencyAdded(result);
    });

    this.initiateTable();
    this.fetchCurrencies();
  }

  initiateTable() {
    const gT = (key: string) => this.translationService.getTranslation(key);
    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      {
        prop: 'name',
        name: gT('config.management.Currencies.name'),
        width: 100
      },
      {
        prop: 'symbol',
        name: gT('config.management.Currencies.Symbol'),
        width: 100
      },
      {
        prop: 'code',
        name: gT('config.management.Currencies.Code'),
        width: 100
      },
      {
        prop: 'egpExchangeRate',
        name: gT('config.management.Currencies.ExchangeRate'),
        width: 100
      }
    ];
    if (this.canUpdateCurrency || this.canDeleteCurrecny) {
      let optionsColumn = {
        prop: 'options',
        name: gT('config.management.Currencies.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      };
      this.columns.push(optionsColumn);
    }
  }

  initiateIndex(currencies: Currency[]) {
    currencies.forEach((currency, index) => {
      (currency as any).index = index + 1;
    });
    return currencies;
  }

  fetchCurrencies() {
    this.route.data.subscribe(
      data => {
        let currencies = data['Currencies'];
        currencies = this.initiateIndex(currencies);
        this.currencyRowsCache = [...currencies];
        this.currencyRows = currencies;
      },
      error => {
        this.changeLoadingStatus(false, '');
        this.showStickyMessage(
          'Load Error',
          'Unable to retrieve currencies from the server.',
          error
        );
      }
    );
  }

  updateCurrenciesRows(currency: Currency) {
    this.currencyRows = this.updateRow(currency, this.currencyRows);
    this.currencyRowsCache = this.updateRow(currency, this.currencyRowsCache);
  }

  updateRow(currency: Currency, currencies: Currency[]) {
    return currencies.map(object => {
      if (object.id === currency.id) {
        return Object.assign(object, currency);
      }
      return object;
    });
  }

  pushCurrencyAdded(currency: Currency) {
    this.currencyRows = [currency, ...this.currencyRows];
    this.currencyRows = this.initiateIndex(this.currencyRows);
    this.currencyRowsCache = [currency, ...this.currencyRowsCache];
    this.currencyRowsCache = this.initiateIndex(this.currencyRowsCache);
  }

  onSearchCurrency(searchTerm: string) {
    this.currencyRows = this.currencyRowsCache.filter(
      r =>
        Utilities.searchArray(searchTerm, false, r.name) ||
        Utilities.searchArray(searchTerm, false, r.code) ||
        Utilities.searchArray(searchTerm, false, r.symbol)
    );
  }

  addOrEditCurrency(currency?: Currency) {
    const dialogRef = this.dialogService.open(
      AddEditCurrencyComponent,
      {
        curreny: currency
      },
      '500px',
      '560px'
    );
  }

  deleteCurrency(currency: Currency) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + currency.name + '" currency?',
      DialogType.confirm,
      () => this.deleteCurrencyHelper(currency)
    );
  }

  deleteCurrencyHelper(currency: Currency) {
    this.changeLoadingStatus(true, 'Deleting...');
    this.currencyService.deleteCurrency(currency.id.toString()).subscribe(
      results => {
        this.changeLoadingStatus(false, '');
        this.currencyRowsCache = this.currencyRowsCache.filter(
          item => item !== currency
        );
        this.currencyRowsCache = this.initiateIndex(this.currencyRowsCache);
        this.currencyRows = this.currencyRows.filter(item => item !== currency);
        this.currencyRows = this.initiateIndex(this.currencyRows);
      },
      error => {
        this.changeLoadingStatus(false, '');
        this.showStickyMessage(
          'Delete Error',
          'An error occured whilst deleting the currency.',
          error
        );
      }
    );
  }

  changeLoadingStatus(loadingStatus: boolean, loadingMessage: string) {
    if (loadingStatus) {
      this.alertService.startLoadingMessage(loadingMessage);
    } else {
      this.alertService.stopLoadingMessage();
    }
    this.loadingIndicator = loadingStatus;
  }

  showStickyMessage(summary: string, details: string, error: any) {
    this.alertService.showStickyMessage(
      summary,
      details + `\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error,
      error
    );
  }

  get canCreateCurrency(): boolean {
    return this.authService.canCreateCurrency;
  }

  get canUpdateCurrency(): boolean {
    return this.authService.canUpdateCurrency;
  }

  get canDeleteCurrecny(): boolean {
    return this.authService.canDeleteCurrency;
  }
}
