import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Supplier } from 'src/app/company/models/supplier.model';
import { SupplierService } from '../services/supplier.service';
import { PagedResult } from 'src/app/shared/models/paged-result';

@Injectable({ providedIn: 'root' })
export class SuppliersListResolver implements Resolve<PagedResult<Supplier>> {
  constructor(private supplierService: SupplierService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PagedResult<Supplier>> {
    return this.supplierService.getSuppliersPaged().pipe(
      catchError(error => {
        return of(null);
      })
    );
  }
}
