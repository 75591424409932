<div class="charges-and-rates-con">
  <button
    *ngIf="canAddShipmentCharge"
    class="add-charge-btn action"
    (click)="showModal()"
  >
    <span>+</span>
    <span>Add New Charge</span>
  </button>
  <div class="charges-and-rates-section">
    <div class="section-title">
      <svg-icon name="servicesAndRates"></svg-icon>
      CHARGES
    </div>
    <ng-container *ngIf="charges?.length > 0; else noChargesBlock">
      <div>
        <div
          *ngFor="let chargeTable of existingChargesTables"
          class="service-charges-table"
        >
          <div class="table-header">
            <div class="col-title service">
              {{ chargeTable.serviceName }} Charges
            </div>
            <div class="col-title rate">Rate</div>
            <div class="col-title quantity">Quantity</div>
            <div class="col-title total-charge">Total Charge</div>
            <div
              id="not-printed"
              *ngIf="canEditShipmentCharge || canDeleteShipmentCharge"
              class="col-title actions"
            >
              Actions
            </div>
          </div>
          <div
            *ngFor="let charge of chargeTable.charges"
            class="table-body-row"
          >
            <div class="col-body service">{{ charge.chargeTypeName }}</div>
            <div class="col-body rate">
              <span *ngIf="charge.hasPercentageRate">
                {{ charge.percentageRate }}
              </span>
              <span *ngIf="!charge.hasPercentageRate">
                {{ charge.rate }} {{ charge.currencyCode || charge.currency }}
              </span>
            </div>

            <div class="col-body quantity">
              <span *ngIf="charge.hasPercentageRate">
                % of {{ charge?.parentCharge?.chargeTypeName }}
              </span>
              <span *ngIf="!charge.hasPercentageRate">
                x {{ charge.quantity }}
              </span>
            </div>
            <div class="col-body total-charge">
              {{ charge.amount }} {{ charge.currencyCode || charge.currency }}
            </div>
            <div
              id="not-printed"
              *ngIf="canEditShipmentCharge || canDeleteShipmentCharge"
              class="col-actions action"
            >
              <span
                *ngIf="!charge.isAutomatic && canEditShipmentCharge"
                class="action-btn edit-btn"
                (click)="showEditChargePopUp(charge)"
              >
                <svg-icon name="editCharge"></svg-icon>
              </span>

              <span
                *ngIf="!charge.isAutomatic && canDeleteShipmentCharge"
                class="action-btn delete-btn"
                (click)="showDeleteChargePopUp(charge)"
              >
                <svg-icon name="shipmentDetailsActionDelete"></svg-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noChargesBlock>
      <div class="noElementsExistsCon">
        <svg-icon name="noItemsIcon"></svg-icon>
        <span>No charges are added yet</span>
      </div>
    </ng-template>
  </div>

  <div class="charges-summary-con" *ngIf="charges?.length > 0">
    <div class="section-title">
      <svg-icon name="chargesSummary"></svg-icon>
      Charges Summary
    </div>

    <div class="charges-summary-section">
      <div *ngFor="let service of existingServices" class="charge-section">
        <div class="charge">{{ service }} Total Charges</div>
        <div class="total-value-per-charge">
          <app-charges-currency-based-total
            [charges]="charges"
            [serviceName]="service"
          ></app-charges-currency-based-total>
        </div>
      </div>

      <div class="total-shipment-charges charge-section">
        <div class="charge">Shipment Total Charges</div>
        <div class="total-value-per-charge">
          <app-charges-currency-based-total
            [charges]="charges"
          ></app-charges-currency-based-total>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex align-items-center gap-4">
  <button class="export-btn" (click)="exportChargesToExcel()">
    <svg-icon name="export-excel"></svg-icon> Export to Excel
  </button>
  <a
    class="export-btn"
    [href]="'charges-pdf?shipmentId=' + shipmentId + '&companyId=' + companyId"
    target="_blank"
    rel="noopener noreferrer"
  >
    <svg-icon name="download-icon"></svg-icon> Download PDF
  </a>
</div>
<ng-template #DGDTemplate>
  <div class="row popOver-field">
    {{ changeServicesButtonDisabledReason }}
  </div>
</ng-template>
