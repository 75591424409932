import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ShipmentsService } from '../../../shipments/services/shipments.service';
import { LeadTimeLog } from 'src/app/shipments/models/lead-time-log.model';
import { ProductRegistrationLeadTimeLog } from 'src/app/product-registration/models/product-registration-lead-time-log.model';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { ApprovalLeadTimeLog } from 'src/app/approvals/models/approval-lead-time-logs.model';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { LeadTimeLogService } from 'src/app/shipments/services/lead-time-log.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-lead-times',
  templateUrl: './lead-times.component.html',
  styleUrls: ['./lead-times.component.scss']
})
export class LeadTimesComponent implements OnInit, OnChanges {
  shipmentId: string;
  companyId: number;
  @Input() shipmentLeadTimes: LeadTimeLog[];
  @Input() approvalLeadTimes: ApprovalLeadTimeLog[];
  @Input() productRegistrationLeadTimes: ProductRegistrationLeadTimeLog[];
  showInput: boolean[] = [];
  insertedNotes: string[] = [];
  leadTimeLogNoteLimit = 20;
  activeModule: string = '';

  constructor(
    private shipmentsServcie: ShipmentsService,
    private approvalService: ApprovalService,
    private productRegistrationService: ProductRegistrationService,
    private leadTimeLogService: LeadTimeLogService,
    private alertService: AlertService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setShipmentAndCompanyId();
  }

  ngOnInit(): void {
    this.setActiveModule();
    this.initNotes();
    this.setShipmentAndCompanyId();
  }

  setShipmentAndCompanyId() {
    this.shipmentId = this.shipmentLeadTimes[0]?.shipmentId;
    this.companyId = this.shipmentLeadTimes[0]?.companyId;
  }

  setActiveModule(): void {
    const pathName = window.location.pathname;
    if (pathName.includes('shipments')) {
      this.activeModule = 'shipments';
    } else if (pathName.includes('registrations')) {
      this.activeModule = 'productRegistration';
    } else if (pathName.includes('approvals')) {
      this.activeModule = 'approvals';
    }
  }

  toggleShowInput(inputIndex: number): void {
    this.showInput[inputIndex] = !this.showInput[inputIndex];
  }

  initNotes(): void {
    for (let i = 0; i < this.leadTimes?.length; i++) {
      this.insertedNotes[i] = this.leadTimes[i]?.notes;
    }
  }

  onNotesChange(leadTimeIndex: number): void {
    let leadTimeId = this.leadTimes[leadTimeIndex]?.id;
    if (this.shipmentLeadTimes?.length > 0) {
      this.leadTimeLogService
        .updateLeadTimeNotes(leadTimeId, this.insertedNotes[leadTimeIndex])
        .subscribe(data => {
          this.toggleShowInput(leadTimeIndex);
        });

      this.shipmentsServcie.leadTimeNotesUpdated.emit({
        leadTimeId: leadTimeId,
        leadTimeNotes: this.insertedNotes[leadTimeIndex]
      });
    } else if (this.productRegistrationLeadTimes?.length > 0) {
      this.productRegistrationService
        .updateProductLeadTimeLogs(
          leadTimeId,
          this.insertedNotes[leadTimeIndex]
        )
        .subscribe(data => {
          this.toggleShowInput(leadTimeIndex);
        });
      this.productRegistrationService.productLeadTimeNotesUpdated.emit({
        leadTimeId: leadTimeId,
        leadTimeNotes: this.insertedNotes[leadTimeIndex]
      });
    } else if (this.approvalLeadTimes?.length > 0) {
      this.approvalService
        .updateLeadTimeNotes(leadTimeId, this.insertedNotes[leadTimeIndex])
        .subscribe(data => {
          this.toggleShowInput(leadTimeIndex);
        });
      this.approvalService.approvalLeadTimeNotesUpdated.emit({
        leadTimeId: leadTimeId,
        leadTimeNotes: this.insertedNotes[leadTimeIndex]
      });
    }
  }

  exportLeadTimesToExcel(): void {
    let shipmentId = this.shipmentLeadTimes[0]?.shipmentId;
    this.leadTimeLogService
      .exportLeadTimeLogsToExcel(shipmentId)
      .subscribe(res => {
        const fileName = res.headers.get('filename');

        const blob = new Blob([res.body], {
          type: res.headers.get('content-type')
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);

        this.alertService.success('Lead time logs successfully exported.');
      });
  }

  get leadTimes():
    | LeadTimeLog[]
    | ProductRegistrationLeadTimeLog[]
    | ApprovalLeadTimeLog[] {
    if (this.shipmentLeadTimes?.length > 0) {
      return this.shipmentLeadTimes;
    } else if (this.productRegistrationLeadTimes?.length > 0) {
      return this.productRegistrationLeadTimes;
    } else if (this.approvalLeadTimes?.length > 0) {
      return this.approvalLeadTimes;
    }

    return [];
  }
}
