export enum AdminSidebarRoutesEnum {
  ShipmentWorkflow = 'settings/activity-transition',
  RegistrationsWorkflow = 'settings/registration-activity-transition',
  ApprovalsWorkflow = 'settings/approval-activity-transitions',
  ShipmentActivities = 'shipment-activities',
  RegistrationActivities = 'settings/registration-activities',
  ApprovalsActivities = 'settings/approval-activities',
  ShipmentStatuses = 'shipment-statuses',
  ShipmentKpis = 'settings/shipment-kpis',
  RegistrationKpis = 'product-registration-kpis',
  ApprovalsKpis = 'settings/approval-kpis',
  ChargeTypes = 'settings/charge-type',
  Currencies = 'settings/currency',
  DocumentTypes = 'settings/document-type',
  HSCodes = 'settings/hs-code',
  Industries = 'settings/industries',
  ProductCategories = 'settings/product-categories',
  Districts = 'settings/districts',
  Cities = 'settings/cities',
  Countries = 'settings/countries',
  Ports = 'settings/ports',
  LandRoutes = 'settings/land-routes',
  Settings = 'settings',
  Carriers = 'settings/carriers',
  Suppliers = 'suppliers',
  OldCustomersProfile = 'customers',
  CustomersProfile = 'customers-profile',
  CompanyProfile = 'company-profile/1/details',
  Plans = 'plans',
  Tenants = 'tenants',
  TruckTypes = 'settings/truck-type'
}
