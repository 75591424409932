import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ShipmentsService } from '../../services/shipments.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ShipmentDetails } from '../../models/shipment-details.model';

@Component({
  selector: 'app-charges-pdf',
  templateUrl: './charges-pdf.component.html',
  styleUrls: ['./charges-pdf.component.scss']
})
export class ChargesPdfComponent implements OnInit {
  logoUrl = 'assets/images/PNG/FreePL_Logo.png';
  shipmentNumber: string;
  shipmentDetails: ShipmentDetails;
  constructor(
    private authService: AuthService,
    private shipmentService: ShipmentsService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    if (this.authService.tenantLogo) {
      this.logoUrl = this.authService.tenantLogo;
    }
    this.shipmentService
      .getShipmentDetails(
        this.route.snapshot.queryParams.shipmentId,
        this.route.snapshot.queryParams.companyId
      )
      .subscribe(details => {
        this.shipmentDetails = details;
        this.shipmentNumber = details?.shipment?.freePlShipmentNumberString;
        this.titleService.setTitle(`${this.shipmentNumber} - Shipment Charges`);
        setTimeout(() => {
          window.print();
          window.close();
        }, 500);
      });
  }
}
