import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusChangeRequest } from 'src/app/shared/models/status-change-request.model';
import { ProductRegistrationService } from '../../../product-registration/services/product-registration.service';
import { AlertService } from '../../services/alert.service';
import { DialogService } from '../../services/dialog.service';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { ShipmentsService } from 'src/app/shipments/services/shipments.service';
import { EntityType } from 'src/app/createShipment/models/enums/entity-type.enum';

@Component({
  selector: 'app-cancel-entity',
  templateUrl: './cancel-entity.component.html',
  styleUrls: ['./cancel-entity.component.scss']
})
export class CancelEntityComponent implements OnInit {
  entityName: string;
  reason: string;
  isLoading: boolean;
  reasonError: boolean;
  minLength: number = 5;
  parentId: string;
  companyId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private productRegistrationService: ProductRegistrationService,
    private alertService: AlertService,
    private dialogService: DialogService,
    private approvalService: ApprovalService,
    private shipmentsService: ShipmentsService
  ) {}

  ngOnInit(): void {
    this.entityName = this.data['entityName'];
    this.parentId = this.data['parentId'];
    this.companyId = this.data['companyId'];
  }

  confirm(isCancel: boolean): void {
    if (this.isLoading || !this.parentId) {
      return;
    }

    if (!isCancel) {
      this.dialogService.close();
      return;
    }

    if (!this.reason || this.reason?.trim()?.length < this.minLength) {
      this.reasonError = true;
      return;
    }

    this.isLoading = true;
    if (this.isProductRegistrationRequestEntity) {
      this.cancelProductRegistration();
    } else if (this.isApprovalEntity) {
      this.cancelApproval();
    } else {
      this.cancelShipment(this.reason);
    }
  }

  cancelProductRegistration(): void {
    let statusChangeRequest: StatusChangeRequest = {
      id: this.parentId,
      reason: this.reason
    };
    this.productRegistrationService
      .cancelProductRegistration(statusChangeRequest)
      .subscribe(() => {
        this.productRegistrationService.productRegistrationRequestUpdated.emit();
        this.alertService.success('Request successfully cancelled.');
        this.dialogService.close(this.reason);
      });
  }

  cancelApproval(): void {
    this.approvalService
      .cancelApproval(this.parentId, this.companyId, this.reason)
      .subscribe(() => {
        this.alertService.success('Approval successfully cancelled.');
        this.approvalService.onApprovalChange.emit(true);
        this.dialogService.close(this.reason);
      });
  }

  cancelShipment(reason: string): void {
    this.shipmentsService
      .cancel(this.parentId, this.companyId, reason)
      .subscribe(() => {
        this.alertService.success('Shipment successfully cancelled.');
        this.shipmentsService.shipmentUpdated.emit();
        this.dialogService.close();
      });
  }

  get isProductRegistrationRequestEntity(): boolean {
    return this.entityName == EntityType.Registration;
  }

  get errorMesage(): string {
    return `Please enter at least 5 characters to submit.`;
  }

  get isApprovalEntity(): boolean {
    return this.entityName == EntityType.Approval;
  }

  get title(): string {
    if (this.isProductRegistrationRequestEntity || this.isApprovalEntity) {
      return 'Request';
    } else {
      return 'Shipment';
    }
  }
}
