import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { ApprovalActivityService } from '../../services/approval-activity.service';
import { ApprovalActivity } from '../../models/approval-activity.model';
import { ActivatedRoute } from '@angular/router';
import {
  AlertService,
  DialogType,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import { Utilities } from 'src/app/shared/services/utilities';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-approval-activity-list',
  templateUrl: './approval-activity-list.component.html',
  styleUrls: ['./approval-activity-list.component.scss']
})
export class ApprovalActivityListComponent implements OnInit {
  columns: any[] = [];
  rows: ApprovalActivity[] = [];
  rowsCache: ApprovalActivity[] = [];
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private approvalActivitiesService: ApprovalActivityService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  @ViewChild('indexTemplate', { static: true })
  indexTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  ngOnInit() {
    this.loadData();
    this.initializeTable();
  }

  initializeTable(): void {
    const gT = (key: string) => this.translationService.getTranslation(key);

    this.columns = [
      {
        prop: 'index',
        name: '#',
        width: 50,
        cellTemplate: this.indexTemplate,
        canAutoResize: false
      },
      { prop: 'name', name: gT('config.management.Name'), width: 360 },
      {
        prop: 'status.name',
        name: gT('config.management.ApprovalActivity.Status'),
        width: 330
      },
      {
        prop: 'systemStatus.name',
        name: gT('config.management.ApprovalActivity.SystemStatus'),
        width: 300
      },
      {
        prop: 'progressPoint.name',
        name: gT('config.management.ApprovalActivity.Progress'),
        width: 300
      },
      {
        prop: 'options',
        name: gT('config.management.ApprovalActivity.Manage'),
        width: 270,
        cellTemplate: this.actionsTemplate,
        resizeable: false,
        canAutoResize: false,
        sortable: false,
        draggable: false
      }
    ];
  }

  loadData(): void {
    this.route.data.subscribe(data => {
      let activities = data['approvalActivities'];

      activities = this.getIndecies(activities);
      this.rowsCache = [...activities];
      this.rows = activities;
    });
  }

  onSearchChanged(value: string) {
    this.rows = this.rowsCache.filter(r =>
      Utilities.searchArray(value, false, r.name)
    );
  }

  deleteApprovalActivity(row: ApprovalActivity) {
    this.alertService.showDialog(
      'Are you sure you want to delete the "' + row.name + '" activity ?',
      DialogType.confirm,
      () => this.deleteApprovalActivityHelper(row)
    );
  }

  deleteApprovalActivityHelper(row: ApprovalActivity) {
    this.alertService.startLoadingMessage('Deleting...');
    this.loadingIndicator = true;

    this.approvalActivitiesService.deleteApprovalActivity(row.id).subscribe(
      results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.rowsCache = this.rowsCache.filter(item => item !== row);

        this.rowsCache = this.getIndecies(this.rowsCache);
        this.rows = this.rowsCache;
      },
      error => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
          'Delete Error',
          `An error occured whilst deleting the activity.\r\nError: "${Utilities.getHttpResponseMessages(
            error
          )}"`,
          MessageSeverity.error,
          error
        );
      }
    );
  }

  getIndecies(array: any) {
    array.forEach((item, index) => {
      (item as any).index = index + 1;
    });

    return array;
  }

  hasCategory(activity: ApprovalActivity): boolean {
    return activity?.approvalActivityCategory?.name ? true : false;
  }

  get canDeleteApprovelActivity():boolean{
    return this.authService.canDeleteApprovalActivities;
  }
  
   get canUpdateApprovelActivity():boolean{
     return this.authService.canUpdateApprovalActivities;
  }

  get canCreateApprovelActivity(): boolean {
     return this.authService.canCreateApprovalActivities;
   }
}
