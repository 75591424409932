<div class="container">
  <app-page-header [title]="'Roles'"></app-page-header>

  <app-page-action-header
    placeholder=" {{ 'roles.management.Search' | translate }}"
    addLabel="{{ 'roles.management.NewRole' | translate }}"
    [canAdd]="canCreateRole"
    [addUrl]="'roles/add'"
    (searchChanged)="onSearchChanged($event)"
  >
  </app-page-action-header>
  <app-add-empty-data-msg
    *ngIf="!rows?.length && !loadingIndicator"
    [boldText]="'roles.management.RoleEmpty' | translate"
    [normalText]="'roles.management.RoleWillAppear' | translate"
  ></app-add-empty-data-msg>

  <ngx-datatable
    *ngIf="rows?.length"
    class="material colored-header sm table table-striped table-hover mt-5"
    [loadingIndicator]="loadingIndicator"
    [rows]="rows"
    [rowHeight]="40"
    [headerHeight]="35"
    [footerHeight]="35"
    [columns]="columns"
    [scrollbarV]="true"
    [columnMode]="'force'"
  >
  </ngx-datatable>
</div>

<ng-template #actionsTemplate let-row="row" let-value="value" let-i="index">
  <div>
    <svg-icon
      *ngIf="canUpdateRole"
      class="action-btn cursor-pointer pr-2"
      name="edit-circle"
      [routerLink]="['roles/' + row.id + '/edit']"
    ></svg-icon>
    <svg-icon
      *ngIf="canDeleteRole"
      class="action-btn cursor-pointer pr-2"
      name="delete-circle"
      (click)="deleteRole(row)"
    ></svg-icon>
  </div>
</ng-template>
