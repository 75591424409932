<div class="action-section">
  <div class="search-filter-container">
    <div class="search-bar">
      <app-admin-search-bar
        class="search-bar d-block"
        [placeholder]="placeholder"
        (searchChange)="onSearchChanged($event)"
      ></app-admin-search-bar>
    </div>
    <div class="p-2 cursor-pointer">
      <svg-icon name="three-lines-filter"></svg-icon>
    </div>
  </div>
  <div>
    <button
      type="submit"
      class="btn add-btn"
      [routerLink]="addUrl"
      *ngIf="canAdd && addUrl"
    >
      <span class="add-btn-title">+ {{ addLabel }}</span>
    </button>
    <button
      type="submit"
      class="btn add-btn"
      (click)="addClick()"
      *ngIf="canAdd && !addUrl"
    >
      <span class="add-btn-title">+ {{ addLabel }}</span>
    </button>
  </div>
</div>
