import { Component, Input, OnInit } from '@angular/core';
import { Stepper } from '../../models/stepper.model';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() steps: Stepper[];

  constructor() {}

  ngOnInit(): void {}
  get stepsBeforeSummary() {
    return this.steps.filter(i => i.name != 'Summary');
  }
}
