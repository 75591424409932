import { HttpEventType } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UploadDocumentPopUpPurpose } from 'src/app/shared/models/enums/upload-document-pop-up-purpose.enum';
import { ShipmentDocument } from 'src/app/shared/models/shipment/shipment-document.model';
import { DocumentService } from 'src/app/shared/services/document.service';
import { UploadedFile } from 'src/app/shared/models/types';
import { DocumentTypeEnum } from 'src/app/shared/models/enums/document-type.enum';
import { DocumentType } from 'src/app/shared/models/document-type.model';
import { DocumentTypeService } from 'src/app/admin-portal-configs/services/document-type.service';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { MatDatePickerHeaderComponent } from '../../mat-date-picker-header/mat-date-picker-header.component';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Router } from '@angular/router';

interface DocumentState {
  id: number;
  name: string;
  uploaded: boolean;
  uploading: boolean;
  nameKey: string;
  parentType: string;
  maxFileSize: number;
  isActive: boolean;
  isForAir?: boolean;
  isForOcean?: boolean;
  isDisabled?: boolean;
  isNonExpiring?: boolean;
}

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  uploadForm!: UntypedFormGroup;
  types: DocumentState[] = [];
  selectedDocumentType: DocumentState = null;
  uploadError = { isError: false, type: '', msg: '' };
  documentsTypes$: Observable<DocumentType[]>;
  documentType: DocumentType;
  percentageValue = 0;
  fileUploadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  disableAnyAction = false; // To disable any action while file is uploading
  formData: FormData = new FormData();
  fileName: string;
  fileToUpload: File;
  matDateHeader = MatDatePickerHeaderComponent;

  showErrorState = false;
  documentTypeErrorState = false;
  uploadFileErrorState = false;
  uploadedFile: UploadedFile;
  isSaving: boolean = false;
  @Input() parentType: string = null;
  isExpirationDateErrorState: boolean;
  @Input() entityDocumentTypes: DocumentState[] = [];
  @Input() selectedSingleDocumentTypeNames: string[] = null;
  @Input() selectedSingleDocumentTypeId: number = null;
  @Input() popUpPurpose: UploadDocumentPopUpPurpose = null;
  @Input() documents: ShipmentDocument[] = null; // to do change this
  @Input() shipmentServicesNames: string[];
  @Input() isAir: boolean;
  @Input() isOcean: boolean;
  @Input() documentOtherName: string;
  @Input() parentTypeId: string;
  formTitle: string = 'Upload Available Documents';

  @Input() documentToReplaceSqlId: number = null;

  @Output() linkExistingDocument = new EventEmitter();
  @Output() uploadedDocument = new EventEmitter<FormData>();
  @Input() isMultipleProductsSelected: boolean;

  isfileChosen: boolean = false;

  uploadedFileDetails: any;

  constructor(
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private documentTypeService: DocumentTypeService,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formsHelperService: FormsHelperService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.parentTypeId = this.data['parentTypeId'] ?? this.parentTypeId;
    this.documents = this.data['documents'] ?? this.documents;
    this.formTitle = this.data['formTitle'] ?? this.formTitle;
    this.shipmentServicesNames =
      this.data['shipmentServicesNames'] ?? this.shipmentServicesNames;
    this.isAir = this.data['isAir'] ?? this.isAir;
    this.isOcean = this.data['isOcean'] ?? this.isOcean;
    this.selectedSingleDocumentTypeNames =
      this.data['selectedSingleDocumentTypeNames'] ??
      this.selectedSingleDocumentTypeNames;
    this.selectedSingleDocumentTypeId =
      this.data['selectedSingleDocumentTypeId'] ??
      this.selectedSingleDocumentTypeId;
    this.documentOtherName =
      this.data['documentOtherName'] ?? this.documentOtherName;
    this.popUpPurpose = this.data['popUpPurpose'] ?? this.popUpPurpose;
    this.documentToReplaceSqlId =
      this.data['documentToReplaceSqlId'] ?? this.documentToReplaceSqlId;
    this.parentType =
      this.data['parentType'] ?? this.parentType ?? DocumentParentType.Shipment;
    this.getDocumentsTypeForTheCurrentParent();
    this.uploadedFile = null;

    this.documentService.cancelFileUploadingEmitter.subscribe(res => {
      this.cancelUpload(res.uploadedFile);
    });

    this.documentService.fileUploadingEmitter.subscribe(res => {
      if (res.isError === true && res.uploadedFile) {
        this.reuploadFile(res.uploadedFile);
      }
    });

    if (this.isUploadLicense) {
      this.selectedDocumentType = this.types[0];
    } else {
      this.excludeRegistrationLicenseDocumentType();
      if (this.types?.length === 1) {
        this.selectedDocumentType = this.types[0];
      }
    }
    this.uploadForm.get('expirationDate').valueChanges.subscribe(res => {
      if (!res) {
        this.isExpirationDateErrorState = true;
      } else {
        this.isExpirationDateErrorState = false;
      }
    });
  }

  onChangeFileUploadMethod(): void {
    this.linkExistingDocument.emit();
  }

  reuploadFile(file: UploadedFile): void {
    if (this.disableAnyAction) {
      return;
    }

    const e = { files: [file] };
    this.reUpload(e);
    this.reAddDocument(file);
  }

  reUpload(e: any): void {
    this.fileUploadingSubject.next(false);
    const file = e.files[0];
    this.fileToUpload = file;
    this.uploadFileErrorState = false;
    this.documentTypeErrorState = false;
    //TODO must one line instead of conditions but needs lots of testing
    if (this.parentType == DocumentParentType.Approval) {
      this.formData.set('submittedFile', this.uploadedFileDetails);
    } else {
      this.formData.set('submittedFile', file);
    }

    this.fileName = file.name;
  }

  reAddDocument(file: UploadedFile): void {
    this.changeFileTypeStatus(file.id.toString(), false, true);
    this.disableAnyAction = true;
    this.formData.append('parentId', this.parentTypeId);
    this.formData.append('parentType', this.parentType);
    this.formData.append('description', file.description);
    this.formData.append('documentTypeId', file.id.toString());

    if (this.documentToReplaceSqlId) {
      this.formData.append(
        'replacedDocumentSqlId',
        this.documentToReplaceSqlId.toString()
      );
    }

    this.documentService
      .Upload(this.formData)
      .pipe(
        takeUntil(this.fileUploadingSubject.pipe(filter((v: boolean) => v)))
      )
      .subscribe((data: any) => {
        file.isUploaded = false;
        file.isUploading = true;
        file.isCanceled = false;
        file.percentageValue = 0;
        this.closeDialog();
        this.handleProgress(data, file);
      });
  }

  isOtherDocumentType(documentType: string): boolean {
    return DocumentTypeEnum.Other === documentType;
  }

  getDocumentsTypeForTheCurrentParent(): void {
    if (this.data['documentTypes']) {
      this.types = this.data['documentTypes'];
      this.formInitialization();
      return;
    }

    if (this.parentType === DocumentParentType.Shipment) {
      this.documentService
        .getShipmentDocumentTypes(this.isAir, this.isOcean)
        .subscribe((res: DocumentType[]) => {
          this.processDocumentTypes(res);
          this.formInitialization();
        });
    } else {
      this.initializeValues();
    }
  }

  initializeValues(): void {
    const isValidPopUpPurpose =
      [
        UploadDocumentPopUpPurpose.AddDangerousGoodsDocument,
        UploadDocumentPopUpPurpose.ReplaceExistingDocument
      ].includes(this.popUpPurpose) ||
      this.parentType == DocumentParentType.CompanyProfile;

    if (isValidPopUpPurpose) {
      let parentType = this.parentType;
      switch (this.parentType) {
        case DocumentParentType.Quote:
          parentType = DocumentParentType.Shipment;
          break;
        case DocumentParentType.Supplier:
          parentType = DocumentParentType.CompanyProfile;
          break;
        case DocumentParentType.ProductRegistration:
        case DocumentParentType.Approval:
          parentType = DocumentParentType.Product;
          break;
      }

      if (
        parentType == DocumentParentType.CompanyProfile &&
        this.parentType != DocumentParentType.Supplier
      ) {
        this.documentTypeService
          .getCompanyProfileDocumentTypes(parseInt(this.companyId))
          .subscribe(res => {
            this.processDocuments(res);
          });
      } else {
        this.documentTypeService
          .getDocumentTypesForSelectedParent(parentType)
          .subscribe(res => {
            this.processDocuments(res);
          });
      }
    } else {
      this.documentService
        .getDocumentsTypes$(
          this.shipmentServicesNames,
          this.isAir,
          this.isOcean
        )
        .subscribe(result => {
          this.processDocumentTypes(result);
        });
    }
    this.formInitialization();
  }

  processDocuments(documentTypes: DocumentType[]): void {
    let documentList;
    if (this.selectedSingleDocumentTypeId) {
      documentList = documentTypes.filter(
        x => this.selectedSingleDocumentTypeId == x.id
      );
    } else {
      documentList = documentTypes.filter(x =>
        this.selectedSingleDocumentTypeNames
          ? this.selectedSingleDocumentTypeNames.includes(x.name)
          : true
      );
    }
    this.processDocumentTypes(documentList);
  }

  processDocumentTypes(documentTypes: DocumentType[]): void {
    this.types = documentTypes.map(x => {
      return {
        id: x.id,
        name: x.name,
        nameKey: x.nameKey,
        uploaded: this.documents?.some(y => y.documentTypeId == x.id),
        uploading: false,
        isDisabled:
          !this.isOtherDocumentType(x.name) &&
          this.documents?.some(
            d => d.documentTypeName === x.name && d.isDeleted != true
          ),
        isNonExpiring: x.isNonExpiring,
        parentType: x.parentType
      } as DocumentState;
    });

    if (this.types.length === 1) {
      this.selectedDocumentType = this.types[0];
    }
  }

  selectDocumentType(documentType: DocumentState): void {
    this.selectedDocumentType = documentType;
    if (this.selectedDocumentType) {
      this.documentTypeErrorState = false;
      if (this.isSelectedOtherDocumentType) {
        this.formsHelperService.addRequiredValidator(
          this.uploadForm,
          'documentOtherName'
        );
      } else {
        this.formsHelperService.clearValidator(
          this.uploadForm,
          'documentOtherName'
        );
        this.uploadForm.get('documentOtherName').setValue('');
      }
    }
  }

  cancelUpload(uploadedFile: UploadedFile): void {
    this.fileUploadingSubject.next(true);
    this.disableAnyAction = false;
    this.resetFileForm();
    const updatedFile: UploadedFile = Object.assign({}, uploadedFile, {
      isUploaded: false,
      isUploading: false,
      isCanceled: true
    });

    this.uploadedFile = updatedFile;
    this.isfileChosen = false;
    this.resetFileForm();
  }

  resetFileForm(): void {
    this.uploadForm.get('file').setValue('');
    this.uploadForm.get('notes').setValue('');
    this.uploadForm.get('expirationDate').setValue('');

    this.uploadError = {
      isError: false,
      type: '',
      msg: ''
    };
    this.formData = null;
    this.formData = new FormData();
    this.isfileChosen = false;
  }

  upload(e: any): void {
    this.fileUploadingSubject.next(false);
    const file = e.files[0];
    this.uploadFileErrorState = false;

    if (
      file.size / 1024 / 1024 > 20 ||
      !file.type.match(
        `image.*|text.*|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/pdf|image/png`
      )
    ) {
      this.uploadError = {
        isError: true,
        type: file.size / 1024 / 1024 > 20 ? 'size' : 'type',
        msg:
          file.size / 1024 / 1024 > 20
            ? 'File size exceeds maximum limit allowed. Add another file with size less than or equal to 20 MB.'
            : 'File type is not supported. Add another file with either of the following formats (pdf, jpg, jpeg, png, xlsx, xls, xlsm, docx, doc, csv)'
      };
      this.documentService.uploadingFile(
        true,
        this.uploadedFile,
        null,
        true,
        this.uploadError.msg
      );
    } else {
      this.uploadForm.get('file').setValue(e.files[0]);
      this.isfileChosen = true;

      this.uploadError = {
        isError: false,
        type: '',
        msg: ''
      };
      this.formData.append('submittedFile', file);
      this.uploadedFileDetails = file;
    }
    this.fileName = file.name;
  }

  addDocument(): void {
    this.checkUploadErrors();

    if (
      !this.uploadForm.valid ||
      this.uploadFileErrorState ||
      this.documentTypeErrorState ||
      (this.isExpirationDateErrorState && !this.isNonExpiring)
    ) {
      return;
    }

    if (this.isExistDocumentTypeName) {
      return;
    }

    this.changeFileTypeStatus(
      this.selectedDocumentType.id.toString(),
      false,
      true
    );

    this.disableAnyAction = true;

    const expirationDate = this.uploadForm.get('expirationDate').value;
    this.formData.set('documentOtherName', this.documentTypeNameFormat);

    this.formData.append('parentId', this.parentTypeId);
    this.formData.append('parentType', this.parentType);
    this.formData.append('description', this.uploadForm.get('notes').value);
    this.formData.append(
      'documentTypeId',
      this.selectedDocumentType.id.toString()
    );
    this.formData.append('hasExpirationDate', this.isNonExpiring ? '0' : '1');
    const formattedExpirationDate = this.formatDate(expirationDate);
    this.formData.set('expirationDate', formattedExpirationDate);

    if (this.documentToReplaceSqlId) {
      this.formData.append(
        'replacedDocumentSqlId',
        this.documentToReplaceSqlId.toString()
      );
    }
    if (this.isMultipleProductsSelected) {
      this.uploadedDocument.emit(this.formData);
    } else {
      this.isSaving = true;
      this.documentService
        .Upload(this.formData)
        .pipe(
          takeUntil(this.fileUploadingSubject.pipe(filter((v: boolean) => v)))
        )
        .subscribe((data: any) => {
          const uploadedFile: UploadedFile = {
            typeName: this.documentTypeNameFormat,
            fileName: this.fileName,
            id: this.selectedDocumentType.id,
            isUploaded: false,
            isCanceled: false,
            isUploading: true,
            percentageValue: 0,
            description: this.uploadForm.get('notes').value,
            oldVersions: data?.oldVersions
          };

          this.handleProgress(data, uploadedFile);

          if (
            (this.isUploadLicense && !this.isReplaceRegistrationLicense) ||
            (this.isApproval && this.isUploadApprovalDocument)
          ) {
            if (data.type === HttpEventType['Response']) {
              this.dialogService.close(true);
            }
          } else {
            this.closeDialog();
          }
        });
    }
  }

  handleProgress(data: any, file: UploadedFile): void {
    if (data.type === HttpEventType['UploadProgress']) {
      const uploadedFile: UploadedFile = Object.assign({}, file, {
        isUploaded: false,
        isUploading: true
      });

      this.documentService.uploadingFile(
        true,
        file,
        null,
        null,
        null,
        HttpEventType['UploadProgress']
      );

      if (!this.uploadedFile) {
        this.uploadedFile = uploadedFile;
      }

      this.percentageValueHandler(data);
    } else if (data.type === HttpEventType['Response']) {
      this.isSaving = false;
      const uploadedFile: UploadedFile = Object.assign({}, file, {
        isUploaded: true,
        isUploading: false
      });

      this.documentService.uploadingFile(
        false,
        file,
        data.body,
        null,
        null,
        HttpEventType['Response']
      );

      this.uploadedFile = uploadedFile;

      this.uploadedFile = null;

      this.uploadForm.reset({
        documentType: '',
        otherDocumentType: this.types[0],
        file: '',
        notes: ''
      });
      this.changeFileTypeStatus(
        this.selectedDocumentType.id.toString(),
        true,
        false
      );

      this.disableAnyAction = false;
      this.resetFileForm();
      this.cd.detectChanges();
    }
  }

  browseFile(input: HTMLInputElement): void {
    input.click();
  }

  deleteUploadedFile(): void {
    this.uploadedFile = null;
    this.resetFileForm();
    // this.changeFileTypeStatus(uploadedFileId, false, false);
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  // From drag and drop
  onDropSuccess(event: any): void {
    event.preventDefault();
    this.upload(event.dataTransfer);
  }

  closeDialog(): void {
    this.resetFileForm();
    this.dialogService.close();
  }

  getTypeName(typeName: string): string {
    if (
      typeName === DocumentTypeEnum.MSDS &&
      this.selectedSingleDocumentTypeNames
    )
      return DocumentTypeEnum.MSDS;
    return typeName;
  }

  excludeRegistrationLicenseDocumentType(): void {
    this.types = this.types?.filter(item => {
      return item?.name != DocumentTypeEnum.RegistrationLicense;
    });
  }

  resetExpirationDate(): void {
    if (this.isNonExpiring) {
      this.uploadForm.get('expirationDate')?.reset();
    }
  }

  get isUploadLicense(): boolean {
    return this.data['isUploadLicense'];
  }

  get isNonExpiring(): boolean {
    return this.selectedDocumentType?.isNonExpiring;
  }

  get isDocumentTypesWithParentShipment(): boolean {
    return this.types?.every(t => t.parentType === DocumentParentType.Shipment);
  }

  get isReplaceRegistrationLicense(): boolean {
    return this.data['isReplaceLicense'];
  }

  get isProductLibraryReplace(): boolean {
    return (
      this.isProductLibrary &&
      this.popUpPurpose == UploadDocumentPopUpPurpose.ReplaceExistingDocument
    );
  }

  get isApproval(): boolean {
    return this.parentType == DocumentParentType.Approval;
  }

  get isUploadApprovalDocument(): boolean {
    return this.types?.some(t => t.name == DocumentTypeEnum.ApprovalDocument);
  }

  get isProductParentType(): boolean {
    return (
      this.parentType === DocumentParentType.ProductRegistration ||
      this.isProductLibrary
    );
  }

  get isProductLibrary(): boolean {
    return this.parentType === DocumentParentType.Product;
  }

  get isSupplierParentType(): boolean {
    return this.parentType === DocumentParentType.Supplier;
  }

  get isCompanyProfileType(): boolean {
    return this.parentType === DocumentParentType.CompanyProfile;
  }

  get isProductRegistrationParentType(): boolean {
    return this.parentType == DocumentParentType.ProductRegistration;
  }

  get currentDate(): Date {
    return new Date();
  }

  get documentTypeNameFormat(): string {
    if (this.isSelectedOtherDocumentType) {
      if (!this.documentToReplaceSqlId) {
        return (
          DocumentTypeEnum.Other +
          ' - ' +
          this.uploadForm.get('documentOtherName')?.value
        );
      } else {
        return this.uploadForm.get('documentOtherName')?.value;
      }
    }
    return this.selectedDocumentType?.name;
  }

  get isExistDocumentTypeName(): boolean {
    return this.documents?.find(
      d => d.documentTypeName == this.documentTypeNameFormat && !d.isDeleted
    )
      ? true
      : false;
  }

  get isSelectedOtherDocumentType(): boolean {
    return this.isOtherDocumentType(this.selectedDocumentType?.name);
  }

  get companyId(): string {
    return this.router.url.split('/')[2];
  }

  private formInitialization(): void {
    this.uploadForm = this.fb.group({
      documentType: '',
      otherDocumentType: this.types[0],
      file: '',
      notes: '',
      documentOtherName: this.documentOtherName,
      expirationDate: ''
    });
  }

  private formatDate(date: any): string {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'yyyy-MM-dd');
  }

  private changeFileTypeStatus(
    id: string,
    isUploaded: boolean,
    isUploading: boolean
  ): void {
    Object.assign(
      this.types,
      this.types.map(el =>
        el.id === parseInt(id)
          ? {
              uploaded: isUploaded,
              uploading: isUploading,
              id: el.id,
              name: el.name,
              nameKey: el.nameKey
            }
          : el
      )
    );
  }

  private checkUploadErrors(): void {
    this.uploadForm.markAllAsTouched();
    this.resetExpirationDate();
    const expirationDate = this.uploadForm.get('expirationDate').value;

    if (
      !expirationDate &&
      !this.isNonExpiring &&
      !this.isDocumentTypesWithParentShipment
    ) {
      this.isExpirationDateErrorState = true;
    }

    if (!this.selectedDocumentType) {
      this.documentTypeErrorState = true;
    }
    const fileFormControlValue = this.uploadForm.get('file').value;
    if (!fileFormControlValue) {
      this.uploadFileErrorState = true;
    }
  }

  private percentageValueHandler(data: {
    loaded: number;
    total: number;
  }): void {
    const percentDone = Math.round((100 * data.loaded) / data.total);
    this.percentageValue = percentDone;
    if (this.uploadedFile) {
      this.uploadedFile.percentageValue = percentDone;
      this.documentService.uploadingFile(
        true,
        this.uploadedFile,
        null,
        null,
        null,
        HttpEventType['UploadProgress']
      );
      this.cd.detectChanges();
    }
  }
}
