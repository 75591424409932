import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AlertService,
  MessageSeverity
} from 'src/app/shared/services/alert.service';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentParentType } from 'src/app/shared/models/enums/document-parent-type.model';
import { DocumentTypeWithService } from '../../../models/document-type-with-service.models';
import { DocumentTypeService } from '../../../services/document-type.service';
import { AppTranslationService } from 'src/app/shared/services/app-translation.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { CertificationType } from 'src/app/shared/models/certification-type.model';
import { CertificationTypeEnum } from 'src/app/admin-portal-configs/enums/certification-type.enum';
import { ClassificationType } from 'src/app/shared/models/classification-type.model';
import { DocumentTypeConditions } from 'src/app/admin-portal-configs/constants/document-type-condition';
import { FormsHelperService } from 'src/app/shared/services/forms-helper.service';
import { ProductDocumentCategory } from 'src/app/shared/models/enums/product-document-category.enum';
import { LicenseTypesEnum } from 'src/app/company/enums/license-types.enum';
import { DdlTable } from 'src/app/admin-portal-configs/models/ddl-table';
import { DdlTableWithId } from 'src/app/admin-portal-configs/models/ddl-table-with-id';
import { CustomerTypesEnum } from 'src/app/company/enums/customer-types.enum';

@Component({
  selector: 'app-add-or-edit-document-type',
  templateUrl: './add-or-edit-document-type.component.html',
  styleUrls: ['./add-or-edit-document-type.component.scss']
})
export class AddOrEditDocumentTypeComponent implements OnInit {
  documentTypeForm: FormGroup;
  isNewDocumentType: boolean = true;
  isSaving: boolean = false;
  documentTypeEdit: DocumentTypeWithService;
  categoryTypes = [
    ProductDocumentCategory.Medical,
    ProductDocumentCategory.NonMedical
  ];

  classificationTypes: ClassificationType[];
  certificationTypes: CertificationType[];
  documentTypeConditions = DocumentTypeConditions;

  parentTypes: BasicDDL[] = this.createParentTypes();
  freightTypes: string[] = this.createFreightTypes();
  booleanTypes: BasicDDL[] = this.createBooleanTypes();
  licenseTypes: string[] = this.createLicenseTypes();

  ddlTables: DdlTable[];
  hasErrorMessage = false;
  errorMessage: string = '';
  industryParentsWithChildren: DdlTableWithId[];
  industryFormControlNames: string[] = [];

  @ViewChild('descriptionTextarea') descriptionTextarea: ElementRef;


  constructor(
    private fb: UntypedFormBuilder,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private documentTypeService: DocumentTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private formsHelperService: FormsHelperService
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.documentTypeEdit = data['documentType'];
      this.industryParentsWithChildren = data['industryParentsWithChildren'];
      this.initForm();
      this.getClassificationAndCertificationTypes();
      this.createIndustryForm();
      this.populateIndustryFormControls();
    });
  }

  ngAfterViewInit() {
    if (this.documentTypeEdit?.description) {
      this.autoExpand({ target: this.descriptionTextarea.nativeElement });
    }
  }

  initForm(): void {
    this.isNewDocumentType = this.documentTypeEdit == null;

    this.documentTypeEdit =
      this.documentTypeEdit || ({} as DocumentTypeWithService);

    this.documentTypeForm = this.fb.group({
      name: [this.documentTypeEdit.name, [Validators.required]],
      description: [this.documentTypeEdit?.description, [Validators.required]],
      maxFileSize: [
        this.documentTypeEdit.maxFileSize,
        [Validators.min(0), Validators.required]
      ],
      parentType: [this.documentTypeEdit?.parentType, [Validators.required]],
      freightType: [
        [this.isForAir, this.isForOcean, this.isForLand].filter(Boolean)
      ],
      category: [this.documentTypeEdit?.category],
      licenseTypes: [this.documentTypeEdit.licenseTypes],
      expiringIn: [this.documentTypeEdit?.expiringIn],
      industry: this.fb.group({}),
      isNonExpiring: [this.documentTypeEdit?.isNonExpiring],
      isPinned: [this.isPinned],
      isForAir: [this.documentTypeEdit.isForAir],
      isForOcean: [this.documentTypeEdit.isForOcean],
      isForLand: [this.documentTypeEdit.isForLand],
      isRequired: [this.documentTypeEdit?.isRequired],
      classificationData: this.fb.group({
        fscClassificationTypes: [this.documentTypeEdit.fscClassificationTypes],
        cfgWithGmbClassificationTypes: [
          this.documentTypeEdit.cfgWithGmbClassificationTypes
        ],
        cfgWithoutGmbClassificationTypes: [
          this.documentTypeEdit.cfgWithoutGmbClassificationTypes
        ]
      })
    });
  }

  changeParentType(event: any): void {
    this.documentTypeEdit.parentType = event?.value;
    this.selectedCompanyProfileOrProduct();
    this.addOrClearProductFieldsValidation();
  }

  addOrClearProductFieldsValidation(): void {
    if (this.isShipment) {
      this.formsHelperService.addRequiredValidator(
        this.documentTypeForm,
        'freightType'
      );

      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'licenseTypes'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'isRequired'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'category'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm?.get('classificationData') as FormGroup,
        'fscClassificationTypes'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm?.get('classificationData') as FormGroup,
        'cfgWithGmbClassificationTypes'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm?.get('classificationData') as FormGroup,
        'cfgWithoutGmbClassificationTypes'
      );
    }
    if (this.isCompany) {
      this.formsHelperService.addRequiredValidator(
        this.documentTypeForm,
        'licenseTypes'
      );
      this.formsHelperService.addRequiredValidator(
        this.documentTypeForm,
        'isRequired'
      );

      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'freightType'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'category'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm?.get('classificationData') as FormGroup,
        'fscClassificationTypes'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm?.get('classificationData') as FormGroup,
        'cfgWithGmbClassificationTypes'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm?.get('classificationData') as FormGroup,
        'cfgWithoutGmbClassificationTypes'
      );
    }
    if (this.isProduct) {
      this.formsHelperService.addRequiredValidator(
        this.documentTypeForm,
        'isRequired'
      );
      this.formsHelperService.addRequiredValidator(
        this.documentTypeForm,
        'category'
      );
      if (this.isProductMedical) {
        this.formsHelperService.addRequiredValidator(
          this.documentTypeForm?.get('classificationData') as FormGroup,
          'fscClassificationTypes'
        );
        this.formsHelperService.addRequiredValidator(
          this.documentTypeForm?.get('classificationData') as FormGroup,
          'cfgWithGmbClassificationTypes'
        );
        this.formsHelperService.addRequiredValidator(
          this.documentTypeForm?.get('classificationData') as FormGroup,
          'cfgWithoutGmbClassificationTypes'
        );
      }
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'freightType'
      );
      this.formsHelperService.clearValidatorsAndValue(
        this.documentTypeForm,
        'licenseTypes'
      );
    }
  }

  isCertificationTypeSelected(certificationType: string): boolean {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm?.get(
      controlName
    ) as UntypedFormArray;

    return formArray?.length === this.classificationTypes?.length;
  }

  isClassificationTypeSelected(
    classificationType: string,
    certificationType: string
  ): boolean {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm?.get(
      controlName
    ) as UntypedFormArray;

    let classificationTypes: string[] = formArray?.value;

    let classificationTypeIndex = classificationTypes?.findIndex(
      c => c === classificationType
    );

    return classificationTypes?.length > 0 && classificationTypeIndex != -1;
  }

  getControlNameFromCertificationType(certificationType: string): string {
    if (certificationType === CertificationTypeEnum.FSC) {
      return 'fscClassificationTypes';
    }

    if (certificationType === CertificationTypeEnum.CFGWithGMB) {
      return 'cfgWithGmbClassificationTypes';
    }

    if (certificationType === CertificationTypeEnum.CFGWithoutGMB) {
      return 'cfgWithoutGmbClassificationTypes';
    }

    return null;
  }

  selectClassificationType(
    event: any,
    classificationType: string,
    certificationType: string
  ): void {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm?.get(
      controlName
    ) as UntypedFormArray;

    if (event.target.checked) {
      formArray?.push(new UntypedFormControl(classificationType));
    } else {
      let formValue: string[] = formArray.value;
      let indexOfUnchecked = formValue.findIndex(f => f === classificationType);
      formArray?.removeAt(indexOfUnchecked);
    }
  }

  selectCertificationType(event: any, certificationType: string): void {
    let controlName = this.getControlNameFromCertificationType(
      certificationType
    );

    const formArray: UntypedFormArray = this.documentTypeForm?.get(
      controlName
    ) as UntypedFormArray;

    formArray?.clear();

    if (event.target.checked) {
      this.classificationTypes?.forEach(classificationType => {
        formArray?.push(new UntypedFormControl(classificationType?.name));
      });
    }
  }

  selectedCompanyProfileOrProduct(): void {
    if (this.isCompanyProfileOrProduct) {
      this.documentTypeForm?.get('isForAir').setValue(null);
      this.documentTypeForm?.get('isForOcean').setValue(null);
      this.documentTypeForm?.get('isForLand').setValue(null);
    }

    if (!this.isProduct) {
      const fscClassificationTypes: UntypedFormArray = this.documentTypeForm?.get(
        'fscClassificationTypes'
      ) as UntypedFormArray;
      fscClassificationTypes?.clear();

      const cfgWithGmbClassificationTypes: UntypedFormArray = this.documentTypeForm?.get(
        'cfgWithGmbClassificationTypes'
      ) as UntypedFormArray;
      cfgWithGmbClassificationTypes?.clear();

      const cfgWithoutGmbClassificationTypes: UntypedFormArray = this.documentTypeForm?.get(
        'cfgWithoutGmbClassificationTypes'
      ) as UntypedFormArray;
      cfgWithoutGmbClassificationTypes?.clear();
    }
  }

  validateOptionProduct(): boolean {
    const formVal = this.documentTypeForm.value;
    if (this.isProductMedical) {
      return (
        formVal?.classificationData?.fscClassificationTypes?.length > 0 ||
        formVal?.classificationData?.cfgWithGmbClassificationTypes?.length >
        0 ||
        formVal?.classificationData?.cfgWithoutGmbClassificationTypes?.length >
        0
      );
    }
    return true;
  }

  isInvalidFormControl(formControlName: string): boolean {
    return this.formsHelperService.isInvalidFormControl(
      this.documentTypeForm,
      formControlName
    );
  }

  save(): void {
    const formVal = this.documentTypeForm?.value;
    this.isSaving = true;
    this.processMaxSize();

    if (!this.validateForm()) {
      return;
    }

    this.assignFreightTypes();
    this.assignIsNonExpiring();

    if (this.isNewDocumentType) {
      this.addDocumentType(formVal);
    } else {
      this.editDocumentType(formVal);
    }
  }

  assignMedicalProductTypes(formVal: any): DocumentTypeWithService {
    if (this.isProductMedical) {
      formVal.fscClassificationTypes = this.documentTypeForm?.value?.classificationData?.fscClassificationTypes;
      formVal.cfgWithGmbClassificationTypes = this.documentTypeForm?.value.classificationData?.cfgWithGmbClassificationTypes;
      formVal.cfgWithoutGmbClassificationTypes = this.documentTypeForm?.value.classificationData?.cfgWithoutGmbClassificationTypes;
    }
    formVal.classificationData.fscClassificationTypes = null;
    formVal.classificationData.cfgWithGmbClassificationTypes = null;
    formVal.classificationData.cfgWithoutGmbClassificationTypes = null;
    return formVal;
  }

  assignIndustries(formVal: any): DocumentTypeWithService {
    if (this.isCompany) {
      const industryIds = Object.values(
        this.documentTypeForm?.value?.industry || {}
      ).reduce((acc: any[], val: any[]) => acc.concat(val), []);
      formVal.industryIds = (industryIds as []).filter(Boolean);
      formVal.industry = null;
    }
    return formVal;
  }

  assignFreightTypes(): void {
    this.documentTypeForm.value.isForAir = false;
    this.documentTypeForm.value.isForOcean = false;
    this.documentTypeForm.value.isForLand = false;

    if (this.documentTypeForm?.value.freightType?.includes('Air')) {
      this.documentTypeForm.value.isForAir = true;
    }
    if (this.documentTypeForm?.value.freightType?.includes('Ocean')) {
      this.documentTypeForm.value.isForOcean = true;
    }
    if (this.documentTypeForm?.value.freightType?.includes('Land')) {
      this.documentTypeForm.value.isForLand = true;
    }
  }

  assignIsNonExpiring(): void {
    if (this.documentTypeForm?.value.expiringIn) {
      this.documentTypeForm.value.isNonExpiring = false;
    } else {
      this.documentTypeForm.value.isNonExpiring = true;
    }
  }

  assignRequirementType(): void {
    if (this.documentTypeForm?.value.requirementType) {
      this.documentTypeForm.value.isRequired = true;
    } else {
      this.documentTypeForm.value.isRequired = false;
    }
  }

  cancel(): void {
    this.router.navigate(['/settings/document-type']);
  }

  createIndustryForm(): void {
    if (this.industryParentsWithChildren?.length) {
      this.initializeIndustryControls(this.industryParentsWithChildren);
    }
  }

  autoExpand(event: any): void {
    const textarea = event.target;
    textarea.style.setProperty('height', 'auto', 'important');
    textarea.style.setProperty('height', `${Math.min(textarea.scrollHeight, 200)}px`, 'important');
  }

  get isCompanyProfileOrProduct(): boolean {
    let isCompanyProfileOrProduct = this.isCompany || this.isProduct;

    return isCompanyProfileOrProduct;
  }

  get isProduct(): boolean {
    const parentType = this.documentTypeForm?.get('parentType')?.value;
    return parentType === DocumentParentType.Product;
  }

  get isCompany(): boolean {
    const parentType = this.documentTypeForm?.get('parentType')?.value;
    return parentType === DocumentParentType.CompanyProfile;
  }

  get isShipment(): boolean {
    const parentType = this.documentTypeForm?.get('parentType')?.value;
    return parentType === DocumentParentType.Shipment;
  }

  get isProductMedical(): boolean {
    return (
      this.documentTypeForm?.get('category')?.value ===
      ProductDocumentCategory.Medical
    );
  }

  get isForAir() {
    return this.documentTypeEdit?.isForAir ? 'Air' : null;
  }

  get isForOcean() {
    return this.documentTypeEdit?.isForOcean ? 'Ocean' : null;
  }

  get isForLand() {
    return this.documentTypeEdit?.isForLand ? 'Land' : null;
  }

  get isPinned() {
    return this.documentTypeEdit?.isPinned?.toString();
  }

  get isFormDisabled(): boolean {
    return this.documentTypeForm?.invalid;
  }

  private getClassificationAndCertificationTypes() {
    this.helperService
      .getClassificationAndCertificationTypes()
      .subscribe(res => {
        this.certificationTypes = res?.certificationTypes;
        this.classificationTypes = res?.classificationTypes;

        this.ddlTables = this.certificationTypes.map(certification => ({
          id: certification.id,
          parent: certification.name,
          children: this.classificationTypes.map(
            classification => classification.name
          )
        }));
      });
  }

  private addDocumentType(documentType: DocumentTypeWithService) {
    documentType = this.assignMedicalProductTypes(documentType);
    documentType = this.assignIndustries(documentType);

    this.isSaving = true;

    this.documentTypeService.addNewDocumentType(documentType).subscribe({
      next: () => {
        this.isSaving = false;
        this.router.navigate(['/settings/document-type']);
        this.alertService.success('Document type was saved successfully');
      },
      error: err => {
        this.isSaving = false;
        this.hasErrorMessage = true;
        this.errorMessage =
          err || 'An error occurred while saving the Document Type.';
      }
    });
  }

  private editDocumentType(documentType: DocumentTypeWithService) {
    documentType = this.assignMedicalProductTypes(documentType);
    documentType = this.assignIndustries(documentType);

    this.isSaving = true;

    this.documentTypeService
      .updateDocumentType(
        this.documentTypeEdit.id,
        this.documentTypeEdit.services,
        documentType
      )
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.router.navigate(['/settings/document-type']);
          this.alertService.success('Document Type was updated successfully');
        },
        error: err => {
          this.isSaving = false;
          this.hasErrorMessage = true;
          this.errorMessage =
            err || 'An error occurred while updating the Document Type.';
        }
      });
  }

  private processMaxSize() {
    this.documentTypeEdit.maxFileSize = Math.ceil(
      this.documentTypeEdit.maxFileSize
    );
  }

  private validateForm(): boolean {
    if (!this.documentTypeForm.valid) {
      this.isSaving = false;
      this.documentTypeForm.markAllAsTouched();
      this.alertService.stopLoadingMessage();
      this.alertService.showMessage(
        'Error',
        'Invalid Form Submission',
        MessageSeverity.error
      );
      return false;
    }
    if (this.isCompanyProfileOrProduct) {
      this.documentTypeEdit.isForAir = true;
      this.documentTypeEdit.isForOcean = true;
      this.documentTypeEdit.isForLand = true;
    }
    if (!this.validateOptionProduct()) {
      return false;
    }
    return true;
  }

  private createFreightTypes(): string[] {
    let freightTypes: string[] = [];
    freightTypes.push('Air');
    freightTypes.push('Ocean');
    freightTypes.push('Land');
    return freightTypes;
  }

  private createBooleanTypes() {
    let booleanTypes: BasicDDL[] = [];
    booleanTypes.push(new BasicDDL('Yes', 'true'));
    booleanTypes.push(new BasicDDL('No', 'false'));
    return booleanTypes;
  }

  private createParentTypes() {
    let booleanTypes: BasicDDL[] = [];
    booleanTypes.push(
      new BasicDDL('Shipment Document', DocumentParentType.Shipment)
    );
    booleanTypes.push(
      new BasicDDL('Company Document', DocumentParentType.CompanyProfile)
    );
    booleanTypes.push(
      new BasicDDL('Product Document', DocumentParentType.Product)
    );

    return booleanTypes;
  }

  private createLicenseTypes() {
    let licenseTypes: string[] = [];
    licenseTypes.push(CustomerTypesEnum.Contractor);
    licenseTypes.push(CustomerTypesEnum.FreeZone);
    licenseTypes.push(LicenseTypesEnum.IndustrialFactory);
    licenseTypes.push(LicenseTypesEnum.IndustrialFactoryUnderGAFI);
    licenseTypes.push(LicenseTypesEnum.TraderWithIORLicense);
    licenseTypes.push(LicenseTypesEnum.TraderWithOwnLicense);
    return licenseTypes;
  }

  private initializeIndustryControls(industryParents: DdlTableWithId[]): void {
    industryParents.forEach(parent => {
      const controlName = parent.parentName;
      this.industryFormControlNames.push(controlName);
      (this.documentTypeForm.get('industry') as FormGroup)?.addControl(
        controlName,
        new UntypedFormControl()
      );
    });
  }
  private populateIndustryFormControls() {
    if (
      this.documentTypeEdit?.industryIds?.length &&
      this.industryParentsWithChildren?.length
    ) {
      this.industryParentsWithChildren.forEach(parent => {
        const controlName = parent.parentName;
        const formControl = this.documentTypeForm
          .get('industry')
          .get(controlName) as UntypedFormControl;
        const selectedChildren = parent.children.filter(child =>
          this.documentTypeEdit.industryIds.includes(child.id)
        );
        formControl.setValue(selectedChildren.map(child => child.id));
      });
    }
  }
}

export class BasicDDL {
  public Name: string;
  public Value: string;

  constructor(private name: string, private value: string) {
    this.Name = name;
    this.Value = value;
  }
}
