import { Component, Inject, OnInit } from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentsService } from 'src/app/shipments/services/shipments.service';
import { ApprovalService } from 'src/app/create-approval/services/approval.service';
import { ProductRegistrationService } from 'src/app/product-registration/services/product-registration.service';
import { LeadTimeLogService } from 'src/app/shipments/services/lead-time-log.service';
@Component({
  selector: 'app-remove-leadtime-note',
  templateUrl: './remove-leadtime-note.component.html',
  styleUrls: ['./remove-leadtime-note.component.scss']
})
export class RemoveLeadtimeNoteComponent implements OnInit {
  id: number;
  activeModule: string;
  constructor(
    private shipmentsServcie: ShipmentsService,
    private approvalService: ApprovalService,
    private productRegistrationService: ProductRegistrationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private leadTimeLogService: LeadTimeLogService
  ) {}

  ngOnInit(): void {
    if (this.data['id']) {
      this.id = +this.data['id'];
    }
    if (this.data['activeModule']) {
      this.activeModule = this.data['activeModule'];
    }
  }
  cancel(success = false) {
    this.dialogService.close(success);
  }

  removeNotes() {
    if (this.activeModule === 'shipments') {
      this.leadTimeLogService
        .updateLeadTimeNotes(this.id, '')
        .subscribe(data => {
          this.cancel(true);
        });
    } else if (this.activeModule === 'productRegistration') {
      this.productRegistrationService
        .updateProductLeadTimeLogs(this.id, '')
        .subscribe(data => {
          this.cancel(true);
        });
    } else if (this.activeModule === 'approvals') {
      this.approvalService.updateLeadTimeNotes(this.id, '').subscribe(data => {
        this.cancel(true);
      });
    }
  }
}
